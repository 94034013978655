import React from "react";
import "./Subheadline.scss";

interface InputTextProps {
    noMargin?: boolean;
}

const Subheadline: React.FC<InputTextProps> = ({ children, noMargin }) => {
    return (
        <p
            className={
                "subheadline" + (noMargin ? " subheadline__no-margin" : "")
            }
        >
            {children}
        </p>
    );
};

export default Subheadline;
