import * as React from "react";

const SVGInforounded: React.FC<SvgProps> = props => (
    <svg width={50} height={50} {...props}>
        <path
            d="M25 47.045c12.175 0 22.045-9.87 22.045-22.045 0-12.175-9.87-22.045-22.045-22.045-12.175 0-22.045 9.87-22.045 22.045 0 12.175 9.87 22.045 22.045 22.045zM25 49C11.745 49 1 38.255 1 25S11.745 1 25 1s24 10.745 24 24-10.745 24-24 24zm-1.2-35.983v2.148c0 .672.537 1.217 1.2 1.217.663 0 1.2-.545 1.2-1.217v-2.148c0-.672-.537-1.217-1.2-1.217-.663 0-1.2.545-1.2 1.217zm0 7.183v18.11a1.2 1.2 0 102.4 0V20.2a1.2 1.2 0 10-2.4 0z"
            fill="currentColor"
        />
    </svg>
);

export default SVGInforounded;
