import { StoreManager } from "../../store";
import { RoutesPage } from "../../route/RouteUtil";

const path: Function = (path: string): number => {
    const listOfPages: RoutesPage[] = StoreManager.getState().listOfPages;
    let pageNumber = 1;
    const find: RoutesPage | undefined = listOfPages.find(
        (item: RoutesPage) => {
            return item.path === path;
        }
    );
    if (find) {
        pageNumber = find.pageNumber;
    }
    return pageNumber;
};

export default path;
