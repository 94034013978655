import React, { ChangeEvent, Component, createRef } from "react";
import Heading from "../../components/Heading/Heading";
import Button from "../../components/Button/Button";
import InputText from "../../components/InputText/InputText";
import Subheadline from "../../components/Subheadline/Subheadline";
import { connect } from "react-redux";
import {
    updateCurrentPage,
    updateForm,
    updateListOfPages
} from "../../store/data/actions";
import { StoreManager } from "../../store";
import { RoutesPage } from "../../route/RouteUtil";
import BackToSummary from "../../components/BackToSummary/BackToSummary";
import {
    Form,
    BrandingTypes,
    ImplementationOption
} from "../../store/data/types";
import path from "../../components/utils/GetPageNumberByPath";
import convertToCurrency from "../../components/utils/ConvertToCurrency";
import { Grid, Row, Col } from "react-flexbox-grid";
import Brandings from "../../components/Brandings/Brandings";

interface ComponentProps {
    currentPage: number;
    moneyVolume?: string;
    updateForm: Function;
    updateListOfPages: Function;
    updateCurrentPage: Function;
    listOfPages: RoutesPage[];
    forms: Form[];
    path: string;
    currentBroker: string;
}

interface ComponentState {}

class Page07 extends Component<ComponentProps, ComponentState> {
    state = {
        hasError: false,
        isDisabled: true,
        moneyVolume: "",
        minInvestmentVolume: 0,
        productName: "",
        implementationOption: ""
    };
    timeout = 0;
    inputText: any = createRef();

    sendForm = () => {
        let state = { ...this.state };
        delete state.productName;
        state.moneyVolume = this.state.moneyVolume.replace(/\s€/g, "");
        this.props.updateForm({
            pageNumber: path(this.props.path),
            data: state
        });
    };

    checkMoneyVolume = () => {
        const moneyVolume: number = this.convertToNumber(
            this.state.moneyVolume
        );
        if (
            moneyVolume < this.state.minInvestmentVolume &&
            this.state.moneyVolume !== ""
        ) {
            this.setState(
                {
                    hasError: true
                } as ComponentState,
                () => this.sendForm()
            );
        } else if (this.state.moneyVolume === "") {
            this.setState(
                {
                    hasError: false,
                    isDisabled: true
                } as ComponentState,
                () => this.sendForm()
            );
        } else {
            this.setState(
                {
                    hasError: false,
                    isDisabled: false
                } as ComponentState,
                () => this.sendForm()
            );
        }
    };

    componentDidMount() {
        const find: Form | undefined = StoreManager.getState().forms.find(
            item => item.pageNumber === path(this.props.path)
        );
        if (find) {
            this.setState(find.data as ComponentState, () => {
                this.checkMoneyVolume();
                if (this.state.moneyVolume) {
                    this.setState({
                        moneyVolume: this.state.moneyVolume + " €"
                    });
                }
            });
        } else {
            this.sendForm();
        }

        // Get Brand configuration (min amount and product name)
        const currentBrand: BrandingTypes = Brandings(this.props.currentBroker);
        this.setState({
            productName: currentBrand.productName
        });

        StoreManager.getState().forms.forEach((item: any) => {
            if (item.pageNumber === path("umsetzungsoptionen")) {
                const implementationOption: ImplementationOption =
                    item.data.implementationOption;

                this.setState({
                    minInvestmentVolume:
                        currentBrand.minInvestmentVolume[implementationOption],
                    implementationOption: implementationOption
                });
            }
        });
    }

    componentDidUpdate(prevProps: ComponentProps, prevState: any) {
        if (this.state.moneyVolume) {
            document
                .getElementById("investmentVolume")!
                .classList.add("form-item-text__input--active");
        }
    }

    componentWillUnmount() {
        clearTimeout(this.timeout);
        const moneyVolume: number = this.convertToNumber(
            this.state.moneyVolume
        );
        let hiddenPage = { ...this.props.listOfPages[path("optionen") - 1] };
        let listOfPages = [...this.props.listOfPages];

        let minAmount;

        if (
            this.state.implementationOption ===
            "einzeltitelUndAusschuettungsfokus"
        ) {
            minAmount = 100000;
        } else {
            minAmount = 1000000;
        }

        hiddenPage.isDisabled = moneyVolume < minAmount;
        listOfPages[path("optionen") - 1] = { ...hiddenPage };
        this.props.updateListOfPages([...listOfPages]);
    }

    handleInput = (e: ChangeEvent<HTMLInputElement>) => {
        this.setState({ moneyVolume: e.target.value }, () => this.sendForm());
        clearTimeout(this.timeout);

        const moneyVolume: number = this.convertToNumber(e.target.value);
        if (moneyVolume === 0) {
            this.setState({ moneyVolume: "" });
        }

        if (moneyVolume < this.state.minInvestmentVolume) {
            this.setState(
                {
                    isDisabled: true
                } as ComponentState,
                () => this.sendForm()
            );
        } else {
            this.setState(
                {
                    isDisabled: false
                } as ComponentState,
                () => this.sendForm()
            );
        }

        this.timeout = window.setTimeout(() => {
            this.checkMoneyVolume();
        }, 1000);
    };

    handleFormSubmission = (e: React.FormEvent): void => {
        e.preventDefault();
        this.props.updateCurrentPage(this.props.currentPage + 1);
    };

    convertToNumber = (str: string) => {
        return Number(str.replace(/[^0-9]/g, ""));
    };

    render() {
        return (
            <section className="parallax-layer">
                <Grid fluid>
                    <Row className={"row--bottom-24"}>
                        <Col xs={10}>
                            <Heading rank={2} text={"3.0 Anlagestrategie"} />
                            <Heading rank={3} text={"3.2 Anlagevolumen"} />

                            <Subheadline>
                                Wie hoch ist die geplante Anlagesumme?
                            </Subheadline>
                        </Col>
                    </Row>
                </Grid>

                <div className="box-model">
                    <Grid fluid>
                        <Row>
                            <Col xs={10}>
                                <InputText
                                    id={"investmentVolume"}
                                    name={"investmentVolume"}
                                    placeHolder={
                                        "Betrag eintragen z.B. 100.000 €"
                                    }
                                    showPlaceholderAsLabel={true}
                                    value={this.state.moneyVolume}
                                    errorMessage={`Die Vermögensanlage in ${
                                        this.state.productName
                                    } ${
                                        this.state.implementationOption.includes(
                                            "einzeltitel"
                                        )
                                            ? "mit Fonds "
                                            : ""
                                    }ist erst ab einer Anlagesumme von ${convertToCurrency(
                                        this.state.minInvestmentVolume
                                    )} möglich.`}
                                    isError={this.state.hasError}
                                    isNumber={true}
                                    onChange={this.handleInput}
                                    ref={this.inputText}
                                />
                            </Col>
                        </Row>
                    </Grid>
                </div>

                <Grid fluid className={"bottom-sticky"}>
                    <Row>
                        <Col xs={12} className={"align-right"}>
                            <BackToSummary />
                            <Button
                                styling={"primary"}
                                isDisabled={this.state.isDisabled}
                                onClick={this.handleFormSubmission}
                            >
                                Weiter
                            </Button>
                        </Col>
                    </Row>
                </Grid>
            </section>
        );
    }
}

const mapStaTeToProps = (props: ComponentProps) => {
    return {
        currentPage: props.currentPage,
        listOfPages: props.listOfPages,
        forms: props.forms,
        currentBroker: props.currentBroker
    };
};

export default connect(mapStaTeToProps, {
    updateCurrentPage,
    updateForm,
    updateListOfPages
})(Page07);
