import * as React from "react";

const SVGGraph: React.FC<SvgProps> = props => (
    <svg width={14} height={14} {...props}>
        <path
            d="M1.433 1v2.567h.584V4h-.584v2.567h.584V7h-.584v2.567h.584V10h-.584v2.567H4v-.584h.433v.584H7v-.584h.433v.584H10v-.584h.433v.584H13V13H1V1h.433zm11.723 2.844a.22.22 0 010 .312l-3 3a.22.22 0 01-.31 0L8.08 5.391l-1.877 4.69a.217.217 0 01-.159.132.213.213 0 01-.2-.059L4.103 8.415l-.816 2.642a.22.22 0 01-.42-.13l.923-2.992a.217.217 0 01.158-.147.224.224 0 01.208.056L5.92 9.607 7.796 4.92a.22.22 0 01.16-.134.217.217 0 01.2.058L10 6.69l2.845-2.845a.22.22 0 01.311 0z"
            fill="red"
            fillRule="evenodd"
        />
    </svg>
);

export default SVGGraph;
