import * as React from "react";

const SVGExit: React.FC<SvgProps> = props => (
    <svg width={14} height={14} {...props}>
        <path
            d="M12.76 0L14 1.24 8.24 7 14 12.76 12.76 14 7 8.24 1.24 14 0 12.76 5.76 7 0 1.24 1.24 0 7 5.76 12.76 0z"
            fill="red"
            fillRule="evenodd"
        />
    </svg>
);

export default SVGExit;
