import * as React from "react";

const SVGArrow: React.FC<SvgProps> = props => (
    <svg width={14} height={14} {...props}>
        <path
            fill="red"
            d="M13 6.402L6.999 0 1 6.402l.755.676 4.746-5.067L6.5 14h1V2.015l4.747 5.063z"
            fillRule="evenodd"
        />
    </svg>
);

export default SVGArrow;
