import * as React from "react";

const SVGUnsustainable: React.FC<SvgProps> = props => (
    <svg width={14} height={14} {...props}>
        <path
            d="M.945.947A.213.213 0 011.14.944c.126.06 2.16 1.038 4.042 2.463-.041.16-.067.324-.091.49C3.56 2.71 1.86 1.801 1.217 1.473c-.16 1.107-.641 5.082.263 7.571.608 1.673 1.587 2.312 2.3 2.553a2.852 2.852 0 002.165-.147L4.739 9.215 2.127 8.23a.22.22 0 01.156-.412l2.137.806-1.827-3.389a.22.22 0 01.089-.298.222.222 0 01.299.09l2.524 4.68.125-1.46c.01-.122.119-.22.238-.2a.218.218 0 01.2.237l-.182 2.131.448.83c.568-.386 1.48-1.169 1.705-2.34.012-.064.006-.132.014-.197.14.05.28.1.429.134-.007.048-.001.098-.011.146-.193 1.007-.88 1.943-1.928 2.645l.832 1.542a.22.22 0 01-.193.325.22.22 0 01-.194-.116l-.83-1.535a3.34 3.34 0 01-1.453.342c-.357 0-.716-.058-1.065-.177-.806-.272-1.905-.983-2.573-2.82C-.027 6.185.793 1.311.827 1.105A.22.22 0 01.946.947zM9.5.78a3.724 3.724 0 013.72 3.72A3.724 3.724 0 019.5 8.22 3.724 3.724 0 015.78 4.5 3.724 3.724 0 019.5.78zm0 .44A3.283 3.283 0 006.22 4.5 3.284 3.284 0 009.5 7.78a3.284 3.284 0 003.28-3.28A3.283 3.283 0 009.5 1.22zm1.843 1.124l.312.312L9.81 4.5l1.845 1.845-.312.311L9.5 4.811 7.655 6.655l-.312-.31L9.188 4.5 7.343 2.656l.312-.312L9.5 4.188l1.843-1.844z"
            fill="red"
            fillRule="evenodd"
        />
    </svg>
);

export default SVGUnsustainable;
