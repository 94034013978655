import * as React from "react";

const SVGEinzeltitelUndAusschuettungsfokus: React.FC<SvgProps> = props => (
    <svg
        id="Layer_1"
        x={0}
        y={0}
        viewBox="0 0 14 14"
        xmlSpace="preserve"
        {...props}
    >
        <path
            className="st0"
            d="M5.7 11.3c-1.6 0-3-1.3-3-3 0-.1.1-.2.2-.2s.2.1.2.2c0 1.4 1.1 2.5 2.5 2.5.1 0 .2.1.2.2s0 .3-.1.3zM7.8 10.6s-.5 1.2 0 1.9l.4-.2c-.4-.6 0-1.5 0-1.5l-.4-.2z"
        />
        <path
            className="st0"
            d="M13.6 12.5c0-.9-.2-1.7-.5-2.3-.2-.5-.5-.9-1-1.4-.3-.4-.8-.6-1.1-.8l.5-.8c.1-.2.2-.4.1-.6-.1-.2-.3-.3-.5-.3H8.8c-.1 0-.4 0-.5.3 0 .1 0 .4.2.6l.5.7c-.3.1-.9.4-1.3.8s-.7.9-1 1.4c-.3.7-.5 1.5-.5 2.3 0 0 0 .4.3.8.3.3.8.5 1.4.5h3.9c.7 0 1.2-.2 1.5-.5.4-.3.4-.7.3-.7zM8.8 6.9c0-.1-.1-.1 0-.2h2.4s.1 0 .1.1V7l-.6.9c-.4-.1-.8-.1-1.1 0l-.8-1zM13 13c-.2.2-.6.4-1.1.4H8c-.5 0-.9-.1-1.1-.3-.2-.2-.2-.5-.2-.5 0-.8.2-1.6.5-2.2.1-.6.4-1 .8-1.4.4-.4 1-.6 1.2-.7.2 0 .9-.1 1.4 0 .2.1.8.4 1.2.8.4.4.7.8.9 1.2.3.6.4 1.4.5 2.2 0 0 0 .3-.2.5zM.4 1.8V6.1C.4 7 1.5 7.7 3 7.7S5.6 7 5.6 6.1V1.8C5.6.9 4.5.2 3 .2S.4.9.4 1.8zm4.7 0c0 .6-1 1.1-2.2 1.1S.8 2.4.8 1.8 1.8.7 3 .7s2.1.5 2.1 1.1zM.8 5v-.2c.1.1.2.2.4.3 0 0 .1 0 .1.1.1.1.2.1.3.1.1 0 .1 0 .2.1.1 0 .2.1.4.1H3.8c.1 0 .3-.1.4-.1.1 0 .1 0 .2-.1.1 0 .2-.1.3-.1 0 0 .1 0 .1-.1l.3-.3V5c0 .6-1 1.1-2.2 1.1S.8 5.6.8 5zm0-1v-.2c.1.1.2.1.4.2 0 0 .1 0 .1.1.1.1.2.1.3.1.1 0 .1 0 .2.1.1 0 .2.1.4.1H3.8c.1 0 .3-.1.4-.1.1 0 .1 0 .2-.1.1 0 .2-.1.3-.1 0 0 .1 0 .1-.1.1-.1.2-.1.3-.2V4c0 .6-1 1.1-2.2 1.1S.8 4.6.8 4zm0-1.1v-.2c.1.1.2.2.4.2 0 0 .1 0 .1.1.1.1.2.1.3.1.1 0 .1 0 .2.1.1 0 .2.1.4.1H3.8c.1 0 .3-.1.4-.1.1 0 .1 0 .2-.1.1 0 .2-.1.3-.1 0 0 .1 0 .1-.1.1 0 .2-.1.3-.2v.2C5.1 3.5 4.1 4 3 4S.8 3.5.8 2.9zm0 3.2v-.2c.1.1.2.2.4.3.1.1.3.2.5.2.4.1.8.2 1.3.2s.9-.1 1.2-.2c.4-.1.7-.3.9-.5v.2c0 .6-1 1.1-2.2 1.1S.8 6.7.8 6.1zM6.3 1.8V4c0 .9 1.1 1.6 2.6 1.6s2.6-.7 2.6-1.6V1.8C11.5.9 10.4.2 8.9.2 7.5.2 6.3.9 6.3 1.8zm4.8 0c0 .6-1 1.1-2.2 1.1s-2.1-.5-2.1-1.1S7.8.7 9 .7s2.1.5 2.1 1.1zM6.8 2.9v-.2c.1.1.2.2.3.2 0 0 .1 0 .1.1.1.1.2.1.3.1.1 0 .1 0 .2.1.1 0 .2.1.4.1H9.7c.1 0 .3-.1.4-.1.1 0 .1 0 .2-.1.1 0 .2-.1.3-.1 0 0 .1 0 .1-.1.1-.1.3-.2.4-.3v.2c0 .6-1 1.1-2.2 1.1s-2.1-.4-2.1-1zm0 1.1v-.2c.1.1.2.1.3.2 0 0 .1 0 .1.1.1.1.2.1.3.1.1 0 .1 0 .2.1.1 0 .2.1.4.1H9.7c.1 0 .3-.1.4-.1.1 0 .1 0 .2-.1.1 0 .2-.1.3-.1 0 0 .1 0 .1-.1.1-.1.3-.2.4-.3V4c0 .6-1 1.1-2.2 1.1S6.8 4.6 6.8 4z"
        />
    </svg>
);

export default SVGEinzeltitelUndAusschuettungsfokus;
