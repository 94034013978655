import * as React from "react";

const SVGCheckmark: React.FC<SvgProps> = props => (
    <svg width={50} height={36} {...props}>
        <path
            d="M16.51 34.994c-.462.039-.939-.12-1.293-.472L1.48 20.823a1.63 1.63 0 010-2.31 1.641 1.641 0 012.316 0L16.458 31.14 46.204 1.478a1.641 1.641 0 012.316 0 1.63 1.63 0 010 2.31L17.704 34.516a1.636 1.636 0 01-1.193.478z"
            fill="currentColor"
        />
    </svg>
);

export default SVGCheckmark;
