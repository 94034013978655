import React from "react";
import "./BarsLoader.scss";

const BarsLoader: React.FC = () => {
    return (
        <div className="bars-wrapper">
            <div className="bar1" />
            <div className="bar2" />
            <div className="bar3" />
        </div>
    );
};

export default BarsLoader;
