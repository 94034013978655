import React, { BaseSyntheticEvent, createRef } from "react";
import Heading from "../../components/Heading/Heading";
import Button from "../../components/Button/Button";
import Accordion from "../../components/Accordion/Accordion";
import Subheadline from "../../components/Subheadline/Subheadline";
import InfoText from "../../components/InfoText/InfoText";
import InputText from "../../components/InputText/InputText";
import SVGDirection from "../../components/svg/Direction";
import Dropdown, { Option } from "react-dropdown";
import {
    importForm,
    updateCurrentPage,
    updateForm
} from "../../store/data/actions";
import { connect } from "react-redux";
import { StoreManager } from "../../store";
import BackToSummary from "../../components/BackToSummary/BackToSummary";
import { Form } from "../../store/data/types";
import path from "../../components/utils/GetPageNumberByPath";
import convertToCurrency from "../../components/utils/ConvertToCurrency";
import { Grid, Row, Col } from "react-flexbox-grid";
import InputTextCheckbox from "../../components/InputTextCheckbox/InputTextCheckbox";

interface ComponentProps {
    currentPage: number;
    updateForm: Function;
    updateCurrentPage: Function;
    forms: Form[];
    importForm: Function;
    path: string;
}

export interface RegularPayments {
    [index: string]: string | boolean;
    checked: boolean;
    frequency1: string;
    frequency2: string;
    minimumAmount: string;
}

interface ComponentState {
    [index: string]: string | number | boolean | undefined | RegularPayments;
    hasError: boolean;
    isDisabled: boolean;
    regularPayments: RegularPayments;
    isLegalPerson: boolean;
    minMoneyVolume: number;
    implementationOption: string;
    distributionOfOrdinaryIncomeCheckbox: boolean;
    amount: any;
}

class Page10 extends React.Component<ComponentProps, ComponentState> {
    optionsPayingOutShort = ["", "halbjährlich", "jährlich"];

    optionsDate = ["", "01. des Monats", "15. des Monats"];

    optionsPayingOut = [
        "",
        "monatlich",
        "vierteljährlich",
        "halbjährlich",
        "jährlich"
    ];

    state = {
        hasError: false,
        isDisabled: false,
        regularPayments: {
            checked: false,
            frequency1: "",
            frequency2: "",
            minimumAmount: ""
        },
        isLegalPerson: false,
        minMoneyVolume: 0,
        implementationOption: "",
        amount: 0,
        distributionOfOrdinaryIncomeCheckbox: false
    };
    timeout = 0;
    inputText: any = createRef();

    sendForm = () => {
        let { isLegalPerson, ...cleanState } = this.state;
        let regularPayments = { ...cleanState.regularPayments };
        let moneyVolume = this.state.regularPayments.minimumAmount.substring(
            0,
            this.state.regularPayments.minimumAmount.length - 2
        );

        if (this.state.regularPayments.minimumAmount.includes("€")) {
            regularPayments.minimumAmount = moneyVolume;
        }
        cleanState.regularPayments = { ...regularPayments };
        delete cleanState.implementationOption;

        this.props.updateForm({
            pageNumber: path(this.props.path),
            data: cleanState
        });
    };

    checkResetFields = () => {
        let regularPayments: RegularPayments = {
            ...this.state.regularPayments
        };

        // clean children options when parent is unchecked
        if (!regularPayments.checked) {
            regularPayments.frequency1 = "";
            regularPayments.frequency2 = "";
            regularPayments.minimumAmount = "";
            this.setState({
                regularPayments: { ...regularPayments }
            });
        }
    };

    disableButton = () => {
        const moneyVolume = Number(
            this.state.regularPayments.minimumAmount
                .replace(/\s€/g, "")
                .split(".")
                .join("")
        );
        const { regularPayments } = this.state;

        if (
            regularPayments.checked &&
            (moneyVolume < this.state.minMoneyVolume ||
                !regularPayments.frequency2 ||
                !regularPayments.frequency1)
        ) {
            this.setState({ isDisabled: true }, () => this.sendForm());
            return;
        }

        this.setState({ isDisabled: false }, () => {
            const find = StoreManager.getState().forms.find(
                item => item.pageNumber === path(this.props.path)
            );
            if (find) {
                this.sendForm();
            } else {
                this.props.updateForm({
                    pageNumber: path(this.props.path),
                    data: { isDisabled: false }
                });
            }
        });
        this.checkResetFields();
    };

    componentDidMount() {
        const find = StoreManager.getState().forms.find(
            item => item.pageNumber === path(this.props.path)
        );
        if (find) {
            this.setState(find.data as ComponentState, () => {
                if (find.data.regularPayments?.minimumAmount) {
                    let regularPayments = { ...this.state.regularPayments };
                    regularPayments.minimumAmount += " €";
                    this.setState({ regularPayments }, () => {
                        this.disableButton();
                        this.checkMoneyVolume();
                    });
                }
            });
            this.checkImplementationOption();
            this.checkAmount();
        } else {
            this.sendForm();
            this.checkImplementationOption();
            this.checkAmount();
        }

        StoreManager.getState().forms.forEach((item: Form) => {
            if (item.pageNumber === path("umsetzungsoptionen")) {
                let minMoneyVolume;
                switch (item.data.implementationOption) {
                    case "fondsbausteine":
                        minMoneyVolume = 5000;
                        break;
                    default:
                        minMoneyVolume = 25000;
                        break;
                }

                this.setState({ minMoneyVolume });
            }
        });
    }

    componentWillUnmount() {
        clearTimeout(this.timeout);
    }

    handleDropdownChange = (
        e: Option,
        parent: "regularPayments",
        name: string
    ) => {
        const state: ComponentState = { ...this.state };
        let changedItem: RegularPayments = {
            ...state[parent]
        };
        changedItem[name] = e.value;
        changedItem.checked = true;
        this.setState({ [parent]: { ...changedItem } }, () => {
            this.sendForm();
            this.disableButton();
        });
    };
    handleFormSubmission = (e: React.FormEvent): void => {
        e.preventDefault();
        this.props.updateCurrentPage(this.props.currentPage + 1);
    };

    handleInputChange = (e: BaseSyntheticEvent, parent: "regularPayments") => {
        clearTimeout(this.timeout);
        const state: ComponentState = { ...this.state };
        let changedItem: RegularPayments = {
            ...state[parent]
        };
        if (e.target.value === "0") {
            e.target.value = "";
        }
        changedItem[e.target.name] = e.target.value;
        changedItem.checked = true;
        this.setState({ [parent]: { ...changedItem } }, () => {
            this.sendForm();
            this.disableButton();
            this.checkMoneyVolume();
        });
    };

    checkImplementationOption = () => {
        const find = this.props.forms.find(
            item => item.pageNumber === path("umsetzungsoptionen")
        );

        if (
            find!.data.implementationOption ===
            "einzeltitelUndAusschuettungsfokus"
        ) {
            this.setState({
                implementationOption: "einzeltitelUndAusschuettungsfokus"
            });
        }
    };

    checkAmount = () => {
        const find = this.props.forms.find(
            item => item.pageNumber === path("anlagevolumen")
        );

        this.setState({
            amount: Number(find!.data.moneyVolume!.split(".").join(""))
        });
    };

    checkMoneyVolume = () => {
        let minimumAmount = Number(
            this.state.regularPayments.minimumAmount
                .split(".")
                .join("")
                .substring(
                    0,
                    this.state.regularPayments.minimumAmount.length - 2
                )
        );
        if (minimumAmount === 0) {
            let regularPayments = { ...this.state.regularPayments };
            regularPayments.minimumAmount = "";
            this.setState({ regularPayments });
        }

        if (
            minimumAmount < this.state.minMoneyVolume &&
            this.state.regularPayments.minimumAmount !== ""
        ) {
            this.timeout = window.setTimeout(() => {
                this.setState(
                    {
                        hasError: true
                    } as ComponentState,
                    () => this.sendForm()
                );
            }, 1000);
        } else {
            this.setState(
                {
                    hasError: false
                } as ComponentState,
                () => this.sendForm()
            );
        }
    };

    handleClick = (e: BaseSyntheticEvent) => {
        const dataValue: "regularPayments" = e.target
            .closest(".accordion__headline")
            .getAttribute("data-value");

        const state: ComponentState = { ...this.state };
        let item: RegularPayments = {
            ...state[dataValue]
        };

        if (
            !e.target
                .closest(".accordion__headline")
                .classList.contains("is-selected")
        ) {
            item.checked = true;

            this.setState(
                {
                    [dataValue]: { ...item }
                },
                () => {
                    this.sendForm();
                    this.disableButton();
                }
            );
        } else {
            item.checked = false;

            this.setState(
                {
                    [dataValue]: { ...item }
                },
                () => {
                    this.sendForm();
                    this.disableButton();
                    this.checkEraseForm();
                }
            );
        }
    };

    handleCheckBox = (e: BaseSyntheticEvent): void => {
        this.setState(
            { [e.target.name]: e.target.checked } as ComponentState,
            () => {
                this.sendForm();
            }
        );
    };

    checkEraseForm = () => {
        if (
            !this.state.regularPayments.checked &&
            this.props.currentPage === path("optionen")
        ) {
            const listOfForms: Form[] = StoreManager.getState().forms.filter(
                item => item.pageNumber !== path("optionen")
            );
            this.props.importForm(listOfForms);
        }
    };

    render() {
        return (
            <section className="parallax-layer">
                <Heading rank={2} text={"3.0 Anlagestrategie"} />
                <Heading rank={3} text={"3.5 Optionen"} />

                <Subheadline>
                    Was soll zusätzlich berücksichtigt werden?
                </Subheadline>

                <div className="box-model">
                    {this.state.implementationOption ===
                    "einzeltitelUndAusschuettungsfokus" ? (
                        <Subheadline>
                            Eine Auszahlung der angefallenen Erträge erfolgt
                            zweimal jährlich.
                        </Subheadline>
                    ) : (
                        <InputTextCheckbox
                            name={"distributionOfOrdinaryIncomeCheckbox"}
                            additionalClass={"text-checkbox--bottom-14"}
                            onChange={this.handleCheckBox}
                            checked={
                                this.state.distributionOfOrdinaryIncomeCheckbox
                            }
                        >
                            Gutschrift der angefallenen Erträge zum Monatsultimo
                        </InputTextCheckbox>
                    )}

                    <Accordion
                        showAccordion={this.state.amount >= 1000000}
                        headline={"Regelmäßige Auszahlung"}
                        dataValue={"regularPayments"}
                        onMouseUp={this.handleClick}
                        id={"regularPayments"}
                        checked={this.state.regularPayments.checked}
                    >
                        In welchem Rhythmus soll die Auszahlung von mindestens{" "}
                        {convertToCurrency(this.state.minMoneyVolume)} erfolgen?
                        <Grid fluid>
                            <Row className={"row--bottom-0"}>
                                <Col xs={12}>
                                    <InfoText
                                        isFlex={true}
                                        additionalClass={"info-text--top-30"}
                                    >
                                        <div
                                            className={
                                                "info-text__inline info-text--flex__item info-text--flex__item--text"
                                            }
                                        >
                                            Auszahlung von
                                        </div>
                                        <div
                                            className={"info-text--flex__item"}
                                        >
                                            <InputText
                                                id={"minimumAmount"}
                                                name={"minimumAmount"}
                                                placeHolder={"Betrag wählen"}
                                                showPlaceholderAsLabel={true}
                                                isNumber={true}
                                                value={
                                                    this.state.regularPayments
                                                        .minimumAmount
                                                }
                                                isError={this.state.hasError}
                                                errorMessage={`Mindestbetrag\n${convertToCurrency(
                                                    this.state.minMoneyVolume
                                                )}`}
                                                onChange={e => {
                                                    this.handleInputChange(
                                                        e,
                                                        "regularPayments"
                                                    );
                                                }}
                                                ref={this.inputText}
                                            />
                                        </div>

                                        <div
                                            className={
                                                "info-text--flex__item info-text--flex__item--dropdown"
                                            }
                                        >
                                            <div className="form-item-text ">
                                                <label className="form-item-text__label form-item-text__label--empty"></label>
                                                <Dropdown
                                                    value={
                                                        this.state
                                                            .regularPayments
                                                            .frequency1
                                                    }
                                                    options={
                                                        this.optionsPayingOut
                                                    }
                                                    placeholder={
                                                        "Rhythmus wählen"
                                                    }
                                                    onChange={e => {
                                                        this.handleDropdownChange(
                                                            e,
                                                            "regularPayments",
                                                            "frequency1"
                                                        );
                                                    }}
                                                    arrowClosed={
                                                        <SVGDirection
                                                            className={
                                                                "Dropdown-svg-arrow"
                                                            }
                                                            viewBox={
                                                                "0 0 14 14"
                                                            }
                                                        />
                                                    }
                                                    arrowOpen={
                                                        <SVGDirection
                                                            className={
                                                                "Dropdown-svg-arrow"
                                                            }
                                                            viewBox={
                                                                "0 0 14 14"
                                                            }
                                                        />
                                                    }
                                                />
                                                <label
                                                    data-content={
                                                        "Rhythmus wählen"
                                                    }
                                                    className={
                                                        "form-item-text__label--placeholder" +
                                                        (this.state
                                                            .regularPayments
                                                            .frequency1
                                                            ? " active"
                                                            : "")
                                                    }
                                                >
                                                    Rhythmus wählen
                                                </label>
                                            </div>
                                        </div>

                                        <div
                                            className={
                                                "info-text__inline info-text--flex__item info-text--flex__item--text"
                                            }
                                        >
                                            am
                                        </div>

                                        <div
                                            className={
                                                "info-text--flex__item info-text--flex__item--dropdown"
                                            }
                                        >
                                            <div className="form-item-text">
                                                <label className="form-item-text__label form-item-text__label--empty"></label>
                                                <Dropdown
                                                    value={
                                                        this.state
                                                            .regularPayments
                                                            .frequency2
                                                    }
                                                    options={this.optionsDate}
                                                    placeholder={
                                                        "Zeitpunkt wählen"
                                                    }
                                                    onChange={e => {
                                                        this.handleDropdownChange(
                                                            e,
                                                            "regularPayments",
                                                            "frequency2"
                                                        );
                                                    }}
                                                    arrowClosed={
                                                        <SVGDirection
                                                            className={
                                                                "Dropdown-svg-arrow"
                                                            }
                                                            viewBox={
                                                                "0 0 14 14"
                                                            }
                                                        />
                                                    }
                                                    arrowOpen={
                                                        <SVGDirection
                                                            className={
                                                                "Dropdown-svg-arrow"
                                                            }
                                                            viewBox={
                                                                "0 0 14 14"
                                                            }
                                                        />
                                                    }
                                                />
                                                <label
                                                    data-content={
                                                        "Zeitpunkt wählen"
                                                    }
                                                    className={
                                                        "form-item-text__label--placeholder" +
                                                        (this.state
                                                            .regularPayments
                                                            .frequency2
                                                            ? " active"
                                                            : "")
                                                    }
                                                >
                                                    Zeitpunkt wählen
                                                </label>
                                            </div>
                                        </div>
                                    </InfoText>
                                </Col>
                            </Row>
                        </Grid>
                    </Accordion>
                </div>

                <Grid fluid className={"bottom-sticky"}>
                    <Row>
                        <Col xs={12} className={"align-right"}>
                            <BackToSummary />
                            <Button
                                styling={"primary"}
                                isDisabled={this.state.isDisabled}
                                onClick={this.handleFormSubmission}
                                additionalClass={"button--end-of-page"}
                            >
                                Weiter
                            </Button>
                        </Col>
                    </Row>
                </Grid>
            </section>
        );
    }
}

const mapStaTeToProps = (props: ComponentProps) => {
    return {
        currentPage: props.currentPage,
        forms: props.forms
    };
};

export default connect(mapStaTeToProps, {
    updateCurrentPage,
    updateForm,
    importForm
})(Page10);
