import React from "react";
import "./InfoText.scss";

interface InfoTextProps {
    noMargins?: boolean;
    isFlex?: boolean;
    isSmall?: boolean;
    isSpaceBetween?: boolean;
    whiteSpaceNormal?: boolean;
    underForm?: boolean;
    additionalClass?: string;
}

const InfoText: React.FC<InfoTextProps> = ({
    children,
    noMargins,
    isFlex,
    isSmall,
    isSpaceBetween,
    whiteSpaceNormal,
    underForm,
    additionalClass
}) => {
    return (
        <div
            className={
                "info-text" +
                (noMargins ? " info-text--no-margins" : "") +
                (isFlex ? " info-text--flex" : "") +
                (isSpaceBetween ? " info-text--flex-space-between" : "") +
                (isSmall ? " info-text--bold" : "") +
                (whiteSpaceNormal ? " info-text--normal" : "") +
                (underForm ? " info-text--under-form" : "") +
                (additionalClass ? " " + additionalClass : "")
            }
        >
            {children}
        </div>
    );
};

export default InfoText;
