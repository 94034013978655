import React, { FormEventHandler } from "react";
import "./InputTextCheckbox.scss";
import SVGCheckMark from "../svg/Checkmark";

interface InputTextProps {
    name: string;
    value?: string;
    checked?: boolean;
    onChange?: FormEventHandler;
    additionalClass?: string;
}

const InputTextCheckbox: React.FC<InputTextProps> = ({
    children,
    name,
    value,
    checked,
    onChange,
    additionalClass
}) => {
    return (
        <div
            className={
                "text-checkbox" + (additionalClass ? " " + additionalClass : "")
            }
        >
            <label className={"text-checkbox__label"}>
                <input
                    className={"text-checkbox__input"}
                    type={"checkbox"}
                    name={name}
                    value={value}
                    checked={checked}
                    onChange={onChange}
                />
                <span className={"text-checkbox__checkbox"}>
                    <SVGCheckMark
                        className={"text-checkbox__icon"}
                        viewBox={"0 0 50 36"}
                    />
                </span>
                <span className={"text-checkbox__text"}>{children}</span>
            </label>
        </div>
    );
};

export default InputTextCheckbox;
