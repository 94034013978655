import React from "react";
import Heading from "../../components/Heading/Heading";
import Button from "../../components/Button/Button";
import InputRadioCheckbox from "../../components/InputRadioCheckbox/InputRadioCheckbox";
import Textarea from "../../components/Textarea/Textarea";
import SVGWithEav from "../../components/svg/WithEav";
import SVGWithoutEav from "../../components/svg/WithoutEav";
import Subheadline from "../../components/Subheadline/Subheadline";
import InfoText from "../../components/InfoText/InfoText";
import { connect } from "react-redux";
import { updateCurrentPage, updateForm } from "../../store/data/actions";
import { StoreManager } from "../../store";
import { Form } from "../../store/data/types";
import BackToSummary from "../../components/BackToSummary/BackToSummary";
import path from "../../components/utils/GetPageNumberByPath";
import { Grid, Row, Col } from "react-flexbox-grid";
import getStrategyName from "../../components/utils/GetStrategyName";

interface ComponentProps {
    currentPage: number;
    updateForm: Function;
    updateCurrentPage: Function;
    forms: Form[];
    path: string;
}

interface ComponentState {
    remuneration: string;
    remunerationTextarea: string;
    isDisabled: boolean;
    isLegalPerson: boolean;
    compensationValueWithEav: string;
    compensationValueWithoutEav: string;
    originalCompensationValue: string;
    compensationValue: string;
    hasValueChanged: boolean;
}

class Page12 extends React.Component<ComponentProps, ComponentState> {
    state = {
        remuneration: "withoutRemuneration",
        remunerationTextarea: "",
        isDisabled: false,
        isLegalPerson: false,
        compensationValueWithEav: "",
        compensationValueWithoutEav: "",
        originalCompensationValue: "",
        compensationValue: "",
        hasValueChanged: false
    };

    sendForm = () => {
        const {
            isDisabled,
            remuneration,
            remunerationTextarea,
            compensationValue,
            originalCompensationValue,
            hasValueChanged
        } = this.state;
        this.props.updateForm({
            pageNumber: path(this.props.path),
            data: {
                isDisabled,
                remuneration,
                remunerationTextarea,
                compensationValue,
                originalCompensationValue,
                hasValueChanged
            }
        });
    };

    calculateCompensation = () => {
        if (!this.state.hasValueChanged) {
            const compensationValue = this.state.isLegalPerson
                ? this.state.originalCompensationValue + "%"
                : parseFloat(
                      String(
                          Number(this.state.originalCompensationValue) * 1.17
                      )
                  ).toFixed(5) + "%";
            this.setState({ compensationValue }, () => this.sendForm());
        }
    };

    componentDidMount() {
        StoreManager.getState().forms.forEach((item: any) => {
            if (item.pageNumber === path(this.props.path)) {
                this.setState({ ...item.data }, () => {
                    if (this.state.remuneration === "withRemuneration") {
                        this.setState(
                            {
                                originalCompensationValue: parseFloat(
                                    this.state.compensationValueWithEav
                                ).toFixed(5)
                            } as ComponentState,
                            () => {
                                this.sendForm();
                            }
                        );
                    }
                });
            } else if (item.pageNumber === path("persoenliche-daten")) {
                this.setState({
                    isLegalPerson: item.data.isLegalPerson
                } as ComponentState);
            } else if (item.pageNumber === path("maximale-aktienquote")) {
                this.setState(
                    {
                        originalCompensationValue: item.data.compensationValue,
                        compensationValueWithEav: parseFloat(
                            String(Number(item.data.compensationValue) - 0.2)
                        ).toFixed(5),
                        compensationValueWithoutEav: item.data.compensationValue
                    } as ComponentState,
                    () => {
                        this.sendForm();
                        this.calculateCompensation();
                    }
                );
            }
        });
    }

    componentDidUpdate(prevProps: ComponentProps, prevState: ComponentState) {
        if (this.state.remuneration !== prevState.remuneration) {
            this.calculateCompensation();
        }

        if (!this.state.remunerationTextarea) {
            this.setState(
                {
                    remunerationTextarea:
                        this.state.originalCompensationValue + " %"
                },
                () => {
                    this.sendForm();
                }
            );
        }
    }

    handleFormSubmission = (e: React.FormEvent): void => {
        e.preventDefault();
        this.props.updateCurrentPage(this.props.currentPage + 1);
    };

    handleRadioChange = (e: any): void => {
        this.setState(
            {
                remuneration: e.target.value,
                isDisabled: Boolean(!e.target.value)
            } as ComponentState,
            () => {
                this.sendForm();
            }
        );

        if (e.target.id === "withRemuneration") {
            this.setState(
                {
                    originalCompensationValue: parseFloat(
                        this.state.compensationValueWithEav
                    ).toFixed(5)
                } as ComponentState,
                () => {
                    this.sendForm();
                }
            );
        } else if (e.target.id === "withoutRemuneration") {
            this.setState(
                {
                    originalCompensationValue: parseFloat(
                        this.state.compensationValueWithoutEav
                    ).toFixed(5)
                } as ComponentState,
                () => {
                    this.sendForm();
                }
            );
        }
    };

    handleInputChange = (e: any): void => {
        this.setState(
            {
                hasValueChanged: true,
                remunerationTextarea: e.target.value,
                compensationValue: e.target.value
            } as ComponentState,
            () => {
                this.sendForm();
            }
        );
    };

    handleBlur = (e: any): void => {
        if (e.target.value.indexOf("%") === -1) {
            e.target.value = e.target.value + " %";
            this.setState({ compensationValue: e.target.value }, () => {
                this.sendForm();
            });
        }
    };

    render() {
        return (
            <section className="parallax-layer">
                <Heading rank={2} text={"5.0 Vergütungsmodell"} />

                <Subheadline>
                    Welches Vergütungsmodell soll ausgewählt werden?
                </Subheadline>

                <div className="box-model">
                    <Grid fluid>
                        <Row className={"row--bottom-24"}>
                            <Col xs={5}>
                                <InputRadioCheckbox
                                    inputType={"radio"}
                                    id={"withoutRemuneration"}
                                    name={"pricemodel"}
                                    label={"ohne erfolgsabhängige\nVergütung"}
                                    value={"withoutRemuneration"}
                                    isLongBox={true}
                                    onChange={this.handleRadioChange}
                                    checked={
                                        this.state.remuneration ===
                                        "withoutRemuneration"
                                    }
                                >
                                    <SVGWithoutEav
                                        className={
                                            "form-item-radio__label-icon"
                                        }
                                        viewBox={"0 0 14 14"}
                                    />
                                </InputRadioCheckbox>
                            </Col>
                            <Col xs={5}>
                                <InputRadioCheckbox
                                    inputType={"radio"}
                                    id={"withRemuneration"}
                                    name={"pricemodel"}
                                    label={"mit erfolgsabhängiger\nVergütung"}
                                    isLongBox={true}
                                    value={"withRemuneration"}
                                    onChange={this.handleRadioChange}
                                    checked={
                                        this.state.remuneration ===
                                        "withRemuneration"
                                    }
                                >
                                    <SVGWithEav
                                        className={
                                            "form-item-radio__label-icon"
                                        }
                                        viewBox={"0 0 14 14"}
                                    />
                                </InputRadioCheckbox>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <InfoText isSmall={true} noMargins={true}>
                                    <span>Anlagestrategie:</span>
                                    {" " + getStrategyName() + " "}
                                    bei
                                    <Textarea
                                        className={"textarea-in-textcopy"}
                                        rows={1}
                                        placeholderValue={this.state.compensationValue.replace(
                                            /\./g,
                                            ","
                                        )}
                                        additionalClass={"textarea-active"}
                                        onChange={this.handleInputChange}
                                        onBlur={this.handleBlur}
                                        label={"Prozentsatz"}
                                    />
                                    p.a.{" "}
                                    {this.state.isLegalPerson ? (
                                        <>(exklusive MwSt.)</>
                                    ) : (
                                        <>(inklusive MwSt.)</>
                                    )}
                                </InfoText>
                            </Col>
                        </Row>
                    </Grid>
                </div>

                <Grid fluid className={"bottom-sticky"}>
                    <Row>
                        <Col xs={12} className={"align-right"}>
                            <BackToSummary />
                            <Button
                                styling={"primary"}
                                onClick={this.handleFormSubmission}
                                isDisabled={!this.state.remuneration}
                            >
                                Weiter
                            </Button>
                        </Col>
                    </Row>
                </Grid>
            </section>
        );
    }
}

const mapStaTeToProps = (props: ComponentProps) => {
    return { currentPage: props.currentPage, forms: props.forms };
};

export default connect(mapStaTeToProps, {
    updateCurrentPage,
    updateForm
})(Page12);
