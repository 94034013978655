import React, { Component, ReactElement } from "react";
import "./Header.scss";
import { connect } from "react-redux";
import {
    updateCurrentPage,
    resetForm,
    updateListOfPages,
    updateHeaderLogoRightSideVisible
} from "../../store/data/actions";
import Modal from "../Modal/Modal";
import Button from "../Button/Button";
import { BrandingTypes } from "../../store/data/types";
import RouteUtil, { RoutesPage } from "../../route/RouteUtil";
import Brandings from "../../components/Brandings/Brandings";
import LogoFinanzgruppe from "../svg/LogoFinanzgruppe";
import DekaLogoNoBg from "../svg/DekaLogoNoBg";

interface ComponentProps {
    resetForm: Function;
    updateCurrentPage: Function;
    currentBroker: string;
    listOfPages: RoutesPage[];
    updateListOfPages: Function;
    isHeaderLogoRightSideVisible: boolean;
    updateHeaderLogoRightSideVisible: Function;
}

interface ComponentState {
    isWarningModalVisible: boolean;
}

class Header extends Component<ComponentProps> {
    state: ComponentState = {
        isWarningModalVisible: false
    };

    resetPage = () => {
        document
            .getElementsByClassName("site")[0]
            .classList.remove("site--show");

        document
            .getElementsByClassName("full-overlay")[0]
            .classList.remove("full-overlay--disapear");

        this.setState({ isWarningModalVisible: false });

        this.props.updateCurrentPage(1);

        this.props.updateHeaderLogoRightSideVisible(true);

        setTimeout(() => {
            // Erase the data of all the forms
            this.props.resetForm();

            // Reset the "isDisable" status of the pages
            const listOfPages = RouteUtil.getPages();
            this.props.updateListOfPages([...listOfPages]);
        }, 0);
    };

    handleClick = () => {
        this.setState({ isWarningModalVisible: true });
    };

    warningModal: ReactElement = (
        <div className={"modal__button-wrapper"}>
            <Button
                styling={"primary"}
                onClick={this.resetPage}
                additionalClass={"button__no-icon"}
            >
                Neuen Vorgang starten
            </Button>
        </div>
    );

    render() {
        if (!this.props.currentBroker) {
            return <p />;
        }

        const currentBrand: BrandingTypes = Brandings(this.props.currentBroker);

        const logoLeft = currentBrand.logo;
        let logoRight;

        if (currentBrand.hasFinanzGruppeLogo) {
            logoRight = <LogoFinanzgruppe viewBox={"0 0 126 28"} />;
        } else {
            logoRight = (
                <div className="logo-wrapper">
                    <span className={"logo-wrapper--text"}>
                        In Kooperation mit
                    </span>
                    <DekaLogoNoBg
                        viewBox={"0 0 464 104"}
                        className={"header__content-logo-no-bg"}
                    />
                </div>
            );
        }

        return (
            <>
                <header className={"header"}>
                    <div
                        className={"header__content"}
                        onClick={this.handleClick}
                    >
                        <div className={"header__content-logo--left-side"}>
                            {logoLeft}
                        </div>
                        <div className={"header__content-pipe"}>|</div>
                        <div className={"header__content-text"}>
                            Vermögensverwaltung
                        </div>
                        {logoRight &&
                            this.props.isHeaderLogoRightSideVisible && (
                                <div
                                    className={
                                        "header__content-logo--right-side"
                                    }
                                >
                                    {logoRight}
                                </div>
                            )}
                    </div>
                </header>

                <Modal
                    isVisible={this.state.isWarningModalVisible}
                    hasCloseX={true}
                    buttonWrapper={this.warningModal}
                    headlineText={"Neuen Vorgang starten?"}
                    onExit={() => {
                        this.setState({ isWarningModalVisible: false });
                    }}
                >
                    Alle erhobenen Daten gehen verloren, wenn Sie einen neuen
                    Vorgang beginnen. Haben Sie das Ergebnis gespeichert?
                </Modal>
            </>
        );
    }
}

const mapStaTeToProps = (props: ComponentProps) => {
    return {
        currentBroker: props.currentBroker,
        listOfPages: props.listOfPages,
        isHeaderLogoRightSideVisible: props.isHeaderLogoRightSideVisible
    };
};

export default connect(mapStaTeToProps, {
    updateCurrentPage,
    resetForm,
    updateListOfPages,
    updateHeaderLogoRightSideVisible
})(Header);
