import React from "react";
import "./AccordionTableItem.scss";

interface AccordionListItemProps {
    listItemTitle: string;
    onClick?: any;
    noBorder?: boolean;
    noBorderInner?: boolean;
}

const AccordionTableItem: React.FC<AccordionListItemProps> = ({
    listItemTitle,
    children,
    onClick,
    noBorder,
    noBorderInner
}) => {
    return (
        <div
            onClick={onClick}
            className={
                "accordion-list-item" +
                (noBorder ? " accordion-list-item__no-border" : "")
            }
        >
            <div
                className={
                    "accordion-list-item__left" +
                    (noBorder && !noBorderInner
                        ? " accordion-list-item__no-border--left"
                        : "")
                }
            >
                {listItemTitle}
            </div>
            <div
                className={
                    "accordion-list-item__right" +
                    (noBorder && !noBorderInner
                        ? " accordion-list-item__no-border--right"
                        : "")
                }
            >
                {children}
            </div>
        </div>
    );
};

export default AccordionTableItem;
