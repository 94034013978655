import React, { Component } from "react";
import { connect } from "react-redux";
import "./Content.scss";
import styles from "./Content.scss";
import RouteUtil, { RoutesPage } from "../../route/RouteUtil";
import {
    updateListOfPages,
    updateCurrentPage,
    updateForm
} from "../../store/data/actions";

interface ComponentProps {
    currentPage: number;
    prevProps?: Object;
    listOfPages: RoutesPage[];
    updateListOfPages: Function;
    updateCurrentPage: Function;
    updateForm: Function;
}

interface ComponentState {
    nextPage: React.Component | string;
    currentPage: React.Component | string;
    previousPage: React.Component | string;
    forward: boolean;
    backward: boolean;
    listComponentPages: Array<React.FC>;
    currentPageData: any;
}

type scrollClass = "scrollNext" | "scrollBack" | "";

class Content extends Component<ComponentProps, ComponentState> {
    state = {
        nextPage: "",
        currentPage: "",
        previousPage: "",
        forward: false,
        backward: false,
        listComponentPages: [],
        currentPageData: {
            isDisabled: false,
            pageNumber: 1
        }
    };

    getClass = (): scrollClass => {
        if (this.state.forward) {
            return "scrollNext";
        }

        if (this.state.backward) {
            return "scrollBack";
        }
        return "";
    };

    sendForm = () => {
        this.props.updateForm({
            pageNumber: this.props.currentPage,
            data: {}
        });
    };

    scrollPage: Function = (
        prevProps: ComponentProps,
        prevState: ComponentState
    ) => {
        const currentPageData = this.props.listOfPages.find(item => {
            return item.pageNumber === this.props.currentPage;
        });

        let previousSelect = prevProps.currentPage;

        if (this.state.currentPageData.isDisabled) {
            previousSelect = prevState.currentPageData.pageNumber;
        }

        // if the user select a page that is BEFORE the current one scroll the pages down before scrolling up
        if (this.props.currentPage < prevProps.currentPage) {
            const nextPage = this.state.listComponentPages[previousSelect - 1];
            this.setState({ nextPage, backward: true });

            if (currentPageData!.isDisabled) {
                this.sendForm();
                this.props.updateCurrentPage(this.props.currentPage - 1);
            }
        }
        // if the user select a page that is AFTER the current one scroll the page down
        if (this.props.currentPage > prevProps.currentPage) {
            const previousPage = this.state.listComponentPages[
                previousSelect - 1
            ];
            this.setState({ previousPage, forward: true });
            if (currentPageData!.isDisabled) {
                this.sendForm();
                this.props.updateCurrentPage(this.props.currentPage + 1);
            }
        }

        this.setState({ currentPageData }, () => {
            // remove the unused page
            setTimeout(() => {
                this.setState({
                    nextPage: "",
                    previousPage: "",
                    forward: false,
                    backward: false
                });
            }, parseInt(styles.scrollTime, 10)); // this variable comes from the .scss file
        });
    };

    componentDidMount() {
        const listOfPages = RouteUtil.getPages();
        this.props.updateListOfPages([...listOfPages]);
        const currentPage: React.Component = RouteUtil.getComponentPages()[
            this.props.currentPage - 1
        ];
        this.setState({
            currentPage,
            listComponentPages: RouteUtil.getComponentPages()
        });
    }

    componentDidUpdate(prevProps: ComponentProps, prevStates: ComponentState) {
        if (this.props.currentPage !== prevProps.currentPage) {
            const currentPage = this.state.listComponentPages[
                this.props.currentPage - 1
            ];
            this.setState({ currentPage });

            this.scrollPage(prevProps, prevStates);
        }
    }

    render() {
        const PreviousPage: React.Component | string = this.state.previousPage;
        const CurrentPage: React.Component | string = this.state.currentPage;
        const NextPage: React.Component | string = this.state.nextPage;

        return (
            <main className="main-content">
                <div className={"main-content__wrapper " + this.getClass()}>
                    {this.state.currentPage && CurrentPage}
                    {this.state.previousPage && PreviousPage}
                    {this.state.nextPage && NextPage}
                </div>
            </main>
        );
    }
}

const mapStaTeToProps = (props: ComponentProps) => {
    return {
        currentPage: props.currentPage,
        listOfPages: props.listOfPages
    };
};

export default connect(mapStaTeToProps, {
    updateCurrentPage,
    updateListOfPages,
    updateForm
})(Content);
