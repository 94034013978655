import RouteUtil from "./RouteUtil";

export default class RouteController {
    static update(currentPageNumber: number): void {
        this.updatePath(currentPageNumber);
    }

    static updatePath(currentPageNumber: number): void {
        const page = RouteUtil.getPages().find(
            item => item.pageNumber === currentPageNumber
        );
        window.location.hash = "#" + page!.path;
    }
}
