import React, { Component } from "react";
import Heading from "../../components/Heading/Heading";
import Button from "../../components/Button/Button";
import RangeSlider from "../../components/RangeSlider/RangeSlider";
import Subheadline from "../../components/Subheadline/Subheadline";
import { updateCurrentPage, updateForm } from "../../store/data/actions";
import { connect } from "react-redux";
import { StoreManager } from "../../store";
import BackToSummary from "../../components/BackToSummary/BackToSummary";
import { Form } from "../../store/data/types";
import path from "../../components/utils/GetPageNumberByPath";
import { Grid, Row, Col } from "react-flexbox-grid";
import { sliderPValue, compensationValue } from "./sliderRelatedValues";
import {
    sliderLabels,
    sliderLabelsFondsbausteine,
    sliderLabelsDistributionFocus
} from "./sliderValues";
import generateRecommendationText from "../../components/utils/GenerateRecommendationText";
import InfoBox from "../../components/InfoBox/InfoBox";

interface ComponentProps {
    currentPage: number;
    updateForm: Function;
    updateCurrentPage: Function;
    forms: Form[];
    path: string;
}

interface ComponentState {
    value: number;
    percentageRate: any;
    isDisabled: boolean;
    sliderPValue: Array<Object>;
    compensationValue: Array<Object>;
    implementationOption: any;
}

class Page08 extends Component<ComponentProps, ComponentState> {
    state = {
        value: 50,
        percentageRate: "",
        isDisabled: false,
        sliderPValue: [],
        compensationValue: [],
        implementationOption: ""
    };

    sendForm = () => {
        this.props.updateForm({
            pageNumber: path(this.props.path),
            data: this.state
        });
    };

    componentDidMount() {
        const find: Form | undefined = StoreManager.getState().forms.find(
            item => item.pageNumber === path(this.props.path)
        );
        if (find) {
            this.setState(find.data as ComponentState, () =>
                this.setState({ isDisabled: false }, () => {
                    this.sendForm();
                })
            );
        } else {
            this.setInitialSlider();
        }

        this.checkImplementationOption();
        this.addCurrentToActiveMark();
    }

    setInitialSlider = () => {
        // Set an automatic initial slider value based  on the user choice on the "risikobereitschaft" page (2.3)
        const findPercent:
            | Form
            | undefined = StoreManager.getState().forms.find(
            itemPercent => itemPercent.pageNumber === path("risikobereitschaft")
        );

        if (findPercent) {
            let percentageRateMark = findPercent.data.percentageRateMark || 0;

            if (
                this.checkImplementationOption() ===
                "einzeltitelUndAusschuettungsfokus"
            ) {
                switch (percentageRateMark) {
                    case 2:
                        percentageRateMark = 3;
                        break;
                    case 5:
                        percentageRateMark = 4;
                        break;
                    case 8:
                        percentageRateMark = 7;
                        break;
                }
            }

            this.setState(
                {
                    value: percentageRateMark * 10,
                    percentageRate: `${percentageRateMark * 10}%`,
                    sliderPValue: sliderPValue[percentageRateMark * 10],
                    compensationValue:
                        compensationValue[percentageRateMark * 10]
                } as ComponentState,
                () => {
                    this.sendForm();
                }
            );
        }
    };

    checkImplementationOption = () => {
        // Set slider values based on the users choice on page 3.1
        const find: Form | undefined = StoreManager.getState().forms.find(
            item => item.pageNumber === path("umsetzungsoptionen")
        );

        if (find) {
            this.setState(
                {
                    implementationOption: find.data.implementationOption
                },
                () => this.isSliderZero()
            );
            return find.data.implementationOption;
        }
    };

    isSliderZero = () => {
        if (
            this.state.implementationOption ===
                "einzeltitelUndAusschuettungsfokus" &&
            this.state.value === 0
        ) {
            this.setState({ isDisabled: true });
        } else {
            this.setState({ isDisabled: false });
        }
    };

    handleFormSubmission = (e: React.FormEvent): void => {
        e.preventDefault();
        this.props.updateCurrentPage(this.props.currentPage + 1);
    };

    handleInputChange = (value: number): void => {
        if (value === 25) {
            value = 20;
        } else if (value === 75) {
            value = 80;
        }
        this.setState(
            {
                value: value,
                percentageRate: sliderLabels[value],
                sliderPValue: sliderPValue[value],
                compensationValue: compensationValue[value]
            } as ComponentState,
            () => {
                this.isSliderZero();
                this.sendForm();
                generateRecommendationText();
            }
        );

        this.addCurrentToActiveMark();
    };

    getImplementationOption = () => {
        switch (this.state.implementationOption) {
            case "einzeltitel":
                return sliderLabels;
            case "fondsbausteine":
                return sliderLabelsFondsbausteine;
            case "einzeltitelUndAusschuettungsfokus":
                return sliderLabelsDistributionFocus;
        }
    };

    addCurrentToActiveMark = () => {
        // This is an workaround to deal with a bug o rc-slider external Library.
        // In some cases, the library will not add a class to tell which value
        // In the slider is currently selected.

        setTimeout(() => {
            const marks = document.querySelectorAll(
                ".rc-slider .rc-slider-mark-text-active"
            );

            if (marks.length > 0) {
                marks.forEach(element => {
                    element.classList.remove("rc-slider-mark-text-current");
                });

                marks[marks.length - 1].classList.add(
                    "rc-slider-mark-text-current"
                );
            }
        }, 5);
    };

    render() {
        return (
            <section className="parallax-layer">
                <Heading rank={2} text={"3.0 Anlagestrategie"} />
                <Heading rank={3} text={"3.3 Maximale Aktienquote"} />

                <Subheadline>
                    Welche maximale Aktienquote soll die Vermögensanlage haben?
                </Subheadline>

                <div className="box-model">
                    <Grid fluid>
                        <Row className={"row--bottom-68"}>
                            <Col xs={10}>
                                <RangeSlider
                                    min={0}
                                    max={100}
                                    marks={this.getImplementationOption()}
                                    dots={false}
                                    step={null}
                                    defaultValue={this.state.value}
                                    value={this.state.value}
                                    onChange={this.handleInputChange}
                                    additionalClass={
                                        "range-slider--short-labels"
                                    }
                                    hasSmallDots={true}
                                />
                            </Col>
                        </Row>
                    </Grid>
                </div>

                <div className="box-model">
                    <Grid fluid>
                        <Row>
                            <Col xs={10}>
                                <InfoBox hasInfoIcon={true}>
                                    <strong>Hinweis:</strong> Die neutrale
                                    Aktienquote beträgt{" "}
                                    {this.state.implementationOption ===
                                    "einzeltitelUndAusschuettungsfokus"
                                        ? "90%"
                                        : "75%"}{" "}
                                    der maximalen Aktienquote.
                                </InfoBox>
                            </Col>
                        </Row>
                    </Grid>
                </div>

                <Grid fluid className={"bottom-sticky"}>
                    <Row>
                        <Col xs={12} className={"align-right"}>
                            <BackToSummary />
                            <Button
                                styling={"primary"}
                                isDisabled={this.state.isDisabled}
                                onClick={this.handleFormSubmission}
                            >
                                Weiter
                            </Button>
                        </Col>
                    </Row>
                </Grid>
            </section>
        );
    }
}

const mapStaTeToProps = (props: ComponentProps) => {
    return {
        currentPage: props.currentPage,
        forms: props.forms
    };
};

export default connect(mapStaTeToProps, {
    updateCurrentPage,
    updateForm
})(Page08);
