import { Marks } from "rc-slider";

export const sliderLabels: Marks = {
    0: "0%",
    10: "10%",
    20: "20%",
    30: "30%",
    40: "40%",
    50: "50%",
    60: "60%",
    70: "70%",
    80: "80%",
    90: "90%",
    100: "100%"
};

export const sliderLabelsDistributionFocus: Marks = {
    0: "0%",
    30: "30%",
    40: "40%",
    70: "70%",
    100: "100%"
};

// Correct keys are 0, 20, 50, 80, 100
// We use 25 and 75 for visual purposes on the slider.
// But we change them on the page before send data to Json
export const sliderLabelsFondsbausteine: Marks = {
    0: "0%",
    25: "20%",
    50: "50%",
    75: "80%",
    100: "100%"
};

export const sliderLabelsFondsbausteineTrue: Marks = {
    0: "0%",
    20: "20%",
    50: "50%",
    80: "80%",
    100: "100%"
};
