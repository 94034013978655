import * as React from "react";

const SVGEuropeSustainable: React.FC<SvgProps> = props => (
    <svg width={14} height={14} {...props}>
        <path
            d="M8.333 5.026a.213.213 0 01.195-.003c.126.058 3.082 1.477 4.298 3.224.515.74.709 1.467.576 2.16-.128.665-.574 1.279-1.25 1.748l.52.962a.22.22 0 01-.193.324.222.222 0 01-.194-.115l-.522-.968c-.294.13-.601.22-.917.22-.244 0-.488-.04-.726-.12-.772-.261-1.391-.938-1.742-1.905-.728-2.001-.184-5.232-.161-5.37a.22.22 0 01.116-.157zm-1.47 6.235l.207.644h.675l-.546.397.209.644-.546-.398-.546.398.209-.644-.546-.397h.675l.208-.644zm-2.398-.698l.209.644h.674l-.546.398.209.644-.546-.398-.545.398.208-.644-.546-.398h.675l.208-.644zm4.142-5.01c-.116.844-.381 3.296.184 4.85.39 1.075 1.015 1.484 1.47 1.638.43.147.888.117 1.299-.06l-.744-1.38-1.695-.638a.22.22 0 01-.13-.283.223.223 0 01.285-.13l1.22.46-1.108-2.056a.218.218 0 01.089-.297.22.22 0 01.299.088l1.544 2.864.104-1.23c.01-.121.104-.216.238-.2a.22.22 0 01.2.237l-.16 1.9.242.45c.365-.26.89-.737 1.026-1.442.11-.572-.06-1.186-.505-1.825-.944-1.357-3.103-2.553-3.858-2.945zm-5.94 3.233l.209.644h.674l-.545.398.208.644-.546-.398-.545.398.208-.644-.546-.398h.675l.208-.644zm-.784-2.572l.208.643h.675l-.546.398.209.644-.546-.398-.546.398.209-.644L1 6.857h.674l.209-.643zm.662-2.573l.208.644h.674l-.545.397.208.644-.545-.398-.546.398.208-.644-.545-.397h.674l.209-.644zm8.568 0l.208.644h.675l-.546.397.209.644-.546-.398-.546.398.209-.644-.546-.397h.675l.208-.644zM4.31 1.785l.209.644h.674l-.545.398.208.643-.546-.397-.545.397.208-.643-.546-.398h.675l.208-.644zm5.037 0l.209.644h.674l-.546.398.209.643-.546-.397-.545.397.208-.643-.546-.398h.675l.208-.644zM6.862 1l.208.644h.675l-.546.398.209.643-.546-.398-.546.398.209-.643-.546-.398h.675L6.862 1z"
            fill="red"
            fillRule="evenodd"
        />
    </svg>
);

export default SVGEuropeSustainable;
