export const sliderPValue: any = {
    0: "P1",
    10: "P2",
    20: "P2",
    30: "P2",
    40: "P3",
    50: "P3",
    60: "P3",
    70: "P4",
    80: "P4",
    90: "P4",
    100: "P5"
};

export const compensationValue: any = {
    0: "1.00000",
    10: "1.20000",
    20: "1.20000",
    30: "1.20000",
    40: "1.30000",
    50: "1.30000",
    60: "1.30000",
    70: "1.40000",
    80: "1.40000",
    90: "1.40000",
    100: "1.50000"
};
