import React, { Component, ReactElement } from "react";
import Heading from "../../components/Heading/Heading";
import Button from "../../components/Button/Button";
import InputRadioCheckbox from "../../components/InputRadioCheckbox/InputRadioCheckbox";
import SVGGlobalFondsbausteinen from "../../components/svg/Fondsbausteinen";
import SVGGlobalEinzeltitel from "../../components/svg/Einzeltitel";
import SVGEinzeltitelUndAusschuettungsfokus from "../../components/svg/EinzeltitelUndAusschuettungsfokus";
import Subheadline from "../../components/Subheadline/Subheadline";
import { StoreManager } from "../../store";
import { connect } from "react-redux";
import {
    updateCurrentPage,
    updateForm,
    updateListOfPages
} from "../../store/data/actions";
import { RoutesPage } from "../../route/RouteUtil";
import BackToSummary from "../../components/BackToSummary/BackToSummary";
import { Form, ImplementationOption } from "../../store/data/types";
import path from "../../components/utils/GetPageNumberByPath";
import { Grid, Row, Col } from "react-flexbox-grid";
import { BrandingTypes } from "../../store/data/types";
import Brandings from "../../components/Brandings/Brandings";
import resetAusrichtungOptions from "../../components/utils/ResetAusrichtungOptions";
import convertToCurrency from "../../components/utils/ConvertToCurrency";
import generateRecommendationText from "../../components/utils/GenerateRecommendationText";
import Modal from "../../components/Modal/Modal";
import LinkStandard from "../../components/LinkStandard/LinkStandard";
import checkMoney from "../../components/utils/CheckMoney";
import resetSliderValue from "../../components/utils/ResetSliderValue";
import InfoBox from "../../components/InfoBox/InfoBox";

interface ComponentProps {
    currentPage: number;
    updateForm: Function;
    updateCurrentPage: Function;
    updateListOfPages: Function;
    listOfPages: RoutesPage[];
    forms: Form[];
    path: string;
    currentBroker: string;
}

interface ComponentState {
    implementationOption: ImplementationOption;
    implementationOptionLabel: string;
    isDisabled: boolean;
    filteredOptions: Array<OptionsType>;
    isModalVisible: boolean;
    isModalErrorVisible: boolean;
    sustainability: string;
}

interface OptionsType {
    type: ImplementationOption;
    label: string;
    boxList: Array<string>;
    svgIcon: string;
}

class Page06 extends Component<ComponentProps, ComponentState> {
    state: ComponentState = {
        implementationOption: "",
        implementationOptionLabel: "",
        isDisabled: true,
        filteredOptions: [],
        isModalVisible: false,
        isModalErrorVisible: false,
        sustainability: ""
    };

    createOptions = (): Array<OptionsType> => {
        const currentBrand: BrandingTypes = Brandings(this.props.currentBroker);
        const fondsbausteineMinAmount =
            currentBrand.minInvestmentVolume.fondsbausteine;
        const einzeltitelMinAmount =
            currentBrand.minInvestmentVolume.einzeltitel;
        const einzeltitelUndAusschuettungsfokusMinAmount =
            currentBrand.minInvestmentVolume.einzeltitelUndAusschuettungsfokus;

        return [
            {
                type: "fondsbausteine",
                label: "Mit exklusiv\n gemanagten Fondsbausteinen",
                boxList: [
                    `Umsetzung möglich ab ${convertToCurrency(
                        fondsbausteineMinAmount
                    )} Anlagevolumen`
                ],
                svgIcon: "SVGGlobalFondsbausteinen"
            },
            {
                type: "einzeltitel",
                label: "Mit Einzeltiteln",
                boxList: [
                    `Umsetzung möglich ab ${convertToCurrency(
                        einzeltitelMinAmount
                    )} Anlagevolumen`
                ],
                svgIcon: "SVGGlobalEinzeltitel"
            },
            {
                type: "einzeltitelUndAusschuettungsfokus",
                label:
                    "Mit exklusiv gemanagten Fondsbausteinen und Ausschüttungsfokus",
                boxList: [
                    `Umsetzung möglich ab ${convertToCurrency(
                        einzeltitelUndAusschuettungsfokusMinAmount
                    )} Anlagevolumen und der Prämisse Anlage mit Nachhaltigkeitsmerkmalen`
                ],
                svgIcon: "SVGEinzeltitelUndAusschuettungsfokus"
            }
        ];
    };

    sendForm = () => {
        this.props.updateForm({
            pageNumber: path(this.props.path),
            data: this.state
        });
    };

    componentDidMount() {
        const find = StoreManager.getState().forms.find(
            item => item.pageNumber === path(this.props.path)
        );
        if (find) {
            this.setState(find.data as ComponentState, () => {
                this.checkSustainabilityOption();
            });
        } else {
            this.sendForm();
            this.checkSustainabilityOption();
        }

        this.filterOptions();
    }

    disableAnlagevolumen = () => {
        StoreManager.getState().forms.forEach((item: Form) => {
            // Disable "Weiter" button on page 3.2 if amount is not valid anymore
            if (item.pageNumber === path("anlagevolumen")) {
                let newData = { ...item.data };
                newData.isDisabled = true;

                this.props.updateForm({
                    pageNumber: path("anlagevolumen"),
                    data: newData
                });
            }

            // Disable "Weiter" button on page 3.5 if the user entered data
            if (item.pageNumber === path("optionen")) {
                const enteredMoneyVolume = Number(
                    item.data.regularPayments?.minimumAmount
                        .replace(/\s€/g, "")
                        .split(".")
                        .join("")
                );

                if (enteredMoneyVolume > 0) {
                    let newData = { ...item.data };
                    newData.isDisabled = true;

                    this.props.updateForm({
                        pageNumber: path("optionen"),
                        data: newData
                    });
                }
            }
        });
    };

    handleFormSubmission = (e: React.FormEvent): void => {
        this.setState({ isModalVisible: false }, () => this.sendForm());
        e.preventDefault();
        this.props.updateCurrentPage(this.props.currentPage + 1);
    };

    handleInputChange = (e: any): void => {
        this.setState(
            {
                implementationOption: e.target.value,
                isDisabled: Boolean(!e.target.value),
                implementationOptionLabel: e.target.getAttribute("data-label"),
                isModalVisible: this.isPageFilled("anlagevolumen")
            } as ComponentState,
            () => {
                this.sendForm();
                this.disableAnlagevolumen();
                resetAusrichtungOptions();
                this.resetSlider();
            }
        );

        if (
            e.target.value === "einzeltitelUndAusschuettungsfokus" &&
            this.state.sustainability === "Nein"
        ) {
            this.setState({ isDisabled: true, isModalErrorVisible: true }, () =>
                this.sendForm()
            );
        }
    };

    resetSlider = () => {
        // Reset the automatic initial slider value based on the user choice on the "risikobereitschaft" page (2.3)
        const find: Form | undefined = StoreManager.getState().forms.find(
            item => item.pageNumber === path("risikobereitschaft")
        );

        if (find) {
            resetSliderValue(
                find.data.percentageRateMark,
                this.state.implementationOption
            );
            generateRecommendationText();
        }
    };

    getSvgComponent = (name: string) => {
        const customProps = {
            className: "form-item-radio__label-icon",
            viewBox: "0 0 14 14"
        };
        switch (name) {
            case "SVGGlobalFondsbausteinen":
                return <SVGGlobalFondsbausteinen {...customProps} />;
            case "SVGGlobalEinzeltitel":
                return <SVGGlobalEinzeltitel {...customProps} />;
            case "SVGEinzeltitelUndAusschuettungsfokus":
                return (
                    <SVGEinzeltitelUndAusschuettungsfokus {...customProps} />
                );
        }
    };

    createInfoBox = (index: number) => {
        return (
            <InfoBox
                key={index}
                isVisible={
                    this.state.implementationOption ===
                    this.state.filteredOptions[index].type
                }
                hasInfoIcon={true}
            >
                {this.state.filteredOptions[index].boxList}
            </InfoBox>
        );
    };
    createBox = (boxList: Array<OptionsType>) => {
        const newRowEachNthBox = 2;

        let rows = [],
            cols = [];

        for (let index = 0; index <= boxList.length - 1; index++) {
            const SvgCustom = this.getSvgComponent(
                this.state.filteredOptions[index].svgIcon
            );

            const box = (
                <Col xs={5} key={index}>
                    <InputRadioCheckbox
                        inputType={"radio"}
                        id={this.state.filteredOptions[index].type}
                        name={"implementationOption"}
                        label={this.state.filteredOptions[index].label}
                        dataLabel={this.state.filteredOptions[index].label}
                        value={this.state.filteredOptions[index].type}
                        checked={
                            this.state.implementationOption ===
                            this.state.filteredOptions[index].type
                        }
                        onChange={this.handleInputChange}
                        isLongBox={true}
                    >
                        {SvgCustom}
                    </InputRadioCheckbox>
                </Col>
            );

            cols.push(box);

            if (
                (index + 1) % newRowEachNthBox === 0 ||
                index + 1 === boxList.length
            ) {
                rows.push(
                    <Row
                        key={index}
                        className={
                            "row--bottom-" +
                            (index + 1 === boxList.length ? "48" : "24")
                        }
                    >
                        {cols}
                    </Row>
                );
                cols = [];
            }
        }

        return <>{rows}</>;
    };

    filterOptions = () => {
        const currentBrand: BrandingTypes = Brandings(this.props.currentBroker);
        const filteredOptions: OptionsType[] = this.createOptions().filter(
            (item: OptionsType) =>
                currentBrand.implementationOptions.indexOf(item.type) !== -1
        );

        this.setState({ filteredOptions });
        if (filteredOptions.length === 1) {
            this.setState(
                {
                    implementationOption: filteredOptions[0].type,
                    isDisabled: false,
                    implementationOptionLabel: filteredOptions[0].label
                },
                () => this.sendForm()
            );
        }
    };

    buttonModal: ReactElement = (
        <div className={"modal__button-wrapper"}>
            <Button
                styling={"primary"}
                additionalClass={"button__no-icon"}
                onClick={() => {
                    this.setState({ isModalVisible: false }, () =>
                        this.sendForm()
                    );
                }}
            >
                Ok, verstanden
            </Button>
        </div>
    );

    buttonErrorModal: ReactElement = (
        <div className={"modal__button-wrapper"}>
            <Button
                styling={"primary"}
                additionalClass={"button__no-icon"}
                onClick={() => {
                    this.setState({ isModalErrorVisible: false }, () =>
                        this.sendForm()
                    );
                }}
            >
                Ok, verstanden
            </Button>
        </div>
    );

    isPageFilled = (pagePath: string): boolean => {
        let pagesFilled: number[] = [];

        StoreManager.getState().forms.forEach((item: Form) => {
            pagesFilled.push(item.pageNumber);
        });

        return pagesFilled.indexOf(path(pagePath)) !== -1;
    };

    checkSustainabilityOption = () => {
        const find = this.props.forms.find(
            item => item.pageNumber === path("nachhaltigkeit")
        );

        if (find!.data.sustainability === "Nein") {
            this.setState({ sustainability: "Nein" }, () => this.sendForm());
        }

        if (
            find!.data.sustainability === "Nein" &&
            this.state.implementationOption ===
                "einzeltitelUndAusschuettungsfokus"
        ) {
            this.setState({ isDisabled: true }, () => this.sendForm());
        } else if (
            find!.data.sustainability === "Ja" &&
            this.state.implementationOption
        ) {
            this.setState({ sustainability: "Ja", isDisabled: false }, () =>
                this.sendForm()
            );
        }
    };

    generateModalContent = () => {
        return (
            <>
                {this.isPageFilled("anlagevolumen") && (
                    <li className="form-field__info-box-list-item">
                        Abschnitt 3.2 Anlagevolumen
                    </li>
                )}

                {this.isPageFilled("maximale-aktienquote") && (
                    <li className="form-field__info-box-list-item">
                        Abschnitt 3.3 Maximale Aktienquote
                    </li>
                )}
                {this.isPageFilled("ausrichtung") && (
                    <li className="form-field__info-box-list-item">
                        Abschnitt 3.4 Ausrichtung
                    </li>
                )}
                {this.isPageFilled("optionen") && checkMoney() && (
                    <li className="form-field__info-box-list-item">
                        Abschnitt 3.5 Optionen
                    </li>
                )}
            </>
        );
    };

    render() {
        return (
            <section className="parallax-layer">
                <Heading rank={2} text={"3.0 Anlagestrategie"} />
                <Heading rank={3} text={"3.1 Umsetzungsoptionen"} />

                <Subheadline>
                    Wie soll die Anlagestrategie in Abhängigkeit vom
                    Anlagevolumen umgesetzt werden?
                </Subheadline>

                {this.state.filteredOptions.length > 0 && (
                    <div className="box-model">
                        <Grid fluid>
                            {this.createBox(this.state.filteredOptions)}
                            <Row>
                                <Col xs={10}>
                                    {this.state.filteredOptions.map(
                                        (item: any, i: number) =>
                                            this.createInfoBox(i)
                                    )}
                                </Col>
                            </Row>
                        </Grid>
                    </div>
                )}

                <Grid fluid className={"bottom-sticky"}>
                    <Row>
                        <Col xs={12} className={"align-right"}>
                            <BackToSummary />
                            <Button
                                styling={"primary"}
                                onClick={this.handleFormSubmission}
                                isDisabled={this.state.isDisabled}
                            >
                                Weiter
                            </Button>
                        </Col>
                    </Row>
                </Grid>

                {this.state.isModalVisible && (
                    <Modal
                        isVisible={this.state.isModalVisible}
                        headlineText={"Hinweis:"}
                        hasCloseX={false}
                        buttonWrapper={this.buttonModal}
                        onExit={() => {
                            this.setState({ isModalVisible: false });
                        }}
                    >
                        Bitte treffen Sie eine neue Auswahl in den Abschnitten{" "}
                        <ul className="form-field__info-box-list">
                            {this.generateModalContent()}
                        </ul>
                        {!this.state.isDisabled && (
                            <>
                                <LinkStandard
                                    additionalClass={
                                        "link-standard--bold link-standard--is-inline"
                                    }
                                    onClick={this.handleFormSubmission}
                                >
                                    Weiter
                                </LinkStandard>{" "}
                                zum Abschnitt 3.2
                            </>
                        )}
                    </Modal>
                )}
                {this.state.isModalErrorVisible && (
                    <Modal
                        isVisible={this.state.isModalErrorVisible}
                        headlineText={"Hinweis:"}
                        hasCloseX={false}
                        buttonWrapper={this.buttonErrorModal}
                        onExit={() => {
                            this.setState({ isModalErrorVisible: false }, () =>
                                this.sendForm()
                            );
                        }}
                    >
                        Die Umsetzungsoption „Mit exklusiv gemanagten
                        Fondsbausteinen und Ausschüttungsfokus“ ist nur möglich
                        wenn unter Punkt{" "}
                        <LinkStandard
                            additionalClass={
                                "link-standard--bold link-standard--is-inline"
                            }
                            onClick={() => {
                                this.setState(
                                    { isModalErrorVisible: false },
                                    () => this.sendForm()
                                );
                                this.props.updateCurrentPage(
                                    path("nachhaltigkeit")
                                );
                            }}
                        >
                            2.2. Nachhaltigkeit
                        </LinkStandard>{" "}
                        als Prämisse nachhaltig „Ja“ ausgewählt ist.
                    </Modal>
                )}
            </section>
        );
    }
}

const mapStaTeToProps = (props: ComponentProps) => {
    return {
        currentPage: props.currentPage,
        listOfPages: props.listOfPages,
        forms: props.forms,
        currentBroker: props.currentBroker
    };
};

export default connect(mapStaTeToProps, {
    updateCurrentPage,
    updateForm,
    updateListOfPages
})(Page06);
