import React from "react";
import "./Accordion.scss";
import SVGDirection from "../svg/Direction";
import SVGPlus from "../svg/Plus";
import SVGMinusCenter from "../svg/MinusCenter";
import SVGCheckMark from "../svg/Checkmark";

interface AccordionProps {
    headline: string;
    isBigHeadline?: boolean;
    onMouseUp?: any;
    dataValue?: string;
    id?: string;
    checked?: boolean;
    openByDefault?: true;
    dontOpen?: boolean;
    showAccordion?: boolean;
}

class Accordion extends React.Component<AccordionProps> {
    panelRef = React.createRef<HTMLDivElement>();

    state = { firstLoad: true, checked: false, isOpen: false };

    handleAccordion = () => {
        this.setState({ isOpen: !this.state.isOpen });
    };

    componentDidUpdate(prevProps: AccordionProps): void {
        if (prevProps.checked !== this.props.checked) {
            this.setState({ checked: this.props.checked });
        }

        if (
            (this.props.checked || this.props.openByDefault) &&
            this.state.firstLoad
        ) {
            this.setState({ firstLoad: false, isOpen: true });
        }

        if (this.state.isOpen) {
            this.panelRef.current!.style.maxHeight =
                this.panelRef.current!.scrollHeight + 100 + "px";
        } else {
            this.panelRef.current!.style.maxHeight = "";
        }
    }

    handleCheckbox = (): void => {
        this.setState({ isOpen: this.props.checked });
    };

    render() {
        return (
            <div
                className={
                    "accordion-wrapper" +
                    (this.props.isBigHeadline
                        ? " accordion-wrapper--big-headline"
                        : "") +
                    (this.props.showAccordion ||
                    this.props.showAccordion === undefined
                        ? ""
                        : "  accordion-wrapper--hidden")
                }
            >
                <div
                    className={
                        "accordion__headline" +
                        (this.state.isOpen ? " active" : "") +
                        (this.state.checked ? " is-selected" : "")
                    }
                    data-value={this.props.dataValue}
                    data-checked={this.props.checked}
                >
                    <input
                        type={"checkbox"}
                        className={"accordion__headline-input"}
                    />
                    <label
                        onLoad={this.handleCheckbox}
                        id={this.props.id}
                        className={"accordion__headline-checkbox"}
                        onClick={this.handleCheckbox}
                        onMouseUp={this.props.onMouseUp}
                    >
                        <SVGCheckMark
                            className={"accordion__headline-checkbox-icon"}
                            viewBox={"0 0 50 36"}
                        />
                    </label>
                    <span
                        className={"accordion__headline-text"}
                        onClick={this.handleAccordion}
                    >
                        {this.props.isBigHeadline && !this.state.isOpen && (
                            <SVGPlus
                                className={"accordion__headline-plus"}
                                viewBox={"0 0 14 14"}
                            />
                        )}
                        {this.props.isBigHeadline && this.state.isOpen && (
                            <SVGMinusCenter
                                className={"accordion__headline-minus"}
                                viewBox={"0 0 14 14"}
                            />
                        )}
                        {this.props.headline}
                    </span>
                    <SVGDirection className={"accordion__headline-arrow"} />
                </div>
                <div ref={this.panelRef} className={"accordion__panel"}>
                    {this.props.children}
                </div>
            </div>
        );
    }
}

export default Accordion;
