import React, { Component } from "react";
import Modal from "../Modal/Modal";
import { connect } from "react-redux";
import { updateCurrentBroker } from "../../store/data/actions";

interface ComponentProps {
    intervalMs: number;
    intervalForbiddenMs: number;
    updateCurrentBroker: Function;
}

interface ComponentState {
    isModalVisible: boolean;
    interval: number;
    isFirstSuccessfulLoad: boolean;
}

class SessionControl extends Component<ComponentProps, ComponentState> {
    state: ComponentState = {
        isModalVisible: false,
        interval: this.props.intervalMs,
        isFirstSuccessfulLoad: true
    };

    componentDidMount(): void {
        this.checkSession();

        const fncCheck = () => {
            this.checkSession();
            setTimeout(fncCheck, this.state.interval);
        };
        setTimeout(fncCheck, this.state.interval);
    }

    checkSession(): void {
        fetch(process.env.REACT_APP_WS_PATH + "/auth/ping", {
            credentials: "same-origin"
        })
            .then(response => {
                if (response.status === 403) {
                    this.setState({
                        isModalVisible: true,
                        interval: this.props.intervalForbiddenMs
                    });
                } else {
                    this.setState({
                        isModalVisible: false,
                        interval: this.props.intervalMs
                    });
                    if (this.state.isFirstSuccessfulLoad) {
                        this.getBrokerData();
                        this.setState({ isFirstSuccessfulLoad: false });
                    }
                }
            })
            .catch(error => {
                console.error("Error:", error);
                this.setState({
                    isModalVisible: true,
                    interval: this.props.intervalForbiddenMs
                });
            });
    }

    getBrokerData(): void {
        fetch(process.env.REACT_APP_WS_PATH + "/auth/get", {
            credentials: "same-origin"
        })
            .then(response => response.json())
            .then(data => {
                if (data && data.vermittler) {
                    //this.props.updateCurrentBroker("51850079");
                    this.props.updateCurrentBroker(data.vermittler);
                }
            })
            .catch(error => {
                console.error("Error:", error);
            });
    }

    render() {
        return (
            <>
                <Modal
                    isVisible={this.state.isModalVisible}
                    hasCloseX={false}
                    headlineText={"Ihre Sitzung ist abgelaufen"}
                    onExit={() => {
                        this.setState({ isModalVisible: false });
                    }}
                >
                    Bitte rufen Sie die Anwendung erneut über das DekaNet auf.
                </Modal>
            </>
        );
    }
}

export default connect(null, {
    updateCurrentBroker: updateCurrentBroker
})(SessionControl);
