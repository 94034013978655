import * as React from "react";

const SVGOther: React.FC<SvgProps> = props => (
    <svg width={14} height={14} {...props}>
        <path
            d="M4 6.5a.5.5 0 110 1 .5.5 0 010-1zm3 0a.5.5 0 110 1 .5.5 0 010-1zm3 0a.5.5 0 110 1 .5.5 0 010-1z"
            fill="red"
            fillRule="evenodd"
        />
    </svg>
);

export default SVGOther;
