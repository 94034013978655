import React, { BaseSyntheticEvent } from "react";
import Heading from "../../components/Heading/Heading";
import Button from "../../components/Button/Button";
import InputText from "../../components/InputText/InputText";
import InputRadioCheckbox from "../../components/InputRadioCheckbox/InputRadioCheckbox";
import { updateCurrentPage, updateForm } from "../../store/data/actions";
import SVGTime from "../../components/svg/Time";
import SVGSack from "../../components/svg/Sack";
import SVGGraph from "../../components/svg/Graph";
import SVGCalendarCoins from "../../components/svg/CalendarCoins";
import SVGOther from "../../components/svg/Other";
import Subheadline from "../../components/Subheadline/Subheadline";
import { connect } from "react-redux";
import BackToSummary from "../../components/BackToSummary/BackToSummary";
import { Form } from "../../store/data/types";
import { StoreManager } from "../../store";
import path from "../../components/utils/GetPageNumberByPath";
import { Grid, Row, Col } from "react-flexbox-grid";
import generateRecommendationText from "../../components/utils/GenerateRecommendationText";

interface ComponentProps {
    currentPage: number;
    updateForm: Function;
    updateCurrentPage: Function;
    forms: Form[];
    path: string;
}

interface ComponentState {
    targetList: string[];
    targetListLabels: string[];
    otherInput: string;
    isTargetInputVisible: boolean;
    isDisabled: boolean;
}

class Page02 extends React.Component<ComponentProps, ComponentState> {
    state: ComponentState = {
        targetList: [],
        targetListLabels: [],
        otherInput: "",
        isTargetInputVisible: false,
        isDisabled: true
    };

    otherInputRef = React.createRef<HTMLDivElement>();
    sendForm = () => {
        this.props.updateForm({
            pageNumber: path(this.props.path),
            data: this.state
        });
    };

    componentDidMount() {
        const find: Form | undefined = StoreManager.getState().forms.find(
            item => item.pageNumber === path(this.props.path)
        );

        if (find) {
            this.setState(find.data as ComponentState);
        } else {
            this.sendForm();
        }
    }

    componentDidUpdate() {
        if (this.state.otherInput) {
            document
                .getElementById("otherInput")!
                .classList.add("form-item-text__input--active");
        }
    }

    handleFormSubmission = (e: React.FormEvent): void => {
        e.preventDefault();
        this.props.updateCurrentPage(this.props.currentPage + 1);
    };

    handleInputTextChange = (e: BaseSyntheticEvent) => {
        this.setState({ otherInput: e.target.value }, () => {
            this.sendForm();
            generateRecommendationText();
        });
    };

    handleCheckboxAndOpen = (e: BaseSyntheticEvent) => {
        const checked = e.target.checked;
        this.setState({ isTargetInputVisible: checked });
        if (!checked) {
            this.setState({ otherInput: "" }, () => this.sendForm());
        }
        this.handleCheckbox(e);
    };

    handleCheckbox = (e: BaseSyntheticEvent): void => {
        if (e.target.value === "other") {
            const node = this.otherInputRef.current;
            setTimeout(() => {
                node!.scrollIntoView();
            }, 0);
        }

        if (e.target.checked) {
            const label: string = e.target
                .getAttribute("data-label")
                .replace("\n", "");
            let targetListLabels = [...this.state.targetListLabels];
            if (label) {
                targetListLabels.push(label);
            }
            this.setState(
                {
                    targetList: [...this.state.targetList, e.target.value],
                    targetListLabels
                },
                () => {
                    this.toggleSubmitButton(!this.state.targetList.length);
                    this.sendForm();
                    generateRecommendationText();
                }
            );
        } else {
            const curr = this.state.targetList.filter(
                item => item !== e.target.value
            );
            const targetListLabels: string[] = this.state.targetListLabels.filter(
                label => {
                    return (
                        label !==
                        e.target.getAttribute("data-label").replace("\n", "")
                    );
                },
                () => this.sendForm()
            );
            this.setState({ targetList: curr, targetListLabels }, () => {
                this.toggleSubmitButton(!this.state.targetList.length);
                this.sendForm();
            });
        }
    };

    toggleSubmitButton = (isDisabled: boolean) => {
        this.setState({ isDisabled }, () => {
            this.sendForm();
        });
    };

    render() {
        return (
            <section className="parallax-layer">
                <Heading rank={2} text={"2.0 Prämissen"} />
                <Heading rank={3} text={"2.1 Anlageziel"} />

                <Subheadline>
                    Welches Anlageziel wird mit der Vermögensanlage verfolgt?
                </Subheadline>

                <div className="box-model box-model--bottom-0">
                    <Grid fluid>
                        <Row className={"row--bottom-24"}>
                            <Col xs={5}>
                                <InputRadioCheckbox
                                    inputType={"checkbox"}
                                    id={"retirementProvisions"}
                                    name={"investmentTarget"}
                                    label={"Altersvorsorge"}
                                    dataLabel={"Altersvorsorge"}
                                    value={"retirementProvisions"}
                                    checked={this.state.targetList.includes(
                                        "retirementProvisions"
                                    )}
                                    onChange={this.handleCheckbox}
                                    isLongBox={true}
                                >
                                    <SVGTime
                                        className={
                                            "form-item-radio__label-icon"
                                        }
                                        viewBox={"0 0 14 14"}
                                    />
                                </InputRadioCheckbox>
                            </Col>

                            <Col xs={5}>
                                <InputRadioCheckbox
                                    inputType={"checkbox"}
                                    id={"generalCapitalFormation"}
                                    name={"investmentTarget"}
                                    label={
                                        "Allgemeine Vermögensbildung/\n-optimierung"
                                    }
                                    dataLabel={
                                        "allgemeine Vermögensbildung/\n-optimierung"
                                    }
                                    value={"generalCapitalFormation"}
                                    checked={this.state.targetList.includes(
                                        "generalCapitalFormation"
                                    )}
                                    onChange={this.handleCheckbox}
                                    isLongBox={true}
                                >
                                    <SVGSack
                                        className={
                                            "form-item-radio__label-icon"
                                        }
                                        viewBox={"0 0 14 14"}
                                    />
                                </InputRadioCheckbox>
                            </Col>
                        </Row>
                        <Row className={"row--bottom-24"}>
                            <Col xs={5}>
                                <InputRadioCheckbox
                                    inputType={"checkbox"}
                                    id={"priceChanges"}
                                    name={"investmentTarget"}
                                    label={
                                        "Überproportionale Teilhabe an Kursveränderungen"
                                    }
                                    dataLabel={
                                        "überproportionale Teilhabe an Kursveränderungen"
                                    }
                                    value={"priceChanges"}
                                    checked={this.state.targetList.includes(
                                        "priceChanges"
                                    )}
                                    onChange={this.handleCheckbox}
                                    isLongBox={true}
                                >
                                    <SVGGraph
                                        className={
                                            "form-item-radio__label-icon"
                                        }
                                        viewBox={"0 0 14 14"}
                                    />
                                </InputRadioCheckbox>
                            </Col>

                            <Col xs={5}>
                                <InputRadioCheckbox
                                    inputType={"checkbox"}
                                    id={"payoutPlan"}
                                    name={"investmentTarget"}
                                    label={
                                        "Generierung regelmäßiger Auszahlungen"
                                    }
                                    dataLabel={
                                        "generierung regelmäßiger Auszahlungen"
                                    }
                                    value={"payoutPlan"}
                                    checked={this.state.targetList.includes(
                                        "payoutPlan"
                                    )}
                                    onChange={this.handleCheckbox}
                                    isLongBox={true}
                                >
                                    <SVGCalendarCoins
                                        className={
                                            "form-item-radio__label-icon"
                                        }
                                        viewBox={"0 0 14 14"}
                                    />
                                </InputRadioCheckbox>
                            </Col>
                        </Row>
                        <Row className={"row--bottom-0"}>
                            <Col xs={5}>
                                <InputRadioCheckbox
                                    inputType={"checkbox"}
                                    id={"loremIpsum"}
                                    name={"investmentTarget"}
                                    label={"Sonstiges"}
                                    dataLabel={this.state.otherInput}
                                    value={"other"}
                                    checked={this.state.targetList.includes(
                                        "other"
                                    )}
                                    onChange={this.handleCheckboxAndOpen}
                                    isLongBox={true}
                                >
                                    <SVGOther
                                        className={
                                            "form-item-radio__label-icon"
                                        }
                                        viewBox={"0 0 14 14"}
                                    />
                                </InputRadioCheckbox>
                            </Col>
                        </Row>
                        <Row
                            className={
                                "additional-investment-field " +
                                (this.state.isTargetInputVisible
                                    ? "additional-investment-field--show"
                                    : "")
                            }
                        >
                            <Col xs={12}>
                                <InputText
                                    ref={this.otherInputRef}
                                    id={"otherInput"}
                                    name={"otherInput"}
                                    value={this.state.otherInput}
                                    onChange={this.handleInputTextChange}
                                    placeHolder={"Abweichendes Anlageziel"}
                                    showPlaceholderAsLabel={true}
                                />
                            </Col>
                        </Row>
                    </Grid>
                </div>

                <Grid fluid className={"bottom-sticky"}>
                    <Row>
                        <Col xs={12} className={"align-right"}>
                            <BackToSummary />
                            <Button
                                styling={"primary"}
                                onClick={this.handleFormSubmission}
                                isDisabled={this.state.isDisabled}
                            >
                                Weiter
                            </Button>
                        </Col>
                    </Row>
                </Grid>
            </section>
        );
    }
}

const mapStaTeToProps = (props: ComponentProps) => {
    return { currentPage: props.currentPage, forms: props.forms };
};

export default connect(mapStaTeToProps, {
    updateCurrentPage,
    updateForm
})(Page02);
