import React from "react";
import "./NavigationOuterGroup.scss";
import NavigationSubLink from "../NavigationSubLink/NavigationSubLink";
import { connect } from "react-redux";
import { updateCurrentPage } from "../../store/data/actions";
import SVGDirection from "../svg/Direction";
import {
    RouterMenuGroup,
    RouterMenuGroupItem,
    RoutesConfig
} from "../../route/Routes";
import { Form } from "../../store/data/types";
import { RoutesPage } from "../../route/RouteUtil";
import SVGCheckMark from "../svg/Checkmark";
import findFirstDisabledPage from "../utils/FindFirstDisabledPage";

interface NavigationOuterGroupProps {
    currentPage: number;
    updateCurrentPage: Function;
    forms: Form[];
    listOfPages: RoutesPage[];
}
const NavigationOuterGroup: React.FC<NavigationOuterGroupProps> = ({
    currentPage,
    updateCurrentPage,
    forms,
    listOfPages
}) => {
    const items: Array<JSX.Element> = [];

    const getDisableClass = (pageNumber: number) => {
        if (findFirstDisabledPage() < pageNumber) {
            return " main-navigation--disabled";
        } else {
            return "";
        }
    };

    const isFormSubmitted: Function = (item: any) => {
        if (!item.subItems) {
            let form: Form[] = [...forms].filter(
                form =>
                    form.pageNumber === item.pageNumber ||
                    form.pageNumber === item.pageNumber + 1
            );
            let selectedForms = form.sort(function(
                previousForm: Form,
                nextForm: Form
            ) {
                return previousForm.pageNumber - nextForm.pageNumber;
            });
            if (selectedForms.length > 0) {
                if (!selectedForms[0].data.isDisabled && selectedForms[1]) {
                    return true;
                }
            }
        }
    };

    RoutesConfig.forEach((item: RouterMenuGroup, index: number) => {
        let groupPages: number[] = [item.pageNumber];
        if (item.subItems) {
            groupPages = item.subItems.map(item => item.pageNumber);
        }

        let activeClass = "";
        if (groupPages.includes(currentPage)) {
            activeClass = "main-navigation__item--active";
        }

        items.push(
            <li
                className={
                    "main-navigation__item " +
                    activeClass +
                    (!item.subItems
                        ? " main-navigation__item--no-subitems"
                        : "")
                }
                key={index}
            >
                <label
                    onClick={() => {
                        getDisableClass(item.pageNumber) ||
                            updateCurrentPage(item.pageNumber);
                    }}
                    className={
                        "main-navigation__item-label" +
                        getDisableClass(item.pageNumber)
                    }
                    htmlFor={item.title}
                >
                    {item.title}

                    {item.subItems && !getDisableClass(item.pageNumber) && (
                        <SVGDirection
                            viewBox={"0 0 14 14"}
                            className={"main-navigation__item-label-arrow"}
                        />
                    )}
                    {!item.subItems && isFormSubmitted(item) && (
                        <SVGCheckMark
                            viewBox={"0 0 50 36"}
                            className={"main-navigation__item-label-check"}
                        />
                    )}
                </label>

                <ul className={"main-navigation__sublist"}>
                    {item.subItems &&
                        item.subItems.map(
                            (subItem: RouterMenuGroupItem, subIndex) => {
                                return (
                                    <NavigationSubLink
                                        key={subIndex}
                                        item={subItem}
                                        className={"main-navigation__subitem"}
                                    >
                                        {subItem.title}
                                    </NavigationSubLink>
                                );
                            }
                        )}
                </ul>
            </li>
        );
    });

    return <ul>{items}</ul>;
};

const mapStaTeToProps = (state: NavigationOuterGroupProps) => {
    return {
        currentPage: state.currentPage,
        forms: state.forms,
        listOfPages: state.listOfPages
    };
};

export default connect(mapStaTeToProps, {
    updateCurrentPage: updateCurrentPage
})(NavigationOuterGroup);
