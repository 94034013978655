import Heading from "../../components/Heading/Heading";
import Subheadline from "../../components/Subheadline/Subheadline";
import SVGSustainable from "../../components/svg/Sustainable";
import { StoreManager } from "../../store";
import SVGUnsustainable from "../../components/svg/Unsustainable";
import Button from "../../components/Button/Button";
import { updateCurrentPage, updateForm } from "../../store/data/actions";
import React, { ReactElement } from "react";
import { connect } from "react-redux";
import InputRadioCheckbox from "../../components/InputRadioCheckbox/InputRadioCheckbox";
import BackToSummary from "../../components/BackToSummary/BackToSummary";
import { Form } from "../../store/data/types";
import path from "../../components/utils/GetPageNumberByPath";
import { Grid, Row, Col } from "react-flexbox-grid";
import resetAusrichtungOptions from "../../components/utils/ResetAusrichtungOptions";
import Modal from "../../components/Modal/Modal";
import LinkStandard from "../../components/LinkStandard/LinkStandard";
import findFirstDisabledPage from "../../components/utils/FindFirstDisabledPage";
import InfoBox from "../../components/InfoBox/InfoBox";

interface ComponentProps {
    currentPage: number;
    updateForm: Function;
    updateCurrentPage: Function;
    forms: Form[];
    path: string;
}

interface ComponentState {
    sustainability: string;
    isDisabled: boolean;
    isModalVisible: boolean;
    modalVersion: number;
    blockLinkToAusrichtung: boolean;
}

class Page03 extends React.Component<ComponentProps, ComponentState> {
    state: ComponentState = {
        sustainability: "",
        isDisabled: true,
        isModalVisible: false,
        modalVersion: 1,
        blockLinkToAusrichtung: true
    };

    buttonModal: ReactElement = (
        <div className={"modal__button-wrapper"}>
            <Button
                styling={"primary"}
                additionalClass={"button__no-icon"}
                onClick={() => {
                    this.setState({ isModalVisible: false }, () =>
                        this.sendForm()
                    );
                }}
            >
                Ok, verstanden
            </Button>
        </div>
    );

    sendForm = () => {
        this.props.updateForm({
            pageNumber: path(this.props.path),
            data: this.state
        });
    };

    componentDidMount() {
        const find = StoreManager.getState().forms.find(item => {
            return item.pageNumber === path(this.props.path);
        });
        if (find) {
            this.setState(find.data as ComponentState, () =>
                this.checkAusrichtungStatus()
            );
        } else {
            this.sendForm();
        }
    }

    handleFormSubmission = (e: React.FormEvent): void => {
        e.preventDefault();
        this.props.updateCurrentPage(this.props.currentPage + 1);
    };

    handleInputChange = (e: any): void => {
        let modalVersion = 1;
        let isModalVisible = this.state.isModalVisible;
        if (
            e.target.value === "Nein" &&
            this.checkImplementationOption() ===
                "einzeltitelUndAusschuettungsfokus"
        ) {
            modalVersion = 2;
            isModalVisible = true;
            this.changeUmsetzungsOptionenPageButtonStatus(true);
        } else if (
            e.target.value === "Nein" &&
            this.checkImplementationOption() ===
                "einzeltitelUndAusschuettungsfokus"
        ) {
            this.changeUmsetzungsOptionenPageButtonStatus(false);
        }

        this.checkAusrichtungStatus();

        this.setState(
            {
                isDisabled: false,
                modalVersion: modalVersion,
                [e.target.name]: e.target.value,
                isModalVisible: isModalVisible
            } as ComponentState,
            () => {
                this.sendForm();
                this.handleAusrichtungOptions();
            }
        );
    };

    changeUmsetzungsOptionenPageButtonStatus = (isDisabled: boolean) => {
        const find = this.props.forms.find(
            item => item.pageNumber === path("umsetzungsoptionen")
        );

        if (find) {
            let newData = { ...find.data };
            newData.isDisabled = isDisabled;

            this.props.updateForm({
                pageNumber: path("umsetzungsoptionen"),
                data: newData
            });
        }
    };

    handleAusrichtungOptions = () => {
        const find: Form | undefined = StoreManager.getState().forms.find(
            item => item.pageNumber === path("ausrichtung")
        );

        if (find) {
            resetAusrichtungOptions();
            this.setState({ isModalVisible: true });
        }
    };

    checkAusrichtungStatus = () => {
        const blockLinkToAusrichtung =
            findFirstDisabledPage() < path("ausrichtung");

        this.setState({ blockLinkToAusrichtung });
    };

    checkImplementationOption = () => {
        const find = this.props.forms.find(
            item => item.pageNumber === path("umsetzungsoptionen")
        );

        if (find) {
            return find.data.implementationOption;
        }

        return "";
    };

    modalVersion1 = () => {
        return (
            <>
                Bitte treffen Sie gegebenenfalls eine neue Auswahl im{" "}
                <LinkStandard
                    isDisabled={this.state.blockLinkToAusrichtung}
                    additionalClass={
                        "link-standard--is-inline" +
                        (!this.state.blockLinkToAusrichtung
                            ? " link-standard--bold"
                            : "")
                    }
                    onClick={() => {
                        !this.state.blockLinkToAusrichtung &&
                            this.props.updateCurrentPage(path("ausrichtung"));
                    }}
                >
                    Abschnitt 3.4
                </LinkStandard>{" "}
                bezüglich der Ausrichtung des Mandates.
            </>
        );
    };

    modalVersion2 = () => {
        return (
            <>
                Sie können nicht die Auswahl „Nein“ mit der Umsetzungsoption
                „Mit exklusiv gemanagten Fondsbausteinen und Ausschüttungsfokus“
                unter{" "}
                <LinkStandard
                    additionalClass={
                        "link-standard--is-inline link-standard--bold"
                    }
                    onClick={() => {
                        this.setState({ isModalVisible: false }, () =>
                            this.sendForm()
                        );
                        this.props.updateCurrentPage(
                            path("umsetzungsoptionen")
                        );
                    }}
                >
                    Abschnitt 3.1
                </LinkStandard>{" "}
                kombinieren. Bitte wählen Sie „Ja“ aus oder passen Sie die
                Umsetzungsoption unter Abschnitt 3.1. an
            </>
        );
    };

    render() {
        return (
            <section className="parallax-layer">
                <Heading rank={2} text={"2.0 Prämissen"} />
                <Heading rank={3} text={"2.2 Nachhaltigkeitskriterien"} />

                <Subheadline>
                    Sollen nachhaltige Kriterien nach Möglichkeit bei der
                    Vermögensanlage berücksichtigt werden?
                </Subheadline>

                <div className="box-model">
                    <Grid fluid>
                        <Row className={"row--bottom-48"}>
                            <Col xs={5}>
                                <InputRadioCheckbox
                                    inputType={"radio"}
                                    id={"option2"}
                                    name={"sustainability"}
                                    label={"Nein"}
                                    value={"Nein"}
                                    checked={
                                        this.state.sustainability === "Nein"
                                    }
                                    onChange={this.handleInputChange}
                                    isLongBox={true}
                                >
                                    <SVGUnsustainable
                                        className={
                                            "form-item-radio__label-icon"
                                        }
                                        viewBox={"0 0 14 14"}
                                    />
                                </InputRadioCheckbox>
                            </Col>
                            <Col xs={5}>
                                <InputRadioCheckbox
                                    inputType={"radio"}
                                    id={"option1"}
                                    name={"sustainability"}
                                    label={"Ja"}
                                    value={"Ja"}
                                    checked={this.state.sustainability === "Ja"}
                                    onChange={this.handleInputChange}
                                    isLongBox={true}
                                >
                                    <SVGSustainable
                                        className={
                                            "form-item-radio__label-icon"
                                        }
                                        viewBox={"0 0 14 14"}
                                    />
                                </InputRadioCheckbox>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={10}>
                                <InfoBox
                                    isVisible={
                                        this.state.sustainability === "Ja"
                                    }
                                    hasInfoIcon={true}
                                >
                                    Auswahl der Finanzinstrumente nach
                                    Grundsätzen der Nachhaltigkeit
                                </InfoBox>
                            </Col>
                        </Row>
                    </Grid>
                </div>

                <Grid fluid className={"bottom-sticky"}>
                    <Row>
                        <Col xs={12} className={"align-right"}>
                            <BackToSummary />
                            <Button
                                styling={"primary"}
                                onClick={this.handleFormSubmission}
                                isDisabled={this.state.isDisabled}
                            >
                                Weiter
                            </Button>
                        </Col>
                    </Row>
                </Grid>

                <Modal
                    isVisible={this.state.isModalVisible}
                    hasCloseX={false}
                    headlineText={"Hinweis:"}
                    buttonWrapper={this.buttonModal}
                    onExit={() => {
                        this.setState({ isModalVisible: false });
                    }}
                >
                    {this.state.modalVersion === 1
                        ? this.modalVersion1()
                        : this.modalVersion2()}
                </Modal>
            </section>
        );
    }
}

const mapStaTeToProps = (props: ComponentProps) => {
    return { currentPage: props.currentPage, forms: props.forms };
};

export default connect(mapStaTeToProps, {
    updateCurrentPage,
    updateForm
})(Page03);
