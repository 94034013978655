import { RegularPayments } from "../../pages/Page10/Page10";
import { ReactElement } from "react";

export const FORM_UPDATE = "@data/FORM_UPDATE";
export const FORM_IMPORT = "@data/FORM_IMPORT";
export const FORM_RESET = "@data/FORM_RESET";
export const CURRENT_PAGE = "@data/CURRENT_PAGE";
export const CURRENT_BROKER = "@data/CURRENT_BROKER";
export const LIST_OF_PAGES = "@data/LIST_OF_PAGES";
export const HEADER_LOGO_STATUS = "@data/HEADER_LOGO_STATUS";

export interface Category {
    originalCompensationValue?: string;
    hasValueChanged?: Boolean;
    regularPayments?: RegularPayments;
    strategyLabel?: string;
    sliderPValue?: any;
    compensationValue?: any;
    isDisabled?: boolean;
    moneyVolume?: string | undefined;
    strategy?: string;
    isLegalPerson?: boolean;
    percentageRateMark?: number;
    changeHappens?: boolean;
    sustainability?: string;
    percentageRate?: string;
    value?: number;
    printMusterPortfolio?: boolean;
    productName?: string;
    implementationOption?: string;
    resetSubForm?: boolean;
    showSub?: boolean;
}

export type ImplementationOption =
    | "einzeltitel"
    | "fondsbausteine"
    | "einzeltitelUndAusschuettungsfokus"
    | "";

export interface Form {
    pageNumber: number;
    data: Category;
}

export interface FormUpdateAction {
    type: string;
    payload: Form;
}
export interface FormImportAction {
    type: string;
    content: Form[];
}
export type FormActionTypes = FormUpdateAction | FormImportAction;

export interface PageAction {
    type: string;
    payload: number;
}

export interface HeaderLogoStatusAction {
    type: string;
    payload: boolean;
}

export interface BrokerAction {
    type: string;
    payload: string;
}

export interface listOfPagesAction {
    type: string;
    payload: any;
}

interface MinInvestmentVolume {
    einzeltitel: Number | null;
    fondsbausteine: Number | null;
    einzeltitelUndAusschuettungsfokus: Number | null;
    ""?: Number | null;
}

export interface BrandingTypes {
    additionalClass?: string;
    brand: string;
    productName: string;
    backgroundImage: string;
    implementationOptions: string[];
    minInvestmentVolume: MinInvestmentVolume;
    logo: ReactElement;
    hasFinanzGruppeLogo: boolean;
    hasExclusiveExtraPdf: boolean;
}
