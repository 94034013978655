import * as React from "react";

const SVGDirection: React.FC<SvgProps> = props => (
    <svg width={14} height={14} {...props}>
        <g fill="#000" fillRule="evenodd">
            <path d="M7 4.81l6.371 5.684.629-.757L7 3.5 0 9.74l.63.76z" />
            <path d="M7 4.81l6.371 5.684.629-.757L7 3.5 0 9.74l.63.76z" />
        </g>
    </svg>
);

export default SVGDirection;
