import { RouterMenuGroup, RouterMenuGroupItem, RoutesConfig } from "./Routes";
import Page01 from "../pages/Page01/Page01";
import Page02 from "../pages/Page02/Page02";
import Page03 from "../pages/Page03/Page03";
import Page04 from "../pages/Page04/Page04";
import Page05 from "../pages/Page05/Page05";
import Page06 from "../pages/Page06/Page06";
import Page07 from "../pages/Page07/Page07";
import Page08 from "../pages/Page08/Page08";
import Page09 from "../pages/Page09/Page09";
import Page10 from "../pages/Page10/Page10";
import Page11 from "../pages/Page11/Page11";
import Page12 from "../pages/Page12/Page12";
import Page13 from "../pages/Page13/Page13";
import React from "react";

export interface RoutesPage {
    pageNumber: number;
    path: string;
    isDisabled?: boolean;
}

export default class RouteUtil {
    static pages: RoutesPage[];
    static componentPages: any[];

    static getPages(): RoutesPage[] {
        if (!this.pages) {
            this.pages = this.createPages();
        }
        return this.pages;
    }

    static getComponentPages() {
        if (!this.componentPages) {
            this.componentPages = this.createComponentPages();
        }
        return this.componentPages;
    }

    static createPages(): RoutesPage[] {
        const items: RoutesPage[] = [];
        RoutesConfig.forEach((item: RouterMenuGroup) => {
            items.push(item);
            if (!item.subItems) {
                return;
            }
            item.subItems.forEach((subItem: RouterMenuGroupItem) => {
                const exist = items.find(
                    item => item.pageNumber === subItem.pageNumber
                );
                if (!exist) {
                    items.push(subItem);
                }
            });
        });
        return items;
    }

    static createComponentPages(): any[] {
        const listOfPages = [...this.getPages()];
        listOfPages.unshift({ pageNumber: 0, path: "" });

        return [
            <Page01 path={listOfPages[1].path} />,
            <Page02 path={listOfPages[2].path} />,
            <Page03 path={listOfPages[3].path} />,
            <Page04 path={listOfPages[4].path} />,
            <Page05 path={listOfPages[5].path} />,
            <Page06 path={listOfPages[6].path} />,
            <Page07 path={listOfPages[7].path} />,
            <Page08 path={listOfPages[8].path} />,
            <Page09 path={listOfPages[9].path} />,
            <Page10 path={listOfPages[10].path} />,
            <Page11 path={listOfPages[11].path} />,
            <Page12 path={listOfPages[12].path} />,
            <Page13 path={listOfPages[13].path} />
        ];
    }
}
