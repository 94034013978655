import React, { Component } from "react";
import "./scss/config/Site.scss";
import Navigation from "./components/Navigation/Navigation";
import { Provider } from "react-redux";
import { StoreManager } from "./store";
import ProgressBar from "./components/ProgressBar/ProgressBar";
import Content from "./components/Content/Content";
import Header from "./components/Header/Header";
import Page00 from "./pages/Page00/Page00";
import { Grid, Row, Col } from "react-flexbox-grid";
import SessionControl from "./components/SessionControl/SessionControl";

class App extends Component {
    render() {
        return (
            <Provider store={StoreManager}>
                <div className="App">
                    <SessionControl
                        intervalMs={20000}
                        intervalForbiddenMs={6000}
                    />
                    <Header />
                    <Page00 />
                    <div className="site">
                        <Grid fluid>
                            <Row>
                                <Col xs={8}>
                                    <Content />
                                </Col>
                                <Col xs={1}>
                                    <ProgressBar />
                                </Col>
                                <Col xs={3}>
                                    <Navigation />
                                </Col>
                            </Row>
                        </Grid>
                    </div>
                </div>
            </Provider>
        );
    }
}

export default App;
