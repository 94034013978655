import * as React from "react";

const SVGGlobalSustainableAusschuetungsfokus: React.FC<SvgProps> = props => (
    <svg
        id="Layer_1"
        x={0}
        y={0}
        viewBox="0 0 14 14"
        xmlSpace="preserve"
        {...props}
    >
        <path
            className="st0"
            d="M7 1.2l.4-.5c.1-.1.1-.2 0-.3-.1-.1-.2-.1-.3 0l-.7.9c-.1.1-.1.2 0 .3l.9.8.1.1c.1 0 .1 0 .2-.1s.1-.2 0-.3l-.5-.5C9.1 1.5 10.8 3 11 5c0 .1.1.2.2.2s.2-.1.2-.2C11.2 2.7 9.3 1 7 1.2zM4.9 11c-1-.2-1.8-.7-2.4-1.5-.6-.8-.8-1.7-.7-2.6l.4.5c0 .1.1.1.2.1h.1c.1-.1.1-.2 0-.3l-.7-1c-.1-.1-.2-.2-.3-.1l-1 .7c0 .1-.1.2 0 .3.1.1.2.1.3 0l.5-.4c-.1 1.1.1 2.1.7 3 .7.9 1.6 1.5 2.7 1.7.1 0 .2-.1.2-.2.2-.1.1-.2 0-.2zM2.8 0C1.3 0 .1.7.1 1.6v2.2c0 .9 1.1 1.6 2.6 1.6s2.6-.7 2.6-1.6V1.6C5.4.7 4.2 0 2.8 0zm0 .5C4 .5 5 1 5 1.6S4 2.7 2.8 2.7.6 2.2.6 1.6 1.6.5 2.8.5zm0 3.3C1.6 3.8.6 3.3.6 2.7v-.2c.1.1.2.2.4.3 0 0 .1 0 .1.1.1 0 .2 0 .3.1h.2c.1 0 .3.1.4.1H3.6c.1 0 .3-.1.4-.1h.1c.1 0 .2-.1.3-.1 0 0 .1 0 .1-.1.1-.1.3-.2.4-.3v.2c0 .6-1 1.1-2.1 1.1zm0 1.1C1.6 4.9.6 4.4.6 3.8v-.2c.1.1.2.2.4.2 0 0 .1 0 .1.1.1.1.2.1.3.1.1 0 .1 0 .2.1.1 0 .3.1.4.1H3.6c.1 0 .3-.1.4-.1l.1-.1c.1 0 .2 0 .3-.1 0 0 .1 0 .1-.1.1-.1.3-.2.4-.3v.2c0 .7-1 1.2-2.1 1.2zM10.2 12.9c-.2.3-.4.4-.6.4-.3 0-.8-.5-1.1-1.3h1.2c0-.1-.1-.3-.1-.4H8.5c-.1-.4-.2-1-.2-1.6h1.3v-.4H8.3c0-.6.1-1.1.2-1.6h1.4c.1-.1.2-.2.3-.2.2-.1.5-.1.7 0 .1 0 .3.1.5.3h1.4c.2.4.4.8.4 1.3l.5.5v-.1c0-.8-.2-1.6-.7-2.2-.8-1.2-2-1.9-3.4-1.9S7 6.4 6.3 7.5c-.4.7-.7 1.4-.7 2.2 0 .8.2 1.6.7 2.2.7 1.1 1.9 1.8 3.3 1.8.4 0 .8-.1 1.2-.2-.2-.1-.4-.4-.6-.6zm2.2-5.4h-1.2c-.2-.5-.4-.9-.6-1.2.7.2 1.3.6 1.8 1.2zM9.6 6.2c.3 0 .8.5 1.1 1.3H8.6c.3-.8.7-1.3 1-1.3zm-.9.1c-.2.3-.4.7-.6 1.2H6.9c.4-.6 1.1-1 1.8-1.2zM6.6 8H8c-.1.4-.2 1-.2 1.5H6.1c0-.6.2-1.1.5-1.5zm-.5 2h1.7c0 .6.1 1.1.2 1.6H6.6c-.3-.5-.5-1.1-.5-1.6zm.8 2h1.2c.2.5.4.9.6 1.2-.7-.2-1.4-.7-1.8-1.2z"
        />
        <path
            className="st0"
            d="M13.8 11.9c.1-.5 0-1-.4-1.5-.8-1.2-2.8-2.1-2.9-2.2h-.2c-.1 0-.1.1-.1.2s-.4 2.3.1 3.6c.2.7.7 1.1 1.2 1.3.2.1.3.1.5.1s.4-.1.6-.1l.3.6c0 .1.1.1.2.1h.1c.1-.1.1-.2.1-.3L13 13c.5-.3.7-.7.8-1.1zm-1 .8l-.1-.2.1-1.2c0-.1-.1-.2-.2-.2s-.2.1-.2.2v.6l-.9-1.7c-.1-.1-.2-.1-.3-.1-.2-.1-.2.1-.2.2l.6 1.2-.6-.3c-.1 0-.2 0-.3.1 0 .1 0 .2.1.3l1.2.4.4.8c-.2.1-.5.1-.7 0-.3-.1-.7-.4-.9-1-.4-1-.2-2.5-.1-3.1.6.3 1.9 1 2.5 1.9.3.4.4.8.3 1.2-.2.4-.5.7-.7.9z"
        />
    </svg>
);

export default SVGGlobalSustainableAusschuetungsfokus;
