import {
    CURRENT_PAGE,
    CURRENT_BROKER,
    Form,
    FORM_IMPORT,
    FORM_RESET,
    FORM_UPDATE,
    LIST_OF_PAGES,
    HEADER_LOGO_STATUS,
    FormActionTypes,
    FormImportAction,
    FormUpdateAction,
    listOfPagesAction,
    PageAction,
    BrokerAction,
    HeaderLogoStatusAction
} from "./types";
import RouteUtil from "../../route/RouteUtil";
import RouteController from "../../route/RouteController";

export function formReducer(
    state: Form[] = [],
    action: FormActionTypes
): Form[] {
    switch (action.type) {
        case FORM_UPDATE:
            const tempAction = action as FormUpdateAction;
            return [
                ...state.filter(
                    item => item.pageNumber !== tempAction.payload.pageNumber
                ),
                tempAction.payload
            ];

        case FORM_IMPORT:
            const replaceAction = action as FormImportAction;
            return [...replaceAction.content];

        case FORM_RESET:
            return [];

        default:
            return state;
    }
}

export const currentPageReducer = (
    currentPage: number | undefined = 1,
    action: PageAction
) => {
    if (action.type === CURRENT_PAGE) {
        RouteController.update(action.payload);
        return action.payload;
    }
    return currentPage;
};

export const currentBrokerReducer = (
    currentBroker: string | undefined = "",
    action: BrokerAction
) => {
    if (action.type === CURRENT_BROKER) {
        return action.payload;
    }
    return currentBroker;
};

export const listOfPagesReducer = (
    pages = RouteUtil.getPages(),
    action: listOfPagesAction
) => {
    if (action.type === LIST_OF_PAGES) {
        return action.payload;
    }
    return [...pages];
};

export const isHeaderLogoRightSideVisible = (
    status: boolean = true,
    action: HeaderLogoStatusAction
) => {
    if (action.type === HEADER_LOGO_STATUS) {
        return action.payload;
    }
    return status;
};
