import React from "react";
import "./InfoBox.scss";
import SVGInfoRounded from "../../components/svg/Inforounded";

interface InfoBoxProps {
    isVisible?: boolean;
    hasInfoIcon?: boolean;
    hasBulletList?: boolean;
}

const InfoBox: React.FC<InfoBoxProps> = ({
    children,
    isVisible,
    hasInfoIcon,
    hasBulletList
}) => {
    return (
        <div
            className={
                "info-box" +
                (isVisible === false ? " info-box--hidden" : "") +
                (hasInfoIcon ? " info-box__has-info-icon" : "")
            }
        >
            {hasInfoIcon && (
                <SVGInfoRounded
                    viewBox={"0 0 50 50"}
                    className={"info-box__info-icon"}
                />
            )}
            {!hasBulletList ? (
                <div className={"info-box__content"}>{children}</div>
            ) : (
                <ul className={"info-box__list"}>{children}</ul>
            )}
        </div>
    );
};

export default InfoBox;
