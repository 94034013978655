import { StoreManager } from "../../store";
import { Form } from "../../store/data/types";
import path from "./GetPageNumberByPath";
import { updateForm } from "../../store/data/actions";
import {
    compensationValue,
    sliderPValue
} from "../../pages/Page08/sliderRelatedValues";

const resetSliderValue: Function = (
    percentageRateMark: number,
    implementationOption?: string
): void => {
    // Reset the automatic initial slider value based on the user choice on the "risikobereitschaft" page (2.3)
    const find: Form | undefined = StoreManager.getState().forms.find(
        item => item.pageNumber === path("maximale-aktienquote")
    );

    if (implementationOption) {
        if (implementationOption === "einzeltitelUndAusschuettungsfokus") {
            switch (percentageRateMark) {
                case 2:
                    percentageRateMark = 3;
                    break;
                case 5:
                    percentageRateMark = 4;
                    break;
                case 8:
                    percentageRateMark = 7;
                    break;
            }
        }
    }

    if (find) {
        let newData = { ...find.data };

        newData.value = percentageRateMark * 10;
        newData.percentageRate = `${percentageRateMark * 10}%`;
        newData.sliderPValue = sliderPValue[percentageRateMark * 10];
        newData.compensationValue = compensationValue[percentageRateMark * 10];
        newData.implementationOption =
            implementationOption || newData.implementationOption;

        StoreManager.dispatch(
            updateForm({
                pageNumber: path("maximale-aktienquote"),
                data: newData
            })
        );
    }
};

export default resetSliderValue;
