import React, { ReactElement } from "react";
import Heading from "../../components/Heading/Heading";
import Button from "../../components/Button/Button";
import RangeSlider from "../../components/RangeSlider/RangeSlider";
import Subheadline from "../../components/Subheadline/Subheadline";
import { connect } from "react-redux";
import { updateCurrentPage, updateForm } from "../../store/data/actions";
import { StoreManager } from "../../store";
import Modal from "../../components/Modal/Modal";
import BackToSummary from "../../components/BackToSummary/BackToSummary";
import { Form } from "../../store/data/types";
import path from "../../components/utils/GetPageNumberByPath";
import { Grid, Row, Col } from "react-flexbox-grid";

interface ComponentProps {
    currentPage: number;
    updateForm: Function;
    updateCurrentPage: Function;
    forms: Form[];
    path: string;
}

interface ComponentState {
    horizonValue: number;
    horizonText: any;
    horizonTextComplement: string;
    isModalVisible: boolean;
    isDisabled: boolean;
}

class Page05 extends React.Component<ComponentProps, ComponentState> {
    sliderLabels: any = {
        0: "bis 3 Jahre (kurzfristig)",
        1: "3 bis 5 Jahre (mittelfristig)",
        2: "mehr als 5 Jahre (langfristig)"
    };

    state: ComponentState = {
        horizonValue: 2,
        horizonText: this.sliderLabels[2].substring(
            0,
            this.sliderLabels[2].lastIndexOf(" ")
        ),
        horizonTextComplement: this.sliderLabels[2].split(" ").slice(-1)[0],
        isModalVisible: false,
        isDisabled: false
    };

    buttonModal: ReactElement = (
        <div className={"modal__button-wrapper"}>
            <Button
                styling={"primary"}
                additionalClass={"button__no-icon"}
                onClick={() => {
                    this.setState({ isModalVisible: false }, () =>
                        this.sendForm()
                    );
                }}
            >
                Ok, verstanden
            </Button>
        </div>
    );

    sendForm = () => {
        this.props.updateForm({
            pageNumber: path(this.props.path),
            data: this.state
        });
    };

    componentDidMount() {
        const find = StoreManager.getState().forms.find(
            item => item.pageNumber === path(this.props.path)
        );
        if (find) {
            this.setState(find.data as ComponentState);
        } else {
            this.sendForm();
        }
    }
    componentDidUpdate(
        prevProps: Readonly<ComponentProps>,
        prevState: Readonly<ComponentState>
    ) {
        if (prevState.horizonValue !== this.state.horizonValue) {
            this.sendForm();
        }
    }

    handleFormSubmission = (e: React.FormEvent): void => {
        e.preventDefault();
        this.props.updateCurrentPage(this.props.currentPage + 1);
    };

    handleInputChange = (horizonValue: number): void => {
        this.setState(
            {
                horizonValue: horizonValue,
                horizonText: this.sliderLabels[horizonValue].substring(
                    0,
                    this.sliderLabels[horizonValue].lastIndexOf(" ")
                ),
                horizonTextComplement: this.sliderLabels[horizonValue]
                    .split(" ")
                    .slice(-1)[0],
                isModalVisible: horizonValue === 0
            },
            () => this.sendForm()
        );
    };

    render() {
        return (
            <section className="parallax-layer">
                <Heading rank={2} text={"2.0 Prämissen"} />
                <Heading rank={3} text={"2.4 Anlagehorizont"} />

                <Subheadline>
                    Über welchen Zeitraum soll das Vermögen angelegt werden?
                </Subheadline>

                <div className="box-model">
                    <Grid fluid>
                        <Row>
                            <Col xs={10}>
                                <RangeSlider
                                    min={0}
                                    max={2}
                                    defaultValue={this.state.horizonValue}
                                    value={this.state.horizonValue}
                                    marks={this.sliderLabels}
                                    dots={true}
                                    onChange={this.handleInputChange}
                                />
                            </Col>
                        </Row>
                    </Grid>
                </div>

                <Grid fluid className={"bottom-sticky"}>
                    <Row>
                        <Col xs={12} className={"align-right"}>
                            <BackToSummary />
                            <Button
                                styling={"primary"}
                                onClick={this.handleFormSubmission}
                            >
                                Weiter
                            </Button>
                        </Col>
                    </Row>
                </Grid>

                <Modal
                    isVisible={this.state.isModalVisible}
                    hasCloseX={false}
                    buttonWrapper={this.buttonModal}
                    headlineText={"Bitte beachten Sie:"}
                    onExit={() => {
                        this.setState({ isModalVisible: false });
                    }}
                >
                    Die Vermögenanlage in einer Vermögensverwaltung ist auf
                    einen mittel- bis langfristigen Anlagehorizont ausgerichtet.
                </Modal>
            </section>
        );
    }
}

const mapStaTeToProps = (props: ComponentProps) => {
    return { currentPage: props.currentPage, forms: props.forms };
};

export default connect(mapStaTeToProps, {
    updateCurrentPage,
    updateForm
})(Page05);
