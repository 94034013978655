import * as React from "react";

const SVGEinzeltitel: React.FC<SvgProps> = props => (
    <svg
        id="Layer_1"
        x={0}
        y={0}
        viewBox="0 0 14 14"
        xmlSpace="preserve"
        {...props}
    >
        <path
            className="st0"
            d="M5 7.7H1C.6 7.7.3 7.4.3 7V1C.3.6.6.3 1 .3h4c.4 0 .7.3.7.7v6c0 .4-.3.7-.7.7zm-4-7C.8.7.7.8.7 1v6c0 .2.1.3.3.3h4c.2 0 .3-.1.3-.3V1c0-.2-.1-.3-.3-.3H1z"
        />
        <path
            className="st0"
            d="M4.7 2.7H1.2c-.1 0-.2-.1-.2-.2s.1-.2.2-.2h3.5c.2 0 .3.1.3.2s-.1.2-.3.2zM4.7 1.7H1.2c-.1 0-.2-.1-.2-.2s.1-.2.2-.2h3.5c.2 0 .3.1.3.2s-.1.2-.3.2zM4.7 3.7H1.2c-.1 0-.2-.1-.2-.2s.1-.2.2-.2h3.5c.2 0 .3.1.3.2s-.1.2-.3.2zM4.7 4.7H1.2c-.1 0-.2-.1-.2-.2s.1-.2.2-.2h3.5c.2 0 .3.1.3.2s-.1.2-.3.2zM4.7 5.7H1.2c-.1 0-.2-.1-.2-.2s.1-.2.2-.2h3.5c.2 0 .3.1.3.2s-.1.2-.3.2zM3.2 6.7h-2c-.1 0-.2-.1-.2-.2s.1-.2.2-.2h2c.1 0 .2.1.2.2s0 .2-.2.2zM7.9 10.5s-.5 1.2 0 1.9l.4-.2c-.4-.6 0-1.5 0-1.5l-.4-.2z"
        />
        <path
            className="st0"
            d="M13.7 12.4c0-.9-.2-1.7-.5-2.3-.2-.5-.5-.9-1-1.4-.4-.3-.8-.6-1.1-.7l.5-.8c.1-.2.2-.4.1-.6-.1-.2-.3-.3-.5-.3H8.9c-.1 0-.4 0-.5.3-.1.2 0 .4.1.6L9 8c-.3 0-.8.2-1.2.7-.4.4-.7.9-1 1.4-.3.7-.5 1.5-.5 2.3 0 .1 0 .4.2.8.3.4.8.5 1.5.5h4c.7 0 1.2-.2 1.5-.5.2-.3.2-.7.2-.8zM8.9 6.8c-.1 0-.1-.1-.1-.1h2.4c.1 0 .1 0 .1.1V7l-.6.9c-.4-.1-.8-.1-1.1 0l-.7-1.1zm4.2 6.1c-.2.2-.6.4-1.2.4H8c-.6 0-1-.1-1.2-.4-.2-.2-.1-.4-.1-.5 0-.8.2-1.6.5-2.2.2-.4.5-.9.9-1.2.4-.4 1-.6 1.2-.7.2 0 .9-.1 1.4 0 .2.1.8.4 1.2.8.4.4.7.8.9 1.2.3.6.4 1.4.5 2.2 0 0 0 .2-.2.4zM5.7 11.2c-1.6 0-3-1.3-3-3 .1-.1.2-.2.3-.2s.2.1.2.2c0 1.4 1.1 2.5 2.5 2.5.2.1.3.2.3.3s-.1.2-.3.2z"
        />
    </svg>
);

export default SVGEinzeltitel;
