import { StoreManager } from "../../store";

import path from "./GetPageNumberByPath";
import getStrategyName from "./GetStrategyName";
import { Form } from "../../store/data/types";
import { updateForm } from "../../store/data/actions";

const generateRecommendationText: Function = (
    dontUpdateRedux?: boolean
): string => {
    let productName: string = "";
    let targetListLabels: any = [];
    let percentageRate: string = "";
    let sliderPValue: string = "";
    let sustainability: string = "";
    let implementationOption: string = "";

    const find: Form | undefined = StoreManager.getState().forms.find(
        item => item.pageNumber === path("empfehlung")
    );

    if (!find) {
        return "";
    }

    StoreManager.getState().forms.forEach((item: any) => {
        if (item.pageNumber === 0) {
            productName = item.data.productName.replace(/\n/g, "");
        } else if (item.pageNumber === path("anlageziel")) {
            targetListLabels = [...item.data.targetListLabels];
            if (item.data.otherInput) {
                targetListLabels.push(item.data.otherInput);
            }
        } else if (item.pageNumber === path("maximale-aktienquote")) {
            percentageRate = item.data.percentageRate;
            sliderPValue = item.data.sliderPValue;
        } else if (item.pageNumber === path("nachhaltigkeit")) {
            sustainability = item.data.sustainability;
        } else if (item.pageNumber === path("umsetzungsoptionen")) {
            implementationOption = item.data.implementationOption;
        }
    });

    const neutraleAktienquoteValue =
        implementationOption === "einzeltitelUndAusschuettungsfokus"
            ? "90%"
            : "75%";

    const textPart1: string = `Aufgrund der von Ihnen genannten Prämissen empfehlen wir Ihnen ${productName} mit der Anlagestrategie ${getStrategyName()}.\n`;

    let textPart2: string =
        targetListLabels.length === 1
            ? "\nInsbesondere aufgrund des von Ihnen formulierten Anlageziels " +
              targetListLabels[0]
            : "\nInsbesondere aufgrund der von Ihnen formulierten Anlageziele " +
              targetListLabels[0];

    if (targetListLabels.length > 1) {
        targetListLabels.forEach((item: string, key: number) => {
            if (key < 1) return;
            textPart2 +=
                targetListLabels.length !== key + 1
                    ? `, ${item}`
                    : ` und ${item}`;
        });
    }
    textPart2 += " empfehlen wir Ihnen diese Anlagestrategie.";

    const textPart3: string = `\n\nDie Vermögensverwaltung mit einer maximalen Aktienquote von ${percentageRate} ermöglicht Ihnen mittel- bis langfristig die Chance auf Erreichung Ihres Anlageziels. Die neutrale Aktienquote beträgt ${neutraleAktienquoteValue} der genannten maximalen Aktienquote. Das Anlageziel der von uns empfohlenen Anlagestrategie ist `;

    const textPart4 = (() => {
        switch (sliderPValue) {
            case "P1":
                return "die Erzielung stetiger Erträge und der Erhalt des investierten Kapitals.";

            case "P2":
                return "die Erzielung stetiger Erträge bei geringfügig erhöhtem Risiko.";

            case "P3":
                return "die Erzielung einer attraktiven Kapitalrendite bei überschaubarem Risiko.";

            case "P4":
                return "die Erzielung einer überdurchschnittlichen Kapitalrendite bei entsprechend erhöhtem Risiko.";

            case "P5":
                return "die Erzielung einer deutlich überdurchschnittlichen Kapitalrendite bei dadurch erheblich erhöhtem Risiko.";
        }
    })();

    const textPart5 =
        sustainability === "Ja"
            ? "\n\nZur Umsetzung Ihrer Anlagestrategie wird nur in Wertpapiere und Investmentanteile investiert, die nach ESG-Kriterien ausgewählt werden."
            : "";

    const finalText = textPart1 + textPart2 + textPart3 + textPart4 + textPart5;

    if (!dontUpdateRedux) {
        const newData = { ...find.data, derivationField: finalText };
        StoreManager.dispatch(
            updateForm({
                pageNumber: path("empfehlung"),
                data: newData
            })
        );
    }

    return finalText;
};

export default generateRecommendationText;
