import * as React from "react";

const SVGEurope: React.FC<SvgProps> = props => (
    <svg width={14} height={14} {...props}>
        <path
            d="M6.862 11.26l.208.645h.675l-.546.397.209.644-.546-.398-.546.398.209-.644-.546-.397h.675l.208-.644zm-2.397-.697l.209.644h.674l-.546.398.209.644-.546-.398-.545.398.208-.644-.546-.398h.675l.208-.644zm4.727 0l.209.644h.674l-.546.398.209.644-.546-.398-.545.398.208-.644-.546-.398h.675l.208-.644zM2.667 8.786l.209.644h.674l-.545.398.208.644-.546-.398-.545.398.208-.644-.546-.398h.675l.208-.644zm8.323 0l.209.644h.674l-.546.398.209.644-.546-.398-.545.398.208-.644-.546-.398h.675l.208-.644zM1.883 6.214l.208.643h.675l-.546.398.209.644-.546-.398-.546.398.209-.644L1 6.857h.674l.209-.643zm10 0l.208.643h.675l-.546.398.209.644-.546-.398-.546.398.209-.644L11 6.857h.674l.209-.643zM2.545 3.64l.208.644h.674l-.545.397.208.644-.545-.398-.546.398.208-.644-.545-.397h.674l.209-.644zm8.568 0l.208.644h.675l-.546.397.209.644-.546-.398-.546.398.209-.644-.546-.397h.675l.208-.644zM4.31 1.785l.209.644h.674l-.545.398.208.643-.546-.397-.545.397.208-.643-.546-.398h.675l.208-.644zm5.037 0l.209.644h.674l-.546.398.209.643-.546-.397-.545.397.208-.643-.546-.398h.675l.208-.644zM6.862 1l.208.644h.675l-.546.398.209.643-.546-.398-.546.398.209-.643-.546-.398h.675L6.862 1z"
            fill="red"
            fillRule="evenodd"
        />
    </svg>
);

export default SVGEurope;
