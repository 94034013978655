export interface SustainableType {
    type: string;
    label: string;
    boxList: Array<string>;
    svgIcon: string;
}

export const notSustainableEinzeltitel: Array<SustainableType> = [
    {
        type: "global",
        label: "Global",
        boxList: [
            "Investition in Finanzinstrumente in- und ausländischer Emittenten",
            "Investition in Fremdwährung möglich"
        ],
        svgIcon: "SVGGlobal"
    },
    {
        type: "europe",
        label: "Überwiegend Europa",
        boxList: [
            "Investition überwiegend in Finanzinstrumente von Emittenten mit juristischem Sitz in der EU, einem anderen Vertragsstaat des\nEWR-Abkommens, der Schweiz oder dem Vereinigten Königreich",
            "Investition in Fremdwährung möglich"
        ],
        svgIcon: "SVGEurope"
    }
];

export const sustainableEinzeltitel: Array<SustainableType> = [
    {
        type: "global-sustainable",
        label: "Global nachhaltig",
        boxList: [
            "Investition in Finanzinstrumente in- und ausländischer Emittenten",
            "Investition in Fremdwährung möglich",
            "Auswahl der Finanzinstrumente nach Grundsätzen der Nachhaltigkeit (ESG-Kriterien)"
        ],
        svgIcon: "SVGGlobalSustainable"
    },
    {
        type: "europe-sustainable",
        label: "Überwiegend Europa nachhaltig",
        boxList: [
            "Investition überwiegend in Finanzinstrumente von Emittenten mit juristischem Sitz in der EU, einem anderen Vertragsstaat des\nEWR-Abkommens, der Schweiz oder dem Vereinigten Königreich",
            "Investition in Fremdwährung möglich",
            "Auswahl der Finanzinstrumente nach Grundsätzen der Nachhaltigkeit (ESG-Kriterien)"
        ],
        svgIcon: "SVGEuropeSustainable"
    }
];

export const notSustainableFondsbausteine: Array<SustainableType> = [
    {
        type: "global-fonds",
        label: "Global Fonds",
        boxList: [
            "Investition in Finanzinstrumente in- und ausländischer Emittenten",
            "Investition in Fremdwährung möglich"
        ],
        svgIcon: "SVGGlobal"
    }
];

export const sustainableFondsbausteine: Array<SustainableType> = [
    {
        type: "global-sustainable-fonds",
        label: "Global Fonds nachhaltig",
        boxList: [
            "Investition in Finanzinstrumente in- und ausländischer Emittenten",
            "Investition in Fremdwährung möglich",
            "Auswahl der Finanzinstrumente nach Grundsätzen der Nachhaltigkeit (ESG-Kriterien)"
        ],
        svgIcon: "SVGGlobalSustainable"
    }
];

export const sustainableFondsbausteineDistributionFocus: Array<SustainableType> = [
    {
        type: "global-sustainable-distribution-focus",
        label: "Global nachhaltig Fonds Ausschüttungsfokus",
        boxList: [
            "Investition in Finanzinstrumente in- und ausländischer Emittenten",
            "Investition zu 50% in Fremdwährung möglich",
            "Auswahl der Finanzinstrumente nach Grundsätzen der Nachhaltigkeit (ESG-Kriterien)",
            "Ausschüttungfokussiertes Management"
        ],
        svgIcon: "SVGGlobalSustainableAusschuetungsfokus"
    }
];
