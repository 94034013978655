import { StoreManager } from "../../store";

import path from "./GetPageNumberByPath";
import { Form } from "../../store/data/types";

const checkMoney: Function = (minAmount: number = 1000000): boolean => {
    const find: Form | undefined = StoreManager.getState().forms.find(
        item => item.pageNumber === path("anlagevolumen")
    );
    if (find) {
        const moneyVolume = Number(find.data.moneyVolume!.split(".").join(""));
        return moneyVolume >= minAmount;
    }
    return false;
};

export default checkMoney;
