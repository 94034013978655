import * as React from "react";

const SVGSack: React.FC<SvgProps> = props => (
    <svg width={14} height={14} {...props}>
        <path
            d="M12.304 11.892c-.316.39-.987.597-1.942.597H3.638c-.947 0-1.616-.203-1.935-.589a.857.857 0 01-.201-.638l.003-.014v-.021c.065-1.39.33-2.628.786-3.68A6.794 6.794 0 013.733 5.4c.799-.789 1.649-1.17 2.002-1.267l.66-.073h1.472l.275.069c.01.002 1.012.26 2.051 1.253a6.76 6.76 0 011.492 2.146c.472 1.056.745 2.3.81 3.698v.018l.003.017c0 .004.052.325-.194.63zM5.043 1.687l-.01-.012a.097.097 0 01-.014-.107.093.093 0 01.088-.057h3.989c.054 0 .078.036.088.057a.097.097 0 01-.014.107l-.016.019-.998 1.856H6.223l-1.18-1.863zm7.948 9.503c-.069-1.459-.356-2.762-.854-3.874a7.267 7.267 0 00-1.604-2.307 6.055 6.055 0 00-1.918-1.255l.953-1.772a.617.617 0 00.067-.63.589.589 0 00-.54-.352H5.108a.589.589 0 00-.54.353.617.617 0 00.074.637l1.036 1.636-.03.004-.017.004c-.045.011-1.113.294-2.22 1.377a7.285 7.285 0 00-1.565 2.307c-.486 1.112-.767 2.414-.836 3.872a1.39 1.39 0 00.304 1.028c.419.519 1.201.782 2.325.782h6.724c1.124 0 1.906-.263 2.325-.782a1.39 1.39 0 00.304-1.028zM3.688 7.995c-.033.077-.79 1.9 0 3.012l.333-.25c-.65-.915.038-2.577.045-2.593l-.19-.084-.188-.085z"
            fill="red"
            fillRule="evenodd"
        />
    </svg>
);

export default SVGSack;
