import React, { ChangeEvent } from "react";
import "./InputText.scss";
import SVGAlert from "../svg/Alert";

interface InputTextProps {
    id: string;
    name: string;
    label?: string;
    value?: string;
    onChange?: React.FormEventHandler<HTMLInputElement>;
    onKeyUp?: React.FormEventHandler<HTMLInputElement>;
    onClick?: React.FormEventHandler<HTMLInputElement>;
    placeHolder?: string;
    isError?: boolean;
    isAbsoluteError?: boolean;
    errorMessage?: string;
    isDate?: boolean;
    isNumber?: boolean;
    isInline?: boolean;
    isInlineNoMargin?: boolean;
    className?: string;
    additionalClass?: string;
    isZip?: boolean;
    isPercentage?: boolean;
    maxLength?: number;
    ref?: any;
    showPlaceholderAsLabel?: boolean;
}

const InputText: React.FC<InputTextProps> = React.forwardRef(
    (
        {
            id,
            name,
            label,
            value,
            onChange,
            onKeyUp,
            placeHolder,
            isError,
            isAbsoluteError,
            errorMessage,
            isDate,
            isNumber,
            isInline,
            isInlineNoMargin,
            className,
            additionalClass,
            isZip,
            maxLength,
            isPercentage,
            onClick,
            showPlaceholderAsLabel
        },
        ref: any
    ) => {
        const [focused, setFocused] = React.useState(false);
        const onFocus = () => setFocused(true);
        const onBlur = () => setFocused(false);

        const handleDateInput = (e: any) => {
            const lastChar = e.target.value.substr(e.target.value.length - 1);

            if (Number.isNaN(Number(lastChar)) && lastChar !== ".") {
                e.target.value = e.target.value.slice(0, -1) + "";
            }

            let len = e.target.value.length;
            let val = e.target.value;
            if (len === 3) {
                if (lastChar !== ".") {
                    e.target.value =
                        val.substring(0, 2) + "." + lastChar + val.substring(3);
                }
            }

            if (len === 6) {
                if (lastChar !== ".") {
                    e.target.value =
                        val.substring(0, 5) + "." + lastChar + val.substring(6);
                }
            }
        };

        const handleCurrencyInput = (e: ChangeEvent<HTMLInputElement>) => {
            let numbersOnly: string;
            if (isPercentage) {
                numbersOnly = e.target.value.replace(/[^0-9.,]/g, ""); //allow 0-9 only;
                e.target.value = Number(numbersOnly).toLocaleString("de-DE", {
                    style: "percent",
                    minimumIntegerDigits: 4,
                    minimumFractionDigits: 2
                });
            } else {
                numbersOnly = e.target.value.replace(/[^0-9]/g, ""); //allow 0-9 only
            }

            const toCurrency = Number(numbersOnly);

            if (!isZip && !isPercentage) {
                const oldValue: string = e.target.value || "";
                let cursorPos = e.target.selectionStart || 0;

                e.target.value = toCurrency.toLocaleString("de-DE", {
                    style: "currency",
                    currency: "EUR",
                    minimumFractionDigits: 0
                });

                const newValue = e.target.value || "";

                // When modifying the value to currency, we change the cursor position.
                // Here we need to fix this position.
                // Since we add "dots" to the currency number, we need
                // first check whether a dot was added or not. If so, we
                // set the cursor position with a +1 value
                const oldDots = (oldValue.match(/\./g) || []).length;
                const newDots = (newValue.match(/\./g) || []).length;

                let newCursorPos = cursorPos;

                if (newDots > oldDots) {
                    newCursorPos = cursorPos + 1;
                } else if (newDots > oldDots) {
                    newCursorPos = cursorPos - 1;
                }

                if (
                    cursorPos === newValue.length - 2 ||
                    cursorPos === newValue.length - 1
                ) {
                    newCursorPos = newValue.length - 2;
                }

                e.target.setSelectionRange(newCursorPos, newCursorPos);
            } else {
                e.target.value = numbersOnly;
            }
            if (onChange) {
                onChange(e);
            }
        };

        return (
            <div
                className={
                    "form-item-text " +
                    (isInline ? " form-item-text--inline" : "") +
                    (isAbsoluteError ? " form-item-text--absolute-error" : "") +
                    (isInlineNoMargin
                        ? " form-item-text--inline no-margins"
                        : "") +
                    (className ? " " + className : "")
                }
            >
                {label && (
                    <label className="form-item-text__label" htmlFor={id}>
                        {label}
                    </label>
                )}

                {showPlaceholderAsLabel && !label && (
                    <label
                        className={
                            "form-item-text__label form-item-text__label--placeholder" +
                            (value!.length > 0 || focused ? " active" : "")
                        }
                        htmlFor={id}
                    >
                        {placeHolder}
                    </label>
                )}

                {isDate ? (
                    <input
                        id={id}
                        name={name}
                        value={value}
                        placeholder={"TT.MM.JJJJ"}
                        onChange={onChange}
                        onInput={handleDateInput}
                        onKeyUp={onKeyUp}
                        maxLength={10}
                        onClick={onClick}
                        ref={ref}
                        className={
                            "form-item-text__input " +
                            (additionalClass ? additionalClass : "") +
                            (isError ? " form-item-text__input--error" : "") +
                            (value !== ""
                                ? " form-item-text__input--active"
                                : "")
                        }
                        autoComplete={"off"}
                    />
                ) : (
                    <input
                        id={id}
                        name={name}
                        value={value}
                        onChange={isNumber ? handleCurrencyInput : onChange}
                        maxLength={maxLength}
                        onBlur={onBlur}
                        onFocus={onFocus}
                        placeholder={placeHolder}
                        onClick={onClick}
                        ref={ref}
                        className={
                            "form-item-text__input" +
                            (isError ? " form-item-text__input--error" : "") +
                            (additionalClass ? " " + additionalClass : "") +
                            (value !== ""
                                ? " form-item-text__input--active"
                                : "")
                        }
                        type="text"
                        autoComplete="off"
                    />
                )}
                {isError && errorMessage && (
                    <div className={"form-item-text--error-message"}>
                        <SVGAlert
                            viewBox={"0 0 18 14"}
                            className={"error-icon"}
                        />
                        <span>{errorMessage}</span>
                    </div>
                )}
            </div>
        );
    }
);

export default InputText;
