import { StoreManager } from "../../store";
import { Form } from "../../store/data/types";
import path from "./GetPageNumberByPath";
import { updateForm } from "../../store/data/actions";

const resetAusrichtungOptions: Function = (): void => {
    // This function will reset the choices on page 3.4 (Ausrichtung)
    const find: Form | undefined = StoreManager.getState().forms.find(
        item => item.pageNumber === path("ausrichtung")
    );

    if (find) {
        let newData = { ...find.data };
        newData.isDisabled = true;
        newData.strategy = "";
        newData.strategyLabel = "";
        newData.showSub = false;
        newData.resetSubForm = true;

        StoreManager.dispatch(
            updateForm({
                pageNumber: path("ausrichtung"),
                data: newData
            })
        );
    }
};

export default resetAusrichtungOptions;
