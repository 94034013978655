import React, { BaseSyntheticEvent } from "react";
import "./TextareaContentEditable.scss";
import SVGExit from "../svg/Exit";
import { Isin } from "../../pages/Page09/SubPage09";
import SVGAlert from "../svg/Alert";

interface ComponentProps {
    height?: string;
    placeHolder?: string;
    value: string;
    handleText: any;
    isin: Isin[];
    onRemoveTag: Function;
    isError?: boolean;
    errorMessage?: string;
}

const TextareaContentEditable: React.FC<ComponentProps> = ({
    children,
    height,
    placeHolder,
    value,
    handleText,
    isin,
    onRemoveTag,
    isError,
    errorMessage
}) => {
    const handleInput = (e: BaseSyntheticEvent) => {
        if (e.target.value !== "") {
            e.target
                .closest(".textarea-content-wrapper")
                .firstElementChild.classList.add("textarea-mask--active");
        } else {
            e.target
                .closest(".textarea-content-wrapper")
                .firstElementChild.classList.remove("textarea-mask--active");
        }
    };

    const getLineHeightClass = () => {
        return value.length > 12
            ? "  textarea-content-editable__line-height"
            : "";
    };

    return (
        <div className={"textarea-content-wrapper"}>
            <div
                className={"textarea-mask"}
                data-placeholder={placeHolder}
                style={{ height: height }}
            >
                {isin.length > 0 &&
                    isin.map((isin: Isin, index: number) => {
                        return (
                            <span
                                className={
                                    "textarea-mask__tag " + isin.className
                                }
                                key={index}
                            >
                                {isin.name}
                                {isin.name.length > 11 && (
                                    <span
                                        onClick={() => {
                                            onRemoveTag(index);
                                        }}
                                    >
                                        <SVGExit
                                            className={"textarea-mask__tag-x"}
                                        />
                                    </span>
                                )}
                            </span>
                        );
                    })}
                {children}
            </div>
            <textarea
                maxLength={129}
                onBlur={handleInput}
                className={"textarea-content-editable" + getLineHeightClass()}
                onChange={handleText}
                onInput={handleInput}
                data-placeholder={placeHolder}
                value={value}
                style={{ height: height }}
                autoCorrect={"off"}
                spellCheck={false}
            />
            {isError && errorMessage && (
                <div className={"form-item-text--error-message"}>
                    <SVGAlert viewBox={"0 0 18 14"} className={"error-icon"} />
                    <span>{errorMessage}</span>
                </div>
            )}
        </div>
    );
};

export default TextareaContentEditable;
