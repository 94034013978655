import React from "react";
import "./Navigation.scss";
import NavigationOuterGroup from "../NavigationOuterGroup/NavigationOuterGroup";
import NavigationPrintSave from "../NavigationPrintSave/NavigationPrintSave";
import { connect } from "react-redux";
import path from "../utils/GetPageNumberByPath";

interface ComponentProps {
    currentPage: number;
}

const Navigation: React.FC<ComponentProps> = ({ currentPage }) => {
    return (
        <React.Fragment>
            <nav
                className={
                    "main-navigation " +
                    (currentPage !== path("ergebnis") ? "active" : "")
                }
            >
                <NavigationOuterGroup />
            </nav>
            <NavigationPrintSave
                className={currentPage === path("ergebnis") ? "active" : ""}
            />
        </React.Fragment>
    );
};

const mapStaTeToProps = (state: ComponentProps) => {
    return { currentPage: state.currentPage };
};

export default connect(mapStaTeToProps, {})(Navigation);
