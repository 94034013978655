import React, { createRef } from "react";
import "../../scss/config/RangeSlider.scss";
import "rc-slider/assets/index.css";
import { StoreManager } from "../../store";
import { importForm } from "../../store/data/actions";

interface ComponentProps {
    openFileBrowser: boolean;
    onSuccess?: Function;
    onError?: Function;
}

export default class Import extends React.Component<ComponentProps> {
    inputClick = createRef<HTMLInputElement>();

    componentDidUpdate(): void {
        if (this.props.openFileBrowser) {
            this.inputClick.current!.click();
        }
    }

    onFilesChange = (files: any) => {
        if (files.target.files.length === 0) {
            return;
        }
        const file = files.target.files[0];
        const reader = new FileReader();
        reader.onload = () => {
            try {
                const content: any = reader.result;
                if (typeof content === "string") {
                    StoreManager.dispatch(importForm(JSON.parse(content)));
                    if (this.props.onSuccess) {
                        this.props.onSuccess();
                    }
                }
            } catch (e) {
                console.error(e);
                if (this.props.onError) {
                    this.props.onError();
                }
            }
        };
        reader.readAsText(file);
    };

    render() {
        return (
            <React.Fragment>
                <input
                    ref={this.inputClick}
                    type="file"
                    id="fileElem"
                    accept="application/json"
                    style={{ display: "none" }}
                    onChange={this.onFilesChange}
                />
            </React.Fragment>
        );
    }
}
