import React, { FormEventHandler } from "react";
import "./InputRadioCheckbox.scss";

interface ComponentProps {
    inputType: "checkbox" | "radio";
    id: string;
    name: string;
    value?: string;
    label?: string;
    dataLabel?: string;
    dataNumber?: number;
    dataKey?: number;
    subLabel?: string;
    onChange?: FormEventHandler;
    onClick?: FormEventHandler;
    checked?: boolean;
    isLongBox?: boolean;
    isFakeSlide?: boolean;
    additionalClass?: string;
}

interface ComponentState {}

export default class InputRadioCheckbox extends React.Component<
    ComponentProps,
    ComponentState
> {
    render() {
        const boxContent = (
            <>
                <div className="form-item-radio__label-circle">
                    {this.props.children}
                </div>
                <div className="form-item-radio__label-text">
                    {this.props.label}
                </div>
                {this.props.subLabel && (
                    <div className="form-item-radio__sublabel-text">
                        {this.props.subLabel}
                    </div>
                )}
            </>
        );

        return (
            <div
                className={
                    "form-item-radio" +
                    (this.props.isLongBox ? " form-item-radio--long-box" : "") +
                    (this.props.isFakeSlide
                        ? " form-item-radio--fake-slide"
                        : "") +
                    (this.props.additionalClass
                        ? " " + this.props.additionalClass
                        : "")
                }
            >
                <input
                    id={this.props.id}
                    name={this.props.name}
                    value={this.props.value}
                    type={this.props.inputType}
                    className="form-field__input"
                    checked={this.props.checked}
                    onChange={this.props.onChange}
                    onClick={this.props.onClick}
                    data-label={this.props.dataLabel}
                    data-number={this.props.dataNumber}
                    data-key={this.props.dataKey}
                />
                {this.props.label && (
                    <label
                        htmlFor={this.props.id}
                        className={
                            "form-field" +
                            (this.props.additionalClass
                                ? " " + this.props.additionalClass
                                : "")
                        }
                    >
                        {this.props.isLongBox ? (
                            <React.Fragment>{boxContent}</React.Fragment>
                        ) : (
                            <div
                                className={
                                    "form-field-wrapper" +
                                    (this.props.additionalClass
                                        ? " " + this.props.additionalClass
                                        : "")
                                }
                            >
                                {boxContent}
                            </div>
                        )}
                    </label>
                )}
            </div>
        );
    }
}
