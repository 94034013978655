import React, { Component } from "react";
import "./NavigationSubLink.scss";
import { connect } from "react-redux";
import { updateCurrentPage } from "../../store/data/actions";
import SVGArrow from "../svg/Arrow";
import { RouterMenuGroupItem } from "../../route/Routes";
import { Form } from "../../store/data/types";
import { RoutesPage } from "../../route/RouteUtil";
import SVGCheckMark from "../svg/Checkmark";
import path from "../../components/utils/GetPageNumberByPath";
import findFirstDisabledPage from "../utils/FindFirstDisabledPage";

interface ComponentProps {
    currentPage: number;
    updateCurrentPage: Function;
    forms: Form[];
    item: RouterMenuGroupItem;
    className?: string;
    children: any;
    listOfPages: RoutesPage[];
}

class NavigationSubLink extends Component<ComponentProps> {
    getActiveClass: Function = () => {
        if (this.props.currentPage === this.props.item.pageNumber) {
            return " active";
        }
        return "";
    };

    getHiddenClass: Function = () => {
        if (
            this.props.item.isDisabled &&
            this.props.listOfPages[path(this.props.item.path) - 1].isDisabled
        ) {
            return " main-navigation__subitem--hidden";
        } else {
            return "";
        }
    };

    isFormSubmitted: Function = () => {
        let form: Form[] = [...this.props.forms].filter(
            item =>
                item.pageNumber === this.props.item.pageNumber ||
                item.pageNumber === this.props.item.pageNumber + 1
        );
        let selectedForms = form.sort(function(prevForm: Form, nextForm: Form) {
            return prevForm.pageNumber - nextForm.pageNumber;
        });
        if (selectedForms.length > 0) {
            if (!selectedForms[0].data.isDisabled && selectedForms[1]) {
                return true;
            }
        }
    };

    getDisabledClass = () => {
        if (findFirstDisabledPage() < this.props.item.pageNumber) {
            return " main-navigation__subitem--disabled";
        } else {
            return "";
        }
    };

    render() {
        return (
            <li
                className={
                    this.props.className +
                    this.getActiveClass() +
                    this.getHiddenClass() +
                    this.getDisabledClass()
                }
                onClick={() => {
                    this.getDisabledClass() ||
                        this.props.updateCurrentPage(
                            this.props.item.pageNumber
                        );
                }}
            >
                <div className={"main-navigation__subitem-text"}>
                    <SVGArrow className={"main-navigation__subitem-arrow"} />
                    {this.props.item.title}
                </div>
                <div className={"main-navigation__subitem-additional"}>
                    {this.isFormSubmitted() && (
                        <SVGCheckMark viewBox={"0 0 50 36"} />
                    )}
                </div>
            </li>
        );
    }
}

const mapStaTeToProps = (props: ComponentProps) => {
    return {
        currentPage: props.currentPage,
        forms: props.forms,
        listOfPages: props.listOfPages
    };
};

export default connect(mapStaTeToProps, {
    updateCurrentPage: updateCurrentPage
})(NavigationSubLink);
