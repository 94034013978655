import {
    CURRENT_PAGE,
    CURRENT_BROKER,
    Form,
    FORM_IMPORT,
    FORM_UPDATE,
    LIST_OF_PAGES,
    FORM_RESET,
    FormImportAction,
    FormUpdateAction,
    HEADER_LOGO_STATUS
} from "./types";

export function updateForm(newForm: Form): FormUpdateAction {
    return {
        type: FORM_UPDATE,
        payload: newForm
    };
}

export function importForm(listForm: Form[]): FormImportAction {
    return {
        type: FORM_IMPORT,
        content: listForm
    };
}

export function resetForm(listForm: Form[]): FormImportAction {
    return {
        type: FORM_RESET,
        content: listForm
    };
}

export const updateCurrentPage = (pageNumber: number) => {
    return {
        type: CURRENT_PAGE,
        payload: pageNumber
    };
};

export const updateCurrentBroker = (broker: string) => {
    return {
        type: CURRENT_BROKER,
        payload: broker
    };
};

export const updateListOfPages = (listOfPages: any) => {
    return {
        type: LIST_OF_PAGES,
        payload: listOfPages
    };
};

export const updateHeaderLogoRightSideVisible = (status: boolean) => {
    return {
        type: HEADER_LOGO_STATUS,
        payload: status
    };
};
