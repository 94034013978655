import React from "react";
import Heading from "../../components/Heading/Heading";
import Button from "../../components/Button/Button";
import InputRadioCheckbox from "../../components/InputRadioCheckbox/InputRadioCheckbox";
import Subheadline from "../../components/Subheadline/Subheadline";
import { StoreManager } from "../../store";
import { connect } from "react-redux";
import { updateCurrentPage, updateForm } from "../../store/data/actions";
import BackToSummary from "../../components/BackToSummary/BackToSummary";
import { Form } from "../../store/data/types";
import path from "../../components/utils/GetPageNumberByPath";
import { Grid, Row, Col } from "react-flexbox-grid";
import generateRecommendationText from "../../components/utils/GenerateRecommendationText";
import resetSliderValue from "../../components/utils/ResetSliderValue";
import InfoBox from "../../components/InfoBox/InfoBox";

interface ComponentProps {
    currentPage: number;
    updateForm: Function;
    updateCurrentPage: Function;
    forms: Form[];
    path: string;
}

interface ComponentState {
    risk: string;
    riskLabel: string;
    riskNumber: number;
    isDisabled: boolean;
    percentageRateMark: number;
}

class Page04 extends React.Component<ComponentProps, ComponentState> {
    state: ComponentState = {
        risk: "",
        riskLabel: "",
        riskNumber: 0,
        percentageRateMark: 0,
        isDisabled: true
    };

    sendForm = () => {
        this.props.updateForm({
            pageNumber: path(this.props.path),
            data: this.state
        });
    };

    componentDidMount() {
        const find: Form | undefined = StoreManager.getState().forms.find(
            item => item.pageNumber === path(this.props.path)
        );
        if (find) {
            this.setState(find.data as ComponentState);
        } else {
            this.sendForm();
        }
    }

    handleFormSubmission = (e: React.FormEvent): void => {
        e.preventDefault();
        this.props.updateCurrentPage(this.props.currentPage + 1);
    };

    handleInputChange = (e: any): void => {
        this.setState(
            {
                [e.target.name]: e.target.value,
                isDisabled: Boolean(!e.target.value),
                riskLabel: e.target.getAttribute("data-label"),
                riskNumber: Number(e.target.getAttribute("data-number")),
                percentageRateMark: Number(e.target.getAttribute("data-key"))
            } as ComponentState,
            () => {
                this.sendForm();
                this.resetSlider();
            }
        );
    };

    resetSlider = () => {
        // Set slider values based on the users choice on page 3.1
        const find: Form | undefined = StoreManager.getState().forms.find(
            item => item.pageNumber === path("umsetzungsoptionen")
        );

        if (find) {
            resetSliderValue(
                this.state.percentageRateMark,
                find.data.implementationOption
            );
        }

        resetSliderValue(this.state.percentageRateMark);
        generateRecommendationText();
    };

    render() {
        return (
            <section className="parallax-layer">
                <Heading rank={2} text={"2.0 Prämissen"} />
                <Heading rank={3} text={"2.3 Risikobereitschaft"} />

                <Subheadline>
                    Welche Risikobereitschaft hat die Anlegerin bzw. der
                    Anleger?
                </Subheadline>

                <div className="box-model">
                    <Grid fluid>
                        <Row className={"row--no-margin row--bottom-48"}>
                            <Col xs={2} className={"col-1-6 no-padding"}>
                                <InputRadioCheckbox
                                    inputType={"radio"}
                                    id={"foo1"}
                                    name={"risk"}
                                    label={"RKL 1\nsehr gering"}
                                    dataLabel={"Stufe 1"}
                                    dataNumber={1}
                                    dataKey={0}
                                    value={"sehr gering"}
                                    checked={this.state.risk === "sehr gering"}
                                    onChange={this.handleInputChange}
                                    isFakeSlide={true}
                                    additionalClass={"first-of-type"}
                                ></InputRadioCheckbox>
                            </Col>

                            <Col xs={2} className={"col-1-6 no-padding"}>
                                <InputRadioCheckbox
                                    inputType={"radio"}
                                    id={"foo2"}
                                    name={"risk"}
                                    label={"RKL 2\nmäßig"}
                                    dataLabel={"Stufe 2"}
                                    dataNumber={2}
                                    dataKey={2}
                                    value={"mäßig"}
                                    checked={this.state.risk === "mäßig"}
                                    onChange={this.handleInputChange}
                                    isFakeSlide={true}
                                    additionalClass={"second-of-type"}
                                ></InputRadioCheckbox>
                            </Col>

                            <Col xs={2} className={"col-1-6 no-padding"}>
                                <InputRadioCheckbox
                                    inputType={"radio"}
                                    id={"foo3"}
                                    name={"risk"}
                                    label={"RKL 3\nausgeprägt"}
                                    dataLabel={"Stufe 3"}
                                    dataNumber={3}
                                    dataKey={5}
                                    value={"ausgeprägt"}
                                    checked={this.state.risk === "ausgeprägt"}
                                    onChange={this.handleInputChange}
                                    isFakeSlide={true}
                                    additionalClass={"third-of-type"}
                                ></InputRadioCheckbox>
                            </Col>

                            <Col xs={2} className={"col-1-6 no-padding"}>
                                <InputRadioCheckbox
                                    inputType={"radio"}
                                    id={"foo4"}
                                    name={"risk"}
                                    label={"RKL 4\nhoch"}
                                    dataLabel={"Stufe 4"}
                                    dataNumber={4}
                                    dataKey={8}
                                    value={"hoch"}
                                    checked={this.state.risk === "hoch"}
                                    onChange={this.handleInputChange}
                                    isFakeSlide={true}
                                    additionalClass={"fourth-of-type"}
                                ></InputRadioCheckbox>
                            </Col>

                            <Col xs={2} className={"col-1-6 no-padding"}>
                                <InputRadioCheckbox
                                    inputType={"radio"}
                                    id={"foo5"}
                                    name={"risk"}
                                    label={"RKL 5\nsehr hoch"}
                                    dataLabel={"Stufe 5"}
                                    dataNumber={5}
                                    dataKey={10}
                                    value={"sehr hoch"}
                                    checked={this.state.risk === "sehr hoch"}
                                    onChange={this.handleInputChange}
                                    isFakeSlide={true}
                                    additionalClass={"fifth-of-type"}
                                ></InputRadioCheckbox>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={10}>
                                <InfoBox
                                    isVisible={
                                        this.state.risk === "sehr gering"
                                    }
                                    hasBulletList={true}
                                >
                                    <li>Geringe Ertragschancen</li>
                                    <li>Vermögenserhaltung</li>
                                    <li>
                                        Sehr geringe Kursrisiken, sehr geringe
                                        Bonitätsrisiken
                                    </li>
                                </InfoBox>
                                <InfoBox
                                    isVisible={this.state.risk === "mäßig"}
                                    hasBulletList={true}
                                >
                                    <li>Begrenzte Ertragschancen</li>
                                    <li>
                                        Geringe Kursrisiken, geringe
                                        Bonitätsrisiken
                                    </li>
                                </InfoBox>
                                <InfoBox
                                    isVisible={this.state.risk === "ausgeprägt"}
                                    hasBulletList={true}
                                >
                                    <li>
                                        Überdurchschnittliche Ertragschancen
                                    </li>
                                    <li>
                                        Mittlere Kurs- und Währungsrisiken,
                                        mittlere Bonitätsrisiken
                                    </li>
                                </InfoBox>
                                <InfoBox
                                    isVisible={this.state.risk === "hoch"}
                                    hasBulletList={true}
                                >
                                    <li>Hohe Ertragschancen</li>
                                    <li>
                                        Hohe Kurs- und Währungsrisiken, hohe
                                        Bonitätsrisiken
                                    </li>
                                </InfoBox>
                                <InfoBox
                                    isVisible={this.state.risk === "sehr hoch"}
                                    hasBulletList={true}
                                >
                                    <li>Sehr hohe Ertragschancen</li>
                                    <li>
                                        Sehr hohe Kurs- und Währungsrisiken,
                                        sehr hohe Bonitätsrisiken
                                    </li>
                                </InfoBox>
                            </Col>
                        </Row>
                    </Grid>
                </div>

                <Grid fluid className={"bottom-sticky"}>
                    <Row>
                        <Col xs={12} className={"align-right"}>
                            <BackToSummary />
                            <Button
                                styling={"primary"}
                                onClick={this.handleFormSubmission}
                                isDisabled={this.state.isDisabled}
                            >
                                Weiter
                            </Button>
                        </Col>
                    </Row>
                </Grid>
            </section>
        );
    }
}

const mapStaTeToProps = (props: ComponentProps) => {
    return { currentPage: props.currentPage, forms: props.forms };
};

export default connect(mapStaTeToProps, {
    updateCurrentPage,
    updateForm
})(Page04);
