import * as React from "react";

const SVGGlobal: React.FC<SvgProps> = props => (
    <svg width={14} height={14} {...props}>
        <path
            d="M8.316 12.323c.434-.48.797-1.194 1.057-2.069h2.037a5.497 5.497 0 01-3.094 2.07zM2.59 10.254h2.037c.26.875.623 1.589 1.057 2.07a5.497 5.497 0 01-3.094-2.07zm3.094-8.577c-.434.48-.797 1.194-1.057 2.069H2.59a5.498 5.498 0 013.094-2.07zm5.726 2.069H9.373c-.26-.875-.623-1.589-1.057-2.07a5.498 5.498 0 013.094 2.07zM9.807 6.742a11.909 11.909 0 00-.297-2.48h2.24c.426.737.685 1.58.727 2.48h-2.67zm0 .516h2.67a5.446 5.446 0 01-.727 2.48H9.51c.175-.746.28-1.585.297-2.48zm-5.614-.516h-2.67c.042-.9.301-1.743.727-2.48h2.24c-.175.746-.28 1.585-.297 2.48zm4.785-2.48c.187.753.294 1.597.312 2.48H4.71c.018-.883.125-1.727.312-2.48h3.956zM7 1.518c.565 0 1.136.557 1.568 1.528.097.22.185.454.264.701H5.168c.079-.247.167-.482.264-.701C5.864 2.074 6.435 1.517 7 1.517zM1.523 7.258h2.67c.017.895.122 1.734.297 2.48H2.25a5.446 5.446 0 01-.727-2.48zm3.5 2.48a11.291 11.291 0 01-.313-2.48h4.58a11.291 11.291 0 01-.312 2.48H5.022zM7 12.482c-.565 0-1.136-.557-1.568-1.528a6.819 6.819 0 01-.264-.701h3.664a6.819 6.819 0 01-.264.701c-.432.971-1.003 1.528-1.568 1.528zM7 1C3.692 1 1 3.692 1 7s2.692 6 6 6 6-2.692 6-6-2.692-6-6-6z"
            fill="red"
            fillRule="evenodd"
        />
    </svg>
);

export default SVGGlobal;
