import React, { BaseSyntheticEvent, ReactElement } from "react";
import "./NavigationPrintSave.scss";
import Button from "../Button/Button";
import { StoreManager } from "../../store";
import { Form } from "../../store/data/types";
import FileSaver from "file-saver";
import packageJson from "../../../package.json";
import Modal from "../Modal/Modal";
import { connect } from "react-redux";
import { updateForm } from "../../store/data/actions";
import InputTextCheckbox from "../InputTextCheckbox/InputTextCheckbox";

interface ComponentProps {
    className?: string;
    updateForm: Function;
}

interface ComponentState {
    isModalVisible?: boolean;
    isModalBarsLoaderVisible?: boolean;
    printMusterPortfolio?: boolean;
}

class NavigationPrintSave extends React.Component<
    ComponentProps,
    ComponentState
> {
    state = {
        isModalVisible: false,
        isModalBarsLoaderVisible: false,
        printMusterPortfolio: false
    };

    exportPDF = (e: React.FormEvent): void => {
        const currentState: any = StoreManager.getState();

        this.setState({ isModalBarsLoaderVisible: true });

        let urlAPI = (process.env.REACT_APP_WS_PATH || "/dvvp") + "/pdf/create";
        fetch(urlAPI, {
            credentials: "same-origin",
            method: "POST",
            headers: {
                accept: "application/pdf",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                version: packageJson.version,
                forms: currentState.forms,
                printMusterPortfolio: this.state.printMusterPortfolio
            })
        })
            .then(response => {
                if (response.status >= 400) {
                    throw new Error(
                        "It was not possible to generate the PDF. Status: " +
                            response.status
                    );
                }
                return response.blob();
            })
            .then(data => {
                this.loadingModalTimeOut(() => {
                    const blob = new Blob([data], { type: "application/pdf" });
                    FileSaver.saveAs(blob, "dvvp.pdf");
                });
            })
            .catch(error => {
                console.error("Error:", error);
                this.loadingModalTimeOut(() => {
                    this.setState({ isModalVisible: true });
                });
            });
    };

    loadingModalTimeOut = (fn: Function): void => {
        setTimeout(() => {
            this.setState({ isModalBarsLoaderVisible: false });
            fn();
        }, 1000);
    };

    exportJSON = (e: React.FormEvent): void => {
        let fileName = "Deka_VV_ID";
        let currentForms: Form[] = StoreManager.getState().forms;

        currentForms.forEach(item => {
            if (item.pageNumber === 1) {
                let min = 100,
                    max = 999,
                    random = Math.floor(Math.random() * (+max - +min)) + +min;

                const newDate = new Date();
                const date =
                    newDate.getFullYear() +
                    "" +
                    ("0" + (newDate.getMonth() + 1)).slice(-2) +
                    "" +
                    ("0" + newDate.getDate()).slice(-2);

                fileName += random + "" + date;
            }
        });

        currentForms = currentForms.filter(item => item.pageNumber !== 1);
        currentForms.unshift({ pageNumber: 1, data: { isDisabled: true } });
        const blob = new Blob([JSON.stringify(currentForms)], {
            type: "application/json;charset=utf-8"
        });
        FileSaver.saveAs(blob, fileName + ".json");
    };

    tryExportPDFAgain = (e: React.FormEvent): void => {
        this.setState({ isModalVisible: false });

        setTimeout(() => {
            this.exportPDF(e);
        }, 200);
    };

    handleCheckBox = (e: BaseSyntheticEvent): void => {
        this.setState({ [e.target.name]: e.target.checked } as ComponentState);
    };

    buttonModal: ReactElement = (
        <div className={"modal__button-wrapper"}>
            <Button
                styling={"primary"}
                onClick={this.tryExportPDFAgain}
                additionalClass={"button__no-icon"}
            >
                Erneut versuchen
            </Button>
        </div>
    );

    render() {
        return (
            <>
                <div className={"print-and-save " + this.props.className}>
                    <p className={"print-and-save__description"}>
                        Sie können das Ergebnis als druckfähiges PDF ausgeben
                        lassen und als Datei für die erneute Bearbeitung
                        speichern*.
                    </p>

                    <InputTextCheckbox
                        name={"printMusterPortfolio"}
                        checked={this.state.printMusterPortfolio}
                        onChange={this.handleCheckBox}
                        additionalClass={"text-checkbox--musterportfolios"}
                    >
                        Muster-Anlagestrategie anhängen
                    </InputTextCheckbox>

                    <Button
                        styling={"primary"}
                        additionalClass={"print-and-save__btn-print"}
                        onClick={this.exportPDF}
                    >
                        PDF drucken
                    </Button>

                    <Button
                        styling={"secondary"}
                        additionalClass={"print-and-save__btn-save"}
                        onClick={this.exportJSON}
                    >
                        Daten speichern
                    </Button>
                    <p className={"print-and-save__footnote"}>
                        * Bitte beachten Sie, dass aus datenschutzrechtlichen
                        Gründen persönliche Daten nicht gespeichert werden.
                        Denken Sie daran, die anonymisierte Datei-ID so zu
                        hinterlegen, dass sie dem Anleger erneut zugeordnet
                        werden kann.
                    </p>
                </div>

                <Modal
                    isVisible={this.state.isModalVisible}
                    hasCloseX={true}
                    buttonWrapper={this.buttonModal}
                    headlineText={"Das PDF konnte nicht erstellt werden."}
                    hasNoCopyText={true}
                    onExit={() => {
                        this.setState({ isModalVisible: false });
                    }}
                />

                <Modal
                    isVisible={this.state.isModalBarsLoaderVisible}
                    hasLoader={true}
                    hasCloseX={false}
                    headlineText={"Das PDF wird erstellt."}
                    onExit={() => {
                        this.setState({ isModalBarsLoaderVisible: false });
                    }}
                />
            </>
        );
    }
}

export default connect(null, {
    updateForm
})(NavigationPrintSave);
