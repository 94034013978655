import React, { FormEventHandler } from "react";
import "./LinkStandard.scss";
import Plus from "../svg/Plus";
import Minus from "../svg/MinusCenter";

interface LinkStandardProps {
    onClick: FormEventHandler;
    additionalClass?: string;
    id?: string;
    isDisabled?: boolean;
    icon?: string;
}

const LinkStandard: React.FC<LinkStandardProps> = ({
    children,
    additionalClass,
    onClick,
    id,
    isDisabled,
    icon
}) => {
    return (
        <span
            className={
                "link-standard " +
                (additionalClass ? additionalClass : "") +
                (isDisabled ? " link-standard--disabled " : "")
            }
            onClick={onClick}
            id={id}
        >
            {children}
            {icon === "plus" && <Plus />}
            {icon === "minus" && <Minus />}
        </span>
    );
};

export default LinkStandard;
