import * as React from "react";

const SVGGlobalSustainableFonds: React.FC<SvgProps> = props => (
    <svg
        id="Layer_1"
        x={0}
        y={0}
        viewBox="0 0 14 14"
        xmlSpace="preserve"
        {...props}
    >
        <path
            className="st0"
            d="M4.6 7.2c-.2.2-.4.4-.6.4-.3 0-.8-.5-1.1-1.3h1.2c0-.1-.1-.3-.1-.4H2.8c-.1-.4-.2-1-.2-1.6h1.3v-.4H2.6c0-.6.1-1.1.2-1.6h1.4c.1-.2.2-.2.3-.3.2-.1.5-.1.7 0 .1 0 .3.1.5.3h1.4c.2.4.4.8.4 1.3.2.1.3.3.5.5V4c0-.8-.2-1.6-.7-2.2C6.6.7 5.4 0 4 0S1.4.7.7 1.8C.2 2.4 0 3.2 0 4s.2 1.6.7 2.2C1.4 7.3 2.6 8 4 8c.4 0 .8-.1 1.2-.2l-.6-.6zm2.2-5.4H5.5c-.1-.5-.3-.9-.6-1.2.8.2 1.4.6 1.9 1.2zM4 .4c.3 0 .8.5 1.1 1.3H2.9C3.2.9 3.7.4 4 .4zm-.9.2c-.3.3-.5.7-.6 1.2H1.2c.5-.6 1.1-1 1.9-1.2zM.9 2.2h1.4c-.1.5-.2 1-.2 1.6H.5c0-.6.2-1.1.4-1.6zm-.4 2h1.7c0 .6.1 1.1.2 1.6H.9c-.2-.5-.4-1-.4-1.6zm.7 2h1.2c.2.5.4.9.6 1.2-.7-.2-1.3-.6-1.8-1.2z"
        />
        <path
            className="st0"
            d="M8.2 6.1c.1-.5 0-1-.4-1.5C7 3.5 5 2.5 4.9 2.5h-.2c-.1 0-.1.1-.1.2s-.4 2.3.1 3.6c.2.7.7 1.1 1.2 1.3.2.1.3.1.5.1s.4-.1.6-.1l.3.6c0 .1.1.1.2.1h.1c.1-.2.2-.3.1-.4l-.3-.6c.4-.3.7-.7.8-1.2zm-1 .8l-.1-.2.1-1.2c0-.1-.1-.2-.2-.2s-.2.1-.2.2v.6l-1-1.8c-.1-.1-.2-.1-.3-.1-.1.1-.2.2-.1.3L6 5.7l-.6-.2c-.1 0-.2 0-.3.1 0 .1 0 .2.1.3l1.1.4.4.8c-.1.1-.4.1-.7 0-.2-.1-.6-.3-.9-1-.3-1-.2-2.5-.1-3.1.6.3 1.9 1 2.5 1.9.3.4.4.8.3 1.2-.1.4-.4.6-.6.8zM11.4 6.6c-1.5 0-2.6.7-2.6 1.6v4.4c0 .9 1.1 1.6 2.6 1.6s2.6-.7 2.6-1.6V8.1c0-.9-1.1-1.5-2.6-1.5zm0 .4c1.2 0 2.2.5 2.2 1.1s-1 1.1-2.2 1.1-2.2-.5-2.2-1.1 1-1.1 2.2-1.1zm0 5.5c-1.2 0-2.2-.5-2.2-1.1v-.2c.1.1.2.2.4.3 0 0 .1 0 .1.1.1.1.2.1.3.1.1 0 .1 0 .2.1.1 0 .3.1.4.1H12.2c.1 0 .3-.1.4-.1.1 0 .1 0 .2-.1.1 0 .2-.1.3-.1 0 0 .1 0 .1-.1.1-.1.3-.2.4-.3v.2c0 .6-1 1.1-2.2 1.1zm0-1.1c-1.2 0-2.2-.5-2.2-1.1v-.2c.1.1.2.2.4.3 0 0 .1 0 .1.1.1.1.2.1.3.1.1 0 .1 0 .2.1.1 0 .3.1.4.1H12.2c.1 0 .3-.1.4-.1.1 0 .1 0 .2-.1.1 0 .2-.1.3-.1 0 0 .1 0 .1-.1.1-.1.3-.2.4-.3v.2c0 .6-1 1.1-2.2 1.1zm0-1.1c-1.2 0-2.2-.5-2.2-1.1V9c.1.1.2.2.4.3 0 0 .1 0 .1.1.1.1.2.1.3.1.1 0 .1 0 .2.1.1 0 .3.1.4.1H12.2c.1 0 .3-.1.4-.1.1 0 .1 0 .2-.1.1 0 .2-.1.3-.1 0 0 .1 0 .1-.1.1-.1.3-.2.4-.3v.2c0 .6-1 1.1-2.2 1.1zm0 3.3c-1.2 0-2.2-.5-2.2-1.1v-.2c.2.2.5.4.9.5.4.1.8.2 1.2.2.5 0 .9-.1 1.2-.2.2-.1.3-.1.5-.2s.3-.2.4-.3v.2c.2.5-.8 1.1-2 1.1zM5.4 8.7c-1.5 0-2.6.7-2.6 1.6v2.2c0 .8 1.2 1.5 2.6 1.5 1.5 0 2.6-.7 2.6-1.6v-2.2c0-.8-1.1-1.5-2.6-1.5zm0 .4c1.2 0 2.2.5 2.2 1.1s-1 1.1-2.2 1.1-2.2-.5-2.2-1.1 1-1.1 2.2-1.1zm0 3.4c-1.2 0-2.2-.5-2.2-1.1v-.2c.1.1.2.2.4.3 0 0 .1 0 .1.1.1.1.2.1.3.1.1 0 .1 0 .2.1.1 0 .3.1.4.1H6.2c.1 0 .3-.1.4-.1.1 0 .1 0 .2-.1.1 0 .2-.1.3-.1 0 0 .1 0 .1-.1.1-.1.3-.2.4-.3v.2c0 .5-1 1.1-2.2 1.1zm0 1c-1.2 0-2.2-.5-2.2-1.1v-.2c.1.1.2.2.4.3 0 0 .1 0 .1.1.1.1.2.1.3.1.1 0 .1 0 .2.1.1 0 .3.1.4.1H6.2c.1 0 .3-.1.4-.1.1 0 .1 0 .2-.1.1 0 .2-.1.3-.1 0 0 .1 0 .1-.1.1-.1.3-.2.4-.3v.2c0 .6-1 1.1-2.2 1.1z"
        />
    </svg>
);

export default SVGGlobalSustainableFonds;
