import { Form } from "../../store/data/types";
import { StoreManager } from "../../store";

/**
 * Loop trough the data from all the pages and return the
 * number of the first disabled it page. This information can
 * be useful to avoid allow the user to go to page 8, for example
 * if the page 6 is disabled because the options are invalid
 */
const findFirstDisabledPage: Function = (): number => {
    const forms: Form[] = StoreManager.getState().forms;

    // Order the list of Forms by the page number in decrescent order
    let listOfForms = [...forms].sort(function(prevForm: Form, nextForm: Form) {
        return nextForm.pageNumber - prevForm.pageNumber;
    });

    listOfForms = listOfForms.filter(data => {
        // remove sub pages from the count
        return Number.isInteger(data.pageNumber);
    });

    let firstDisabledPage = listOfForms.length;

    listOfForms.forEach((form: Form) => {
        if (form.data.isDisabled) {
            firstDisabledPage = form.pageNumber;
        }
    });

    return firstDisabledPage;
};

export default findFirstDisabledPage;
