import { StoreManager } from "../../store";
import path from "./GetPageNumberByPath";

const getStrategyName: Function = (): string => {
    let percentageRate: string = "",
        sliderPValue: string = "",
        strategyLabel: string = "",
        isFondsbausteine: boolean = false,
        isAusschuettungsfokus: boolean = false;

    StoreManager.getState().forms.forEach((item: any) => {
        if (item.pageNumber === path("maximale-aktienquote")) {
            percentageRate = item.data.percentageRate;
            sliderPValue = item.data.sliderPValue;
        } else if (item.pageNumber === path("ausrichtung")) {
            strategyLabel = item.data.strategyLabel;
        } else if (item.pageNumber === path("umsetzungsoptionen")) {
            isFondsbausteine =
                item.data.implementationOption === "fondsbausteine";
            isAusschuettungsfokus =
                item.data.implementationOption ===
                "einzeltitelUndAusschuettungsfokus";
        }
    });

    return `${sliderPValue}${
        isFondsbausteine || isAusschuettungsfokus ? " IF" : ""
    } Aktien ${strategyLabel
        .replace("Global Fonds", "")
        .replace("Global nachhaltig Fonds Ausschüttungsfokus", "")
        .replace(" nachhaltig", "")
        .replace("Überwiegend ", "")}${
        isFondsbausteine || isAusschuettungsfokus ? "" : " "
    }${percentageRate}${
        strategyLabel.indexOf("nachhaltig") >= 0 ? " ESG" : ""
    }${isAusschuettungsfokus ? " AF" : ""}`;
};

export default getStrategyName;
