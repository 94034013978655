import React from "react";
import "./ProgressBar.scss";
// import NavigationArrows from "../NavigationArrows/NavigationArrows";
import { connect } from "react-redux";
import { RoutesPage } from "../../route/RouteUtil";

interface ProgressBarProps {
    currentPage: number;
    listOfPages: RoutesPage[];
}
const ProgressBar: React.FC<ProgressBarProps> = ({
    listOfPages,
    currentPage
}) => {
    const listOfPagesTotal = listOfPages.length;
    let percentage = (100 * currentPage) / listOfPagesTotal;

    return (
        <div className="main-progress">
            {/*/!*<NavigationArrows />*!/    NAVIGATIONS ARROWS REMOVED*/}
            <div className="progress-bar">
                <span
                    className="progress-bar__status"
                    style={{ height: `${percentage}%` }}
                />
            </div>
        </div>
    );
};

const mapStaTeToProps = (props: ProgressBarProps) => {
    return { currentPage: props.currentPage, listOfPages: props.listOfPages };
};

export default connect(mapStaTeToProps, {})(ProgressBar);
