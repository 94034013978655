import * as React from "react";

const SVGFondsbausteinen: React.FC<SvgProps> = props => (
    <svg
        id="Layer_1"
        x={0}
        y={0}
        viewBox="0 0 14 14"
        xmlSpace="preserve"
        {...props}
    >
        <path
            className="st0"
            d="M7.9 10.4s-.5 1.2 0 1.9l.4-.2c-.4-.6 0-1.5 0-1.5l-.4-.2z"
        />
        <path
            className="st0"
            d="M13.7 12.3c0-.9-.2-1.7-.5-2.3-.2-.5-.5-.9-1-1.4-.4-.3-.8-.6-1.1-.7l.5-.9c.1-.2.2-.4.1-.6-.1-.2-.3-.3-.5-.3H8.9c-.1 0-.4 0-.5.3-.1.2 0 .4.1.6l.5.8c-.2.1-.8.3-1.2.8-.4.3-.8.8-1 1.4-.3.7-.5 1.5-.5 2.3 0 .1 0 .4.2.8.3.4.8.5 1.5.5h4c.7 0 1.2-.2 1.5-.5.2-.4.2-.7.2-.8zM8.9 6.7c-.1-.1-.1-.1-.1-.2h2.4c.1 0 .1 0 .1.1v.2l-.6.9c-.4-.1-.8-.1-1.1 0l-.7-1zm4.2 6.1c-.2.2-.6.4-1.2.4H8c-.6 0-1-.1-1.2-.4-.1-.2-.1-.4-.1-.5 0-.8.2-1.6.5-2.2.2-.5.5-.9.9-1.3s1-.6 1.2-.7c.2 0 .9-.1 1.4 0 .2.1.8.4 1.2.8.4.4.7.8.9 1.2.3.6.4 1.4.5 2.2 0 .1 0 .3-.2.5zM9 .4H1C.6.4.3.7.3 1.1v3.5c0 .4.3.7.7.7h8c.4 0 .7-.3.7-.7V1.1c0-.4-.3-.7-.7-.7zM.7 4.6V1.1c0-.1.1-.3.3-.3h3.8V2c0 .3.2.5.5.6.1 0 .2 0 .2-.1.1-.2.4-.4.7-.4.4 0 .8.4.8.8s-.4.8-.9.8c-.3 0-.5-.1-.7-.3 0-.1-.1-.1-.2-.1-.2-.1-.4.2-.4.5v1.1H1c-.2 0-.3-.1-.3-.3zm8.6 0c0 .2-.1.3-.3.3H5.2V3.8v-.1c.3.3.6.4.9.4.7 0 1.2-.6 1.2-1.2s-.5-1.3-1.2-1.3c-.3 0-.6.2-.9.4V.9H9c.2 0 .3.1.3.3v3.4zM6.1 10c-2.2 0-4-1.8-4-4 0-.1.1-.2.2-.2s.2.1.2.2c0 1.9 1.6 3.5 3.5 3.5.1 0 .2.1.2.2s0 .3-.1.3z"
        />
    </svg>
);

export default SVGFondsbausteinen;
