import React, { FormEventHandler } from "react";
import "./InputTextRadio.scss";

interface InputTextProps {
    name: string;
    value?: string;
    checked?: boolean;
    onChange?: FormEventHandler;
}

const InputTextRadio: React.FC<InputTextProps> = ({
    children,
    name,
    value,
    checked,
    onChange
}) => {
    return (
        <div className="text-radio">
            <label className={"text-radio__label"}>
                <input
                    className={"text-radio__input"}
                    type={"radio"}
                    name={name}
                    value={value}
                    checked={checked}
                    onChange={onChange}
                />
                <span className={"text-radio__radio"} />
                <span className={"text-radio__text"}>{children}</span>
            </label>
        </div>
    );
};

export default InputTextRadio;
