import React, { BaseSyntheticEvent, Component } from "react";
import Heading from "../../components/Heading/Heading";
import Button from "../../components/Button/Button";
import Textarea from "../../components/Textarea/Textarea";
import Subheadline from "../../components/Subheadline/Subheadline";
import { updateCurrentPage, updateForm } from "../../store/data/actions";
import { connect } from "react-redux";
import { StoreManager } from "../../store";
import BackToSummary from "../../components/BackToSummary/BackToSummary";
import { Form } from "../../store/data/types";
import path from "../../components/utils/GetPageNumberByPath";
import { Grid, Row, Col } from "react-flexbox-grid";
import generateRecommendationText from "../../components/utils/GenerateRecommendationText";

interface ComponentProps {
    currentPage: number;
    updateForm: Function;
    updateCurrentPage: Function;
    derivationField?: string;
    forms: Form[];
    path: string;
}

interface ComponentState {}

class Page11 extends Component<ComponentProps, ComponentState> {
    state = {
        isDisabled: false,
        derivationField: "",
        textareaHasChanged: false,
        textIsGrey: false
    };

    textAreaRef = React.createRef<HTMLTextAreaElement>();

    sendForm = () => {
        const { isDisabled, derivationField, textareaHasChanged } = this.state;

        this.props.updateForm({
            pageNumber: path(this.props.path),
            data: {
                isDisabled,
                derivationField,
                textareaHasChanged
            }
        });
    };

    componentDidMount() {
        const find: Form | undefined = StoreManager.getState().forms.find(
            item => item.pageNumber === path(this.props.path)
        );

        if (find) {
            this.setState(find.data as ComponentState, () =>
                this.setTextAreaSize()
            );
        } else {
            this.sendForm();
            if (!this.state.textareaHasChanged) {
                const text = generateRecommendationText(true);
                this.setState({ derivationField: text }, () => {
                    this.sendForm();
                    this.setTextAreaSize();
                });
            }
        }
    }

    getClass = () => {
        return this.state.textIsGrey ? "" : "textarea-active";
    };

    setTextAreaSize = () => {
        const node = this.textAreaRef.current;
        node!.style.height = node!.scrollHeight + 2 + "px";
    };

    handleChange = (e: any) => {
        this.setState({ derivationField: e.target.value });

        if (e.target.value === "") {
            this.setState(
                {
                    isDisabled: true,
                    textareaHasChanged: true
                } as ComponentState,
                () => {
                    this.sendForm();
                }
            );
        } else {
            this.setState(
                {
                    isDisabled: false,
                    textareaHasChanged: true
                } as ComponentState,
                () => {
                    this.sendForm();
                }
            );
        }
    };

    handleFormSubmission = (e: React.FormEvent): void => {
        e.preventDefault();
        this.props.updateCurrentPage(this.props.currentPage + 1);
    };

    handleBlur = (e: BaseSyntheticEvent) => {
        if (!e.target.value) {
            this.setState(
                {
                    textareaHasChanged: false,
                    isDisabled: true,
                    textIsGrey: true
                },
                () => this.sendForm()
            );
        }
    };

    handleFocus = () => {
        this.setState({ isDisabled: false, textIsGrey: false }, () =>
            this.sendForm()
        );
    };

    render() {
        return (
            <section className="parallax-layer">
                <Heading rank={2} text={"4.0 Empfehlung"} />

                <Subheadline>
                    Bitte erläutern Sie, warum das Produkt für Ihren Kunden
                    empfohlen wird.
                </Subheadline>

                <div className="box-model">
                    <Grid fluid>
                        <Row>
                            <Col xs={9}>
                                <Textarea
                                    textAreaRef={this.textAreaRef}
                                    id={"derivationField"}
                                    additionalClass={
                                        "textarea-no-border " + this.getClass()
                                    }
                                    placeholderValue={
                                        this.state.derivationField
                                    }
                                    onChange={this.handleChange}
                                    onBlur={e => this.handleBlur(e)}
                                    onFocus={this.handleFocus}
                                />
                            </Col>
                        </Row>
                    </Grid>
                </div>

                <Grid fluid className={"bottom-sticky"}>
                    <Row>
                        <Col xs={12} className={"align-right"}>
                            <BackToSummary />
                            <Button
                                styling={"primary"}
                                isDisabled={this.state.isDisabled}
                                onClick={this.handleFormSubmission}
                            >
                                Weiter
                            </Button>
                        </Col>
                    </Row>
                </Grid>
            </section>
        );
    }
}

const mapStaTeToProps = (props: ComponentProps) => {
    return {
        currentPage: props.currentPage,
        forms: props.forms
    };
};

export default connect(mapStaTeToProps, {
    updateCurrentPage,
    updateForm
})(Page11);
