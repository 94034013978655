import { StoreManager } from "../../store";

import path from "./GetPageNumberByPath";
import { Category, Form } from "../../store/data/types";

const checkLawPerson: Function = (isLegalPerson: boolean): object => {
    const find: Form | undefined = StoreManager.getState().forms.find(
        item => item.pageNumber === path("verguetungsmodell")
    );
    if (find) {
        let data: Category = { ...find.data };

        const compensationValue = isLegalPerson
            ? find.data.originalCompensationValue + " %"
            : parseFloat(
                  String(Number(find.data.originalCompensationValue) * 1.17)
              ).toFixed(5) + " %";
        data.compensationValue = compensationValue;
        return data;
    }
    return {};
};

export default checkLawPerson;
