import React from "react";
import { connect } from "react-redux";
import { updateCurrentPage } from "../../store/data/actions";
import Button from "../Button/Button";
import { Form } from "../../store/data/types";
import path from "../utils/GetPageNumberByPath";

interface BackToSummaryProps {
    updateCurrentPage: Function;
    forms: Form[];
}

const BackToSummary: React.FC<BackToSummaryProps> = ({
    updateCurrentPage,
    forms
}) => {
    let isDisabled = false;

    forms.forEach((form: Form) => {
        if (form.data.isDisabled) {
            isDisabled = true;
        }
    });

    return forms.find(
        item => item.pageNumber === path("ergebnis") && !isDisabled
    ) ? (
        <Button
            styling={"secondary"}
            additionalClass={"back-to-summary"}
            onClick={() => {
                updateCurrentPage(path("ergebnis"));
            }}
        >
            Zum Ergebnis
        </Button>
    ) : (
        <></>
    );
};

const mapStaTeToProps = (state: BackToSummaryProps) => {
    return {
        forms: state.forms
    };
};

export default connect(mapStaTeToProps, {
    updateCurrentPage
})(BackToSummary);
