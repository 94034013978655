import React, { Component } from "react";
import Heading from "../../components/Heading/Heading";
import Accordion from "../../components/Accordion/Accordion";
import AccordionTableItem from "../../components/AccordionTableItem/AccordionTableItem";
import LinkStandard from "../../components/LinkStandard/LinkStandard";
import { StoreManager } from "../../store";
import { connect } from "react-redux";
import Modal from "../../components/Modal/Modal";
import SVGCheckMark from "../../components/svg/Checkmark";
import path from "../../components/utils/GetPageNumberByPath";
import { updateCurrentPage, updateForm } from "../../store/data/actions";
import { Form } from "../../store/data/types";
import { Grid, Row, Col } from "react-flexbox-grid";
import checkMoney from "../../components/utils/CheckMoney";
import getStrategyName from "../../components/utils/GetStrategyName";

interface ComponentProps {
    currentPage: number;
    updateForm: Function;
    updateCurrentPage: Function;
    inputValue?: number;
    path: string;
}

interface ComponentState {
    isModalVisible: boolean;
}

class Page13 extends Component<ComponentProps, ComponentState> {
    state = {
        salutation: "",
        title: "",
        company: "",
        firstname: "",
        surname: "",
        address: "",
        salutation2: "",
        title2: "",
        firstname2: "",
        surname2: "",
        isCouple: false,
        addressNumber: "",
        addressZip: "",
        addressCity: "",
        isLegalPerson: false,
        isExemptFromTax: false,
        targetListLabels: [],
        otherInput: "",
        sustainability: "",
        horizonText: "",
        horizonTextComplement: "",
        riskLabel: "",
        moneyVolume: "",
        percentageRate: "",
        strategyLabel: "",
        compensationValue: "",
        remuneration: "",
        remunerationTextarea: "",
        isModalVisible: false,
        isDisabled: true,
        isinText: "",
        derivationField: "",
        implementationOptionLabel: "",
        implementationOption: "",
        distributionOfOrdinaryIncomeCheckbox: false,
        checkList: {
            noDealWithWarrants: false,
            investmentsInwarrants: false,
            investmentsInCertificates: false,
            underlyingIsins: false,
            hasInvestmentGrade: false,
            hasNonInvestmentGrade: false,
            noCertificates: false,
            maxInvestiment: "",
            ratingRequirements: false,
            ratingRequirementsValue: ""
        },
        anyAgreementWasSelected: false,
        regularPayments: {
            checked: false,
            frequency1: "",
            frequency2: "",
            minimumAmount: ""
        }
    };

    componentDidMount() {
        const find: Form[] | undefined = StoreManager.getState().forms;
        let allData: any = {};
        find.forEach((data: any) => {
            for (const property in data.data) {
                allData[property] = data.data[property];
            }
        });
        this.setState({ ...allData }, () => {
            this.props.updateForm({
                pageNumber: path("ergebnis"),
                data: { productName: getStrategyName() }
            });
        });

        document
            .getElementById("showCompleteDerivationField")!
            .setAttribute("style", "display:none;");
    }

    componentDidUpdate() {
        this.ellipsizeTextBox("ellipsisDerivationField");
    }

    ellipsizeTextBox(id: any) {
        const el = document.getElementById(id);
        const wordArray = el!.innerHTML.split(" ");

        while (el!.scrollHeight > el!.offsetHeight) {
            wordArray.pop();
            el!.innerHTML = wordArray.join(" ") + " ...";

            document
                .getElementById("showCompleteDerivationField")!
                .setAttribute("style", "display:block;");
        }
    }

    handleDerivationFieldClick = () => {
        this.setState({ isModalVisible: true });
    };

    render() {
        return (
            <section className="parallax-layer is-last-page">
                <Heading rank={2} text={"6.0 Ergebnis"} />

                <div className="box-model">
                    <Grid fluid>
                        <Row>
                            <Col xs={12}>
                                <Accordion
                                    headline={"1.0 Persönliche Daten"}
                                    openByDefault={true}
                                    isBigHeadline={true}
                                >
                                    <AccordionTableItem
                                        listItemTitle={"Persönliche Daten"}
                                        onClick={() => {
                                            this.props.updateCurrentPage(
                                                path("persoenliche-daten")
                                            );
                                        }}
                                    >
                                        {" "}
                                        <span
                                            className={
                                                "accordion-list-item__separator"
                                            }
                                        >
                                            {this.state.salutation + " "}
                                            {this.state.title + " "}
                                            {this.state.firstname}{" "}
                                            {this.state.surname}
                                            {this.state.isCouple &&
                                                " und " +
                                                    this.state.salutation2 +
                                                    " " +
                                                    this.state.title2 +
                                                    " " +
                                                    this.state.firstname2 +
                                                    " " +
                                                    this.state.surname2}
                                        </span>
                                        {this.state.address && (
                                            <span
                                                className={
                                                    "accordion-list-item__separator"
                                                }
                                            >
                                                {this.state.address}{" "}
                                                {this.state.addressNumber}
                                                {", "}
                                                {this.state.addressZip}{" "}
                                                {this.state.addressCity}
                                            </span>
                                        )}
                                        {this.state.company && (
                                            <span
                                                className={
                                                    "accordion-list-item__separator"
                                                }
                                            >
                                                {this.state.company}
                                            </span>
                                        )}
                                        {(this.state.isLegalPerson ||
                                            this.state.isExemptFromTax) && (
                                            <div
                                                className={
                                                    "accordion-list-item__from-checkbox"
                                                }
                                            >
                                                {this.state.isLegalPerson && (
                                                    <div>
                                                        <SVGCheckMark
                                                            viewBox={
                                                                "0 0 50 36"
                                                            }
                                                            className={
                                                                "accordion-list-item__check"
                                                            }
                                                        />
                                                        Juristische Person
                                                    </div>
                                                )}
                                                {this.state.isExemptFromTax && (
                                                    <div>
                                                        <SVGCheckMark
                                                            viewBox={
                                                                "0 0 50 36"
                                                            }
                                                            className={
                                                                "accordion-list-item__check"
                                                            }
                                                        />
                                                        Steuerbefreit
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </AccordionTableItem>
                                </Accordion>
                                <Accordion
                                    headline={"2.0 Prämissen"}
                                    openByDefault={true}
                                    isBigHeadline={true}
                                >
                                    <AccordionTableItem
                                        onClick={() => {
                                            this.props.updateCurrentPage(
                                                path("anlageziel")
                                            );
                                        }}
                                        listItemTitle={"Anlageziel"}
                                    >
                                        {this.state.targetListLabels.map(
                                            (label, id) => {
                                                return (
                                                    <div
                                                        className={
                                                            "accordion-list-item__field"
                                                        }
                                                        key={id}
                                                    >
                                                        {label}
                                                    </div>
                                                );
                                            }
                                        )}
                                        {this.state.otherInput && (
                                            <div
                                                className={
                                                    "accordion-list-item__field"
                                                }
                                            >
                                                {this.state.otherInput}
                                            </div>
                                        )}
                                    </AccordionTableItem>

                                    <AccordionTableItem
                                        onClick={() => {
                                            this.props.updateCurrentPage(
                                                path("nachhaltigkeit")
                                            );
                                        }}
                                        listItemTitle={
                                            "Nachhaltigkeitskriterien"
                                        }
                                    >
                                        {this.state.sustainability === "Ja" ? (
                                            <>Ja</>
                                        ) : (
                                            <>Nein</>
                                        )}
                                    </AccordionTableItem>

                                    <AccordionTableItem
                                        onClick={() => {
                                            this.props.updateCurrentPage(
                                                path("risikobereitschaft")
                                            );
                                        }}
                                        listItemTitle={"Risikobereitschaft"}
                                    >
                                        {this.state.riskLabel}
                                    </AccordionTableItem>

                                    <AccordionTableItem
                                        onClick={() => {
                                            this.props.updateCurrentPage(
                                                path("anlagehorizont")
                                            );
                                        }}
                                        listItemTitle={"Anlagehorizont"}
                                    >
                                        {this.state.horizonText +
                                            " " +
                                            this.state.horizonTextComplement}
                                    </AccordionTableItem>
                                </Accordion>
                                <Accordion
                                    headline={"3.0 Anlagestrategie"}
                                    isBigHeadline={true}
                                    openByDefault={true}
                                >
                                    <AccordionTableItem
                                        onClick={() => {
                                            this.props.updateCurrentPage(
                                                path("umsetzungsoptionen")
                                            );
                                        }}
                                        listItemTitle={"Umsetzungsoptionen"}
                                    >
                                        {this.state.implementationOptionLabel}
                                    </AccordionTableItem>
                                    <AccordionTableItem
                                        onClick={() => {
                                            this.props.updateCurrentPage(
                                                path("anlagevolumen")
                                            );
                                        }}
                                        listItemTitle={"Anlagevolumen"}
                                    >
                                        {this.state.moneyVolume} €
                                    </AccordionTableItem>
                                    <AccordionTableItem
                                        onClick={() => {
                                            this.props.updateCurrentPage(
                                                path("maximale-aktienquote")
                                            );
                                        }}
                                        listItemTitle={"Maximale Aktienquote"}
                                    >
                                        {this.state.percentageRate}
                                    </AccordionTableItem>
                                    <AccordionTableItem
                                        onClick={() => {
                                            this.props.updateCurrentPage(
                                                path("ausrichtung")
                                            );
                                        }}
                                        listItemTitle={"Ausrichtung"}
                                    >
                                        {this.state.strategyLabel}
                                    </AccordionTableItem>
                                    {this.state.anyAgreementWasSelected &&
                                        checkMoney() && (
                                            <>
                                                <AccordionTableItem
                                                    onClick={() => {
                                                        this.props.updateCurrentPage(
                                                            path("ausrichtung")
                                                        );
                                                    }}
                                                    listItemTitle={
                                                        "Ergänzende Vereinbarungen"
                                                    }
                                                ></AccordionTableItem>

                                                {this.state.checkList
                                                    .underlyingIsins && (
                                                    <AccordionTableItem
                                                        noBorder={true}
                                                        onClick={() => {
                                                            this.props.updateCurrentPage(
                                                                path(
                                                                    "ausrichtung"
                                                                )
                                                            );
                                                        }}
                                                        listItemTitle={
                                                            "Ausschluss bestimmter Wertpapiere/Investmentfonds"
                                                        }
                                                    >
                                                        {this.state.isinText
                                                            .split(" ")
                                                            .join(", ")}
                                                    </AccordionTableItem>
                                                )}
                                                {this.state.checkList
                                                    .noDealWithWarrants && (
                                                    <AccordionTableItem
                                                        noBorder={true}
                                                        onClick={() => {
                                                            this.props.updateCurrentPage(
                                                                path(
                                                                    "ausrichtung"
                                                                )
                                                            );
                                                        }}
                                                        listItemTitle={
                                                            "Keine Investition in"
                                                        }
                                                    >
                                                        {this.state.checkList
                                                            .investmentsInwarrants && (
                                                            <div>
                                                                <SVGCheckMark
                                                                    viewBox={
                                                                        "0 0 50 36"
                                                                    }
                                                                    className={
                                                                        "accordion-list-item__check"
                                                                    }
                                                                />
                                                                Optionsscheine
                                                            </div>
                                                        )}
                                                        {this.state.checkList
                                                            .investmentsInCertificates && (
                                                            <div>
                                                                <SVGCheckMark
                                                                    viewBox={
                                                                        "0 0 50 36"
                                                                    }
                                                                    className={
                                                                        "accordion-list-item__check"
                                                                    }
                                                                />
                                                                Zertifikate
                                                            </div>
                                                        )}
                                                    </AccordionTableItem>
                                                )}
                                                {this.state.checkList
                                                    .ratingRequirementsValue ===
                                                    "hasInvestmentGrade" && (
                                                    <AccordionTableItem
                                                        noBorder={true}
                                                        onClick={() => {
                                                            this.props.updateCurrentPage(
                                                                path(
                                                                    "ausrichtung"
                                                                )
                                                            );
                                                        }}
                                                        listItemTitle={
                                                            "Investition nur im Rating-Bereich „Investment Grade“"
                                                        }
                                                    >
                                                        Ja
                                                    </AccordionTableItem>
                                                )}
                                                {this.state.checkList
                                                    .ratingRequirementsValue ===
                                                    "hasNonInvestmentGrade" && (
                                                    <AccordionTableItem
                                                        noBorder={true}
                                                        onClick={() => {
                                                            this.props.updateCurrentPage(
                                                                path(
                                                                    "ausrichtung"
                                                                )
                                                            );
                                                        }}
                                                        listItemTitle={
                                                            'Maximale Investition im Rating-Bereich „Non-Investment Grade"'
                                                        }
                                                    >
                                                        {this.state.checkList
                                                            .maxInvestiment +
                                                            "%"}
                                                    </AccordionTableItem>
                                                )}
                                            </>
                                        )}
                                    {((checkMoney(1000000) &&
                                        (this.state.regularPayments.checked ||
                                            this.state
                                                .distributionOfOrdinaryIncomeCheckbox)) ||
                                        (checkMoney(100000) &&
                                            this.state.implementationOption ===
                                                "einzeltitelUndAusschuettungsfokus")) && (
                                        <>
                                            <AccordionTableItem
                                                onClick={() => {
                                                    this.props.updateCurrentPage(
                                                        path("optionen")
                                                    );
                                                }}
                                                listItemTitle={"Optionen"}
                                            ></AccordionTableItem>
                                            {(this.state
                                                .implementationOption ===
                                                "einzeltitelUndAusschuettungsfokus" ||
                                                this.state
                                                    .distributionOfOrdinaryIncomeCheckbox) && (
                                                <AccordionTableItem
                                                    noBorder={true}
                                                    noBorderInner={true}
                                                    onClick={() => {
                                                        this.props.updateCurrentPage(
                                                            path("optionen")
                                                        );
                                                    }}
                                                    listItemTitle={
                                                        "Gutschrift Erträge:"
                                                    }
                                                >
                                                    {"zweimal jährlich"}
                                                </AccordionTableItem>
                                            )}

                                            {this.state.regularPayments
                                                .checked &&
                                                checkMoney(1000000) && (
                                                    <AccordionTableItem
                                                        noBorder={true}
                                                        noBorderInner={true}
                                                        onClick={() => {
                                                            this.props.updateCurrentPage(
                                                                path("optionen")
                                                            );
                                                        }}
                                                        listItemTitle={
                                                            "Regelmäßige Auszahlungen:"
                                                        }
                                                    >
                                                        {
                                                            <span>
                                                                {
                                                                    this.state
                                                                        .regularPayments
                                                                        .minimumAmount
                                                                }{" "}
                                                                €{" "}
                                                                {
                                                                    this.state
                                                                        .regularPayments
                                                                        .frequency1
                                                                }{" "}
                                                                am{" "}
                                                                {
                                                                    this.state
                                                                        .regularPayments
                                                                        .frequency2
                                                                }
                                                            </span>
                                                        }
                                                    </AccordionTableItem>
                                                )}
                                        </>
                                    )}
                                </Accordion>
                                <Accordion
                                    headline={"4.0 Empfehlung"}
                                    isBigHeadline={true}
                                    openByDefault={true}
                                >
                                    <AccordionTableItem
                                        listItemTitle={""}
                                        onClick={() => {
                                            this.props.updateCurrentPage(
                                                path("empfehlung")
                                            );
                                        }}
                                    >
                                        <div
                                            id={"ellipsisDerivationField"}
                                            className={
                                                "ellipsis-derivation-field"
                                            }
                                        >
                                            {this.state.derivationField}
                                        </div>
                                        <LinkStandard
                                            id={"showCompleteDerivationField"}
                                            additionalClass={
                                                "link-standard--bold link-standard--dark"
                                            }
                                            onClick={e => {
                                                e.stopPropagation();
                                                this.handleDerivationFieldClick();
                                            }}
                                        >
                                            Mehr anzeigen
                                        </LinkStandard>
                                    </AccordionTableItem>
                                </Accordion>

                                <Accordion
                                    onMouseUp={() => {
                                        this.props.updateCurrentPage(
                                            path("verguetungsmodell")
                                        );
                                    }}
                                    headline={"5.0 Vergütungsmodell"}
                                    isBigHeadline={true}
                                    openByDefault={true}
                                >
                                    <AccordionTableItem
                                        onClick={() => {
                                            this.props.updateCurrentPage(
                                                path("verguetungsmodell")
                                            );
                                        }}
                                        listItemTitle={" "}
                                    >
                                        {this.state.remuneration ===
                                        "withRemuneration"
                                            ? "Mit erfolgsabhängiger Vergütung / " +
                                              this.state.compensationValue.replace(
                                                  /\./g,
                                                  ","
                                              ) +
                                              (this.state.isLegalPerson
                                                  ? " p.a. (exklusive MwSt.)"
                                                  : " p.a. (inklusive MwSt.)")
                                            : "Ohne erfolgsabhängige Vergütung / " +
                                              this.state.compensationValue.replace(
                                                  /\./g,
                                                  ","
                                              ) +
                                              (this.state.isLegalPerson
                                                  ? " p.a. (exklusive MwSt.)"
                                                  : " p.a. (inklusive MwSt.)")}
                                    </AccordionTableItem>
                                </Accordion>
                            </Col>
                        </Row>
                    </Grid>
                </div>

                <Modal
                    isVisible={this.state.isModalVisible}
                    hasCloseX={true}
                    headlineText={"4.0 Empfehlung"}
                    onExit={() => {
                        this.setState({ isModalVisible: false });
                    }}
                >
                    {this.state.derivationField}
                </Modal>
            </section>
        );
    }
}

const mapStaTeToProps = (props: ComponentProps) => {
    return { currentPage: props.currentPage };
};

export default connect(mapStaTeToProps, {
    updateCurrentPage,
    updateForm
})(Page13);
