import * as React from "react";

const SVGSustainable: React.FC<SvgProps> = props => (
    <svg width={14} height={14} {...props}>
        <path
            d="M8.834 11.322l-.448-.83.322-3.79a.22.22 0 00-.201-.238.22.22 0 00-.237.201l-.265 3.12-2.525-4.68a.222.222 0 00-.298-.09.222.222 0 00-.09.298L6.92 8.701l-2.137-.805a.224.224 0 00-.284.128.222.222 0 00.128.284l2.612.984 1.205 2.235a2.866 2.866 0 01-2.164.148c-.714-.242-1.692-.88-2.3-2.554-.904-2.488-.423-6.464-.263-7.57.997.507 4.525 2.403 6.037 4.577.694.995.957 1.956.785 2.855-.226 1.171-1.138 1.953-1.705 2.34m2.137-2.257c.196-1.02-.092-2.093-.855-3.189C8.287 3.25 3.829 1.111 3.64 1.021a.218.218 0 00-.196.004.216.216 0 00-.115.158c-.036.206-.856 5.08.237 8.088.668 1.838 1.768 2.548 2.573 2.821.349.118.708.176 1.066.176.5 0 .995-.119 1.454-.342l.829 1.536a.22.22 0 00.298.09.22.22 0 00.09-.299l-.833-1.543c1.048-.7 1.734-1.638 1.928-2.644"
            fill="red"
            fillRule="evenodd"
        />
    </svg>
);

export default SVGSustainable;
