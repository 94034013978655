import React from "react";
import Heading from "../../components/Heading/Heading";
import Button from "../../components/Button/Button";
import InputRadioCheckbox from "../../components/InputRadioCheckbox/InputRadioCheckbox";
import SVGGlobal from "../../components/svg/Global";
import SVGEurope from "../../components/svg/Europe";
import SVGEuropeSustainable from "../../components/svg/EuropeSustainable";
import SVGGlobalSustainable from "../../components/svg/GlobalSustainable";
import SVGGlobalFonds from "../../components/svg/GlobalFonds";
import SVGGlobalSustainableFonds from "../../components/svg/GlobalSustainableFonds";
import SVGGlobalSustainableAusschuetungsfokus from "../../components/svg/GlobalSustainableAusschuetungsfokus";
import Subheadline from "../../components/Subheadline/Subheadline";
import InfoText from "../../components/InfoText/InfoText";
import LinkStandard from "../../components/LinkStandard/LinkStandard";
import { StoreManager } from "../../store";
import { connect } from "react-redux";
import {
    updateCurrentPage,
    updateForm,
    updateListOfPages
} from "../../store/data/actions";
import { RoutesPage } from "../../route/RouteUtil";
import BackToSummary from "../../components/BackToSummary/BackToSummary";
import { Form } from "../../store/data/types";
import SubPage09 from "./SubPage09";
import path from "../../components/utils/GetPageNumberByPath";
import { Grid, Row, Col } from "react-flexbox-grid";
import checkMoney from "../../components/utils/CheckMoney";
import generateRecommendationText from "../../components/utils/GenerateRecommendationText";
import {
    notSustainableEinzeltitel,
    sustainableEinzeltitel,
    notSustainableFondsbausteine,
    sustainableFondsbausteine,
    sustainableFondsbausteineDistributionFocus,
    SustainableType
} from "./SustainableContent";
import InfoBox from "../../components/InfoBox/InfoBox";

interface ComponentProps {
    currentPage: number;
    updateForm: Function;
    updateCurrentPage: Function;
    updateListOfPages: Function;
    listOfPages: RoutesPage[];
    forms: Form[];
    path: string;
}

interface ComponentState {
    strategy: string;
    strategyLabel: string;
    isDisabled: boolean;
    sustainableContent: Array<SustainableType>;
    showSub: boolean;
    resetSubForm: boolean;
}

class Page09 extends React.Component<ComponentProps, ComponentState> {
    constructor(props: ComponentProps) {
        super(props);
        this.showSubHandler = this.showSubHandler.bind(this);
    }

    state: ComponentState = {
        strategy: "",
        strategyLabel: "",
        isDisabled: true,
        sustainableContent: [],
        showSub: false,
        resetSubForm: false
    };

    sendForm = () => {
        this.props.updateForm({
            pageNumber: path(this.props.path),
            data: this.state
        });
    };

    componentDidMount() {
        const find: Form | undefined = StoreManager.getState().forms.find(
            item => item.pageNumber === path(this.props.path)
        );
        if (find) {
            this.setState(find.data as ComponentState, () =>
                this.checkSustainabilityOption()
            );
        } else {
            this.checkSustainabilityOption();
        }

        // close the sub form if there is no data there
        const findSub: Form | undefined = StoreManager.getState().forms.find(
            item => item.pageNumber === path(this.props.path) + 0.1
        );
        if (!findSub) {
            this.showSubHandler();
        }
    }

    checkImplementationOption = () => {
        const find = this.props.forms.find(
            item => item.pageNumber === path("umsetzungsoptionen")
        );

        if (find) {
            return find.data.implementationOption;
        }

        return "";
    };

    checkSustainabilityOption = () => {
        const implementationOption = this.checkImplementationOption();

        const findSustainabilityOption = this.props.forms.find(
            item => item.pageNumber === path("nachhaltigkeit")
        );

        let sustainableContent: Array<SustainableType>;

        if (findSustainabilityOption && implementationOption) {
            const sustainabilityOption =
                findSustainabilityOption.data.sustainability;

            if (
                sustainabilityOption === "Ja" &&
                implementationOption === "einzeltitel"
            ) {
                sustainableContent = sustainableEinzeltitel;
            } else if (
                sustainabilityOption === "Ja" &&
                implementationOption === "fondsbausteine"
            ) {
                sustainableContent = sustainableFondsbausteine;
            } else if (
                sustainabilityOption === "Ja" &&
                implementationOption === "einzeltitelUndAusschuettungsfokus"
            ) {
                sustainableContent = sustainableFondsbausteineDistributionFocus;
            } else if (
                sustainabilityOption === "Nein" &&
                implementationOption === "einzeltitel"
            ) {
                sustainableContent = notSustainableEinzeltitel;
            } else {
                sustainableContent = notSustainableFondsbausteine;
            }

            this.setState({ sustainableContent });

            if (sustainableContent.length === 1) {
                this.setState(
                    {
                        strategy: sustainableContent[0].type,
                        strategyLabel: sustainableContent[0].label.replace(
                            "\n",
                            ""
                        ),
                        isDisabled: false
                    },
                    () => {
                        this.sendForm();
                        generateRecommendationText();
                    }
                );
            }
        }
    };

    handleFormSubmission = (e: React.FormEvent): void => {
        e.preventDefault();
        this.props.updateCurrentPage(this.props.currentPage + 1);
    };

    handleInputChange = (e: any): void => {
        this.setState(
            {
                strategy: e.target.value,
                isDisabled: Boolean(!e.target.value),
                strategyLabel: e.target
                    .getAttribute("data-label")
                    .replace("\n", ""),
                resetSubForm: false
            } as ComponentState,
            () => {
                this.sendForm();
                generateRecommendationText();
            }
        );
    };

    subPageDisableForm = (status: boolean) => {
        if (!status) {
            this.setState({ isDisabled: false }, () => this.sendForm());
        } else if (status) {
            this.setState({ isDisabled: true }, () => this.sendForm());
        }
    };

    callLink = () => {
        this.setState({ showSub: true }, () => this.sendForm());
    };

    showSubHandler = () => {
        this.setState({ showSub: false }, () => this.sendForm());
    };

    getSvgComponent = (name: string) => {
        const customProps = {
            className: "form-item-radio__label-icon",
            viewBox: "0 0 14 14"
        };
        switch (name) {
            case "SVGGlobal":
                return <SVGGlobal {...customProps} />;
            case "SVGGlobalFonds":
                return <SVGGlobalFonds {...customProps} />;
            case "SVGEurope":
                return <SVGEurope {...customProps} />;
            case "SVGGlobalSustainable":
                return <SVGGlobalSustainable {...customProps} />;
            case "SVGGlobalSustainableFonds":
                return <SVGGlobalSustainableFonds {...customProps} />;
            case "SVGEuropeSustainable":
                return <SVGEuropeSustainable {...customProps} />;
            case "SVGGlobalSustainableAusschuetungsfokus":
                return (
                    <SVGGlobalSustainableAusschuetungsfokus {...customProps} />
                );
        }
    };

    createInfoBox = (index: number) => {
        return (
            <InfoBox
                key={index}
                isVisible={
                    this.state.strategy ===
                    this.state.sustainableContent[index].type
                }
                hasBulletList={true}
            >
                {this.state.sustainableContent[index].boxList.map(
                    (item, index) => {
                        return <li key={index}>{item}</li>;
                    }
                )}
            </InfoBox>
        );
    };

    createBox = (index: number) => {
        const SvgCustom = this.getSvgComponent(
            this.state.sustainableContent[index].svgIcon
        );
        return (
            <Col xs={5} key={index}>
                <InputRadioCheckbox
                    inputType={"radio"}
                    id={this.state.sustainableContent[index].type}
                    name={"strategy"}
                    label={this.state.sustainableContent[index].label}
                    dataLabel={this.state.sustainableContent[index].label}
                    value={this.state.sustainableContent[index].type}
                    checked={
                        this.state.strategy ===
                        this.state.sustainableContent[index].type
                    }
                    onChange={this.handleInputChange}
                    isLongBox={true}
                >
                    {SvgCustom}
                </InputRadioCheckbox>
            </Col>
        );
    };

    render() {
        return (
            <section className="parallax-layer parallax-layer--static">
                <Heading rank={2} text={"3.0 Anlagestrategie"} />
                <Heading rank={3} text={"3.4 Ausrichtung"} />

                <Subheadline>
                    Welche Ausrichtung soll die Vermögensanlage haben?
                </Subheadline>

                {this.state.sustainableContent.length > 0 && (
                    <div className="box-model">
                        <Grid fluid>
                            <Row className={"row--bottom-48"}>
                                {this.state.sustainableContent.map(
                                    (item: any, i: number) => this.createBox(i)
                                )}
                            </Row>
                            {this.state.strategy && (
                                <Row className={"row--bottom-48"}>
                                    <Col xs={10}>
                                        {this.state.sustainableContent.map(
                                            (item: any, i: number) =>
                                                this.createInfoBox(i)
                                        )}
                                    </Col>
                                </Row>
                            )}
                        </Grid>

                        <SubPage09
                            subPageDisableForm={this.subPageDisableForm}
                            isDisabled={this.state.isDisabled}
                            path={this.props.path}
                            showSubHandler={this.showSubHandler}
                            showSub={this.state.showSub}
                            resetSubForm={this.state.resetSubForm}
                        />
                    </div>
                )}

                <Grid fluid>
                    {checkMoney() &&
                        this.checkImplementationOption() !== "fondsbausteine" &&
                        !this.state.showSub && (
                            <Row>
                                <Col xs={12}>
                                    <InfoText noMargins={true}>
                                        Sollten darüber hinaus ergänzende
                                        Vereinbarungen getroffen werden, können
                                        Sie diese{" "}
                                        <LinkStandard
                                            additionalClass={
                                                "link-standard--bold link-standard--is-inline"
                                            }
                                            onClick={() => {
                                                !this.state.isDisabled &&
                                                    this.callLink();
                                            }}
                                        >
                                            hier
                                        </LinkStandard>{" "}
                                        auswählen.
                                    </InfoText>
                                </Col>
                            </Row>
                        )}
                </Grid>
                <Grid fluid className={"bottom-sticky"}>
                    <Row>
                        <Col xs={12} className={"align-right"}>
                            <BackToSummary />
                            <Button
                                styling={"primary"}
                                onClick={this.handleFormSubmission}
                                isDisabled={this.state.isDisabled}
                            >
                                Weiter
                            </Button>
                        </Col>
                    </Row>
                </Grid>
            </section>
        );
    }
}

const mapStaTeToProps = (props: ComponentProps) => {
    return {
        currentPage: props.currentPage,
        listOfPages: props.listOfPages,
        forms: props.forms
    };
};

export default connect(mapStaTeToProps, {
    updateCurrentPage,
    updateForm,
    updateListOfPages
})(Page09);
