export interface RouterMenuGroupItem {
    title: string;
    path: string;
    pageNumber: number;
    isDisabled?: boolean;
}

export interface RouterMenuGroup {
    title: string;
    path: string;
    pageNumber: number;
    subItems?: Array<RouterMenuGroupItem>;
    isOpened?: boolean;
}

export const RoutesConfig: Array<RouterMenuGroup> = [
    {
        title: "1.0 Persönliche Daten",
        path: "persoenliche-daten",
        isOpened: true,
        pageNumber: 1
    },
    {
        title: "2.0 Prämissen",
        path: "anlageziel",
        pageNumber: 2,
        subItems: [
            {
                title: "Anlageziel",
                path: "anlageziel",
                pageNumber: 2
            },
            {
                title: "Nachhaltigkeitskriterien",
                path: "nachhaltigkeit",
                pageNumber: 3
            },
            {
                title: "Risikobereitschaft",
                path: "risikobereitschaft",
                pageNumber: 4
            },
            {
                title: "Anlagehorizont",
                path: "anlagehorizont",
                pageNumber: 5
            }
        ]
    },
    {
        title: "3.0 Anlagestrategie",
        path: "umsetzungsoptionen",
        pageNumber: 6,
        subItems: [
            {
                title: "Umsetzungsoptionen",
                path: "umsetzungsoptionen",
                pageNumber: 6
            },
            {
                title: "Anlagevolumen",
                path: "anlagevolumen",
                pageNumber: 7
            },
            {
                title: "Maximale Aktienquote",
                path: "maximale-aktienquote",
                pageNumber: 8
            },
            {
                title: "Ausrichtung",
                path: "ausrichtung",
                pageNumber: 9
            },
            {
                title: "Optionen",
                path: "optionen",
                pageNumber: 10,
                isDisabled: true
            }
        ]
    },
    {
        title: "4.0 Empfehlung",
        path: "empfehlung",
        pageNumber: 11
    },
    {
        title: "5.0 Vergütungsmodell",
        path: "verguetungsmodell",
        pageNumber: 12
    },
    {
        title: "6.0 Ergebnis",
        path: "ergebnis",
        pageNumber: 13
    }
];
