import * as React from "react";

const SVGUpload: React.FC<SvgProps> = props => (
    <svg width={14} height={14} {...props}>
        <path
            d="M13.3 9.174c.387 0 .7.309.7.69v2.068C14 13.074 13.06 14 11.9 14H2.1C.94 14 0 13.074 0 11.932V9.864c0-.381.313-.69.7-.69.387 0 .7.309.7.69v2.068c0 .38.313.69.7.69h9.8c.387 0 .7-.31.7-.69V9.864c0-.381.313-.69.7-.69zM7 0a.716.716 0 01.386.114.695.695 0 01.109.088L7.417.136a.702.702 0 01.01.008l.068.058 2.8 2.757a.682.682 0 010 .975.708.708 0 01-.99 0L7.7 2.353v7.988a.693.693 0 01-.605.683L7 11.03a.695.695 0 01-.7-.69V2.354L4.695 3.934a.708.708 0 01-.903.073l-.087-.073a.682.682 0 010-.975l2.8-2.757.067-.058A.702.702 0 017 0z"
            fill="none"
            fillRule="evenodd"
        />
    </svg>
);

export default SVGUpload;
