import * as React from "react";

const SVGPlus: React.FC<SvgProps> = props => (
    <svg width={14} height={14} {...props}>
        <path
            d="M7.5 1v5.5H13v1H7.5V13h-1V7.499L1 7.5v-1l5.5-.001V1h1z"
            fill="red"
            fillRule="evenodd"
        />
    </svg>
);

export default SVGPlus;
