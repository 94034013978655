import React from "react";
import "./Heading.scss";

type PossibleHeadings = "h1" | "h2" | "h3";
type PossibleRanks = 1 | 2 | 3;

interface HeadingProps {
    rank: PossibleRanks;
    text: string;
    className?: string;
    additionalClass?: string;
}

const Heading = ({ rank = 2, text = "", additionalClass }: HeadingProps) => {
    const Tag = `h${rank}` as PossibleHeadings;

    return (
        <Tag
            className={
                "heading-" +
                rank +
                (additionalClass ? " " + additionalClass : "")
            }
        >
            {text + "."}
        </Tag>
    );
};

export default Heading;
