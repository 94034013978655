import React, { BaseSyntheticEvent } from "react";
import { connect } from "react-redux";
import {
    importForm,
    updateCurrentPage,
    updateForm
} from "../../store/data/actions";
import Accordion from "../../components/Accordion/Accordion";
import InfoText from "../../components/InfoText/InfoText";
import TextareaContentEditable from "../../components/TextareaContentEditable/TextareaContentEditable";
import { StoreManager } from "../../store";
import { Form } from "../../store/data/types";
import InputText from "../../components/InputText/InputText";
import InputTextCheckbox from "../../components/InputTextCheckbox/InputTextCheckbox";
import path from "../../components/utils/GetPageNumberByPath";
import InputTextRadio from "../../components/InputTextRadio/InputTextRadio";
import LinkStandard from "../../components/LinkStandard/LinkStandard";
import { Grid, Row, Col } from "react-flexbox-grid";
import SVGInfoRounded from "../../components/svg/Inforounded";
import Modal from "../../components/Modal/Modal";
import checkMoney from "../../components/utils/CheckMoney";

interface ComponentProps {
    currentPage: number;
    updateForm: Function;
    subPageDisableForm: Function;
    isDisabled: boolean;
    importForm: Function;
    path: string;
    showSubHandler: any;
    showSub: boolean;
    resetSubForm: boolean;
}

interface CheckList {
    [index: string]: boolean | string;
    noDealWithWarrants: boolean;
    underlyingIsins: boolean;
    noCertificates: boolean;
    investmentsInwarrants: boolean;
    investmentsInCertificates: boolean;
    maxInvestiment: string;
    ratingRequirements: boolean;
    ratingRequirementsValue: string;
}

export interface Isin {
    className?: string;
    name: string;
}

interface ComponentState {
    checkList: CheckList;
    isinText: string;
    isinList: Isin[];
    hasError: boolean;
    isinHasError: boolean;
    anyAgreementWasSelected: boolean;
    isInGradeModalVisible: boolean;
    isNonInGradeModalVisible: boolean;
}

interface ComponentState {}

class SubPage09 extends React.Component<ComponentProps, ComponentState> {
    state = {
        checkList: {
            noDealWithWarrants: false,
            investmentsInwarrants: false,
            investmentsInCertificates: false,
            underlyingIsins: false,
            noCertificates: false,
            maxInvestiment: "",
            ratingRequirements: false,
            ratingRequirementsValue: ""
        },
        isinText: "",
        isinList: [],
        hasError: false,
        isinHasError: false,
        anyAgreementWasSelected: false,
        isInGradeModalVisible: false,
        isNonInGradeModalVisible: false
    };
    timeout = 0;

    originalState: ComponentState = { ...this.state };

    resetState = () => {
        this.props.subPageDisableForm(true);
        this.setState({ ...this.originalState }, () => this.sendForm());
    };

    sendForm = () => {
        this.props.updateForm({
            pageNumber: path(this.props.path) + 0.1,
            data: {
                ...this.state,
                anyAgreementWasSelected: this.checkWasAnyAgreementSelected()
            }
        });
    };
    componentDidMount(): void {
        const find: Form | undefined = StoreManager.getState().forms.find(
            item => item.pageNumber === path(this.props.path) + 0.1
        );
        if (find) {
            this.setState(find.data as ComponentState);
        }

        if (this.props.resetSubForm) {
            this.resetState();
        }
    }

    handleClickNoAccordion = (
        e: BaseSyntheticEvent,
        id: string,
        parent: string
    ) => {
        let checkList: CheckList = { ...this.state.checkList };
        checkList[id] = !checkList[id];
        checkList[parent] = true;

        if (parent === "ratingRequirements") {
            checkList.ratingRequirementsValue = id;
        }
        this.setState({ checkList }, () => {
            this.sendForm();
            this.checkResetFields();
            this.checkWasAnyAgreementSelected();
        });
    };

    handleClick = (e: BaseSyntheticEvent, id: string) => {
        let checkList: CheckList = { ...this.state.checkList };
        if (
            !e.target
                .closest(".accordion__headline")
                .classList.contains("is-selected")
        ) {
            checkList[id] = true;
        } else {
            checkList[id] = false;
        }
        this.setState({ checkList }, () => {
            this.sendForm();
            this.checkResetFields();
            this.checkWasAnyAgreementSelected();
        });
    };

    componentDidUpdate(prevProps: ComponentProps) {
        this.disableButton();

        if (
            this.props.resetSubForm &&
            prevProps.resetSubForm !== this.props.resetSubForm
        ) {
            this.resetState();
        }
    }

    componentWillUnmount() {
        clearTimeout(this.timeout);
    }

    checkWasAnyAgreementSelected = () => {
        const checkList: CheckList = { ...this.state.checkList };

        if (
            checkList.noDealWithWarrants ||
            checkList.underlyingIsins ||
            checkList.ratingRequirements
        ) {
            this.setState({ anyAgreementWasSelected: true });
            return true;
        } else {
            this.setState({ anyAgreementWasSelected: false }, () =>
                this.checkEraseForm()
            );
            return false;
        }
    };

    checkEraseForm = () => {
        if (!this.state.anyAgreementWasSelected) {
            const listOfForms: Form[] = StoreManager.getState().forms.filter(
                item => item.pageNumber !== path(this.props.path) + 0.1
            );
            this.props.importForm(listOfForms);
        }
    };

    checkResetFields = () => {
        let checkList = { ...this.state.checkList };

        if (!checkList.underlyingIsins) {
            this.setState({ isinText: "", isinList: [] }, () =>
                this.sendForm()
            );
        }

        if (!checkList.noDealWithWarrants) {
            checkList.investmentsInwarrants = false;
            checkList.investmentsInCertificates = false;
        }

        if (checkList.ratingRequirementsValue === "hasInvestmentGrade") {
            checkList.maxInvestiment = "";
            clearTimeout(this.timeout);
        }
        if (!checkList.ratingRequirements) {
            checkList.ratingRequirementsValue = "";
        }
        this.setState({ checkList }, () => {
            this.sendForm();
            this.checkMoneyVolume();
        });
    };

    disableButton = () => {
        const isinLength = 12;
        const isinText = this.state.isinText.replace(/ /g, "");
        if (
            this.state.checkList.underlyingIsins &&
            (!isinText || isinText.length % isinLength !== 0)
        ) {
            this.props.subPageDisableForm(true);
            return true;
        }

        if (
            this.state.checkList.ratingRequirementsValue ===
                "hasNonInvestmentGrade" &&
            this.state.checkList.maxInvestiment === "" &&
            this.state.checkList.ratingRequirements
        ) {
            this.props.subPageDisableForm(true);
            return;
        }
        if (
            Number(this.state.checkList.maxInvestiment.replace(/\d+% ?/g, "")) >
                30 &&
            this.state.checkList.ratingRequirementsValue ===
                "hasNonInvestmentGrade"
        ) {
            this.props.subPageDisableForm(true);
            return;
        }

        if (
            this.state.checkList.noDealWithWarrants &&
            !this.state.checkList.investmentsInCertificates &&
            !this.state.checkList.investmentsInwarrants
        ) {
            this.props.subPageDisableForm(true);
            return;
        }

        if (this.state.isinHasError) {
            this.setState({ isinHasError: false }, () => this.sendForm());
        }

        if (this.props.resetSubForm) {
            return;
        }

        this.props.subPageDisableForm(false);
    };

    removeTag = (key: number) => {
        let isinList = [...this.state.isinList];
        isinList.splice(key, 1);
        let isinText = "";
        isinList.forEach((isin: Isin) => {
            isinText = isinText.concat("", isin.name).trim();
        });
        this.setState({ isinList, isinText }, () => this.sendForm());
    };

    handleText = (e: BaseSyntheticEvent) => {
        clearTimeout(this.timeout);
        const checkList = { ...this.state.checkList };
        checkList.underlyingIsins = true;
        let isinText = e.target.value.replace(/[,\s]/g, "") || " ";
        let isinList = isinText.match(/.{1,12}/g);
        isinText = isinText.replace(/(.{12})/g, "$1 ").trim();
        if (isinList) {
            isinList = isinList.map((isin: string) => {
                if (isin.length === 12) {
                    return { name: isin, className: "" };
                } else {
                    return {
                        name: isin,
                        className: "textarea-mask__characters"
                    };
                }
            });
        }
        this.setState({ isinList, isinText, checkList }, () => {
            this.sendForm();
            if (this.disableButton()) {
                this.timeout = window.setTimeout(() => {
                    this.setState({ isinHasError: true }, () =>
                        this.sendForm()
                    );
                }, 2000);
            }
        });
    };

    handleInputChange = (e: BaseSyntheticEvent) => {
        clearTimeout(this.timeout);
        const checkList = { ...this.state.checkList };
        checkList.maxInvestiment = e.target.value;
        checkList.ratingRequirements = true;
        checkList.ratingRequirementsValue = "hasNonInvestmentGrade";
        this.setState({ checkList }, () => {
            this.checkMoneyVolume();
        });
    };

    checkMoneyVolume = () => {
        let maxInvestiment = Number(
            this.state.checkList.maxInvestiment.replace(/,/g, ".")
        );

        if (maxInvestiment > 30 && this.state.checkList.maxInvestiment !== "") {
            this.timeout = window.setTimeout(() => {
                this.setState(
                    {
                        hasError: true
                    } as ComponentState,
                    () => this.sendForm()
                );
            }, 1000);
        } else {
            this.setState(
                {
                    hasError: false
                } as ComponentState,
                () => this.sendForm()
            );
        }
    };

    getPercentageClass = (): string => {
        const maxInvestiment = this.state.checkList.maxInvestiment;
        if (maxInvestiment !== "" && !this.state.hasError) {
            return "input-wrapper__percentage";
        } else if (this.state.hasError) {
            return "input-wrapper__percentage input-wrapper--error";
        } else {
            return "";
        }
    };

    callInGradeModal = () => {
        this.setState({ isInGradeModalVisible: true });
    };

    callNonInGradeModal = () => {
        this.setState({ isNonInGradeModalVisible: true });
    };

    render() {
        return (
            <>
                <div
                    className={`box-model--additional-content row ${
                        !this.props.showSub || !checkMoney() ? "hidden" : ""
                    }`}
                >
                    <Col xs={10}>
                        <InfoText isFlex={true} isSpaceBetween={true}>
                            Ergänzende Vereinbarungen
                            <LinkStandard
                                additionalClass={
                                    "link-standard--bold link-standard--dark"
                                }
                                onClick={this.props.showSubHandler}
                            >
                                Ausblenden
                            </LinkStandard>
                        </InfoText>

                        <InfoText additionalClass={"info-text--top-24"}>
                            <Accordion
                                headline={
                                    "Ausschluss bestimmter Wertpapiere / Investmentfonds mit folgenden ISINs"
                                }
                                dataValue={"distributionOfOrdinaryIncome"}
                                onMouseUp={(e: BaseSyntheticEvent) => {
                                    this.handleClick(e, "underlyingIsins");
                                }}
                                id={"underlyingIsins"}
                                checked={this.state.checkList.underlyingIsins}
                            >
                                Es dürfen keine Investitionen in nachfolgend
                                angegebene Wertpapiere/Investmentfondsanteile
                                erfolgen (ISIN erforderlich):
                                <InfoText whiteSpaceNormal={true}>
                                    <TextareaContentEditable
                                        value={this.state.isinText}
                                        isin={this.state.isinList}
                                        placeHolder={
                                            "Eingabe von bis zu 10 ISINs möglich"
                                        }
                                        height={"140px"}
                                        handleText={this.handleText}
                                        onRemoveTag={this.removeTag}
                                        isError={this.state.isinHasError}
                                        errorMessage={
                                            "Eine ISIN besteht immer aus 12 Zeichen. Bitte prüfen Sie Ihre Eingabe."
                                        }
                                    />
                                </InfoText>
                            </Accordion>
                            <Accordion
                                headline={
                                    "Ausschluss bestimmter Finanzinstrumente"
                                }
                                id={"noDealWithWarrants"}
                                checked={
                                    this.state.checkList.noDealWithWarrants
                                }
                                onMouseUp={(e: BaseSyntheticEvent) => {
                                    this.handleClick(e, "noDealWithWarrants");
                                }}
                            >
                                Es dürfen keine Investitionen in nachfolgende
                                Finanzinstrumente erfolgen:
                                <InfoText additionalClass={"info-text--top-24"}>
                                    <Grid fluid>
                                        <Row className={"row--bottom-0"}>
                                            <Col xs={3}>
                                                <InputTextCheckbox
                                                    name={
                                                        "investmentsInwarrants"
                                                    }
                                                    checked={
                                                        this.state.checkList
                                                            .investmentsInwarrants
                                                    }
                                                    onChange={(
                                                        e: BaseSyntheticEvent
                                                    ) => {
                                                        this.handleClickNoAccordion(
                                                            e,
                                                            "investmentsInwarrants",
                                                            "noDealWithWarrants"
                                                        );
                                                    }}
                                                >
                                                    Optionsscheine
                                                </InputTextCheckbox>
                                            </Col>
                                            <Col xs={3}>
                                                <InputTextCheckbox
                                                    name={
                                                        "investmentsInCertificates"
                                                    }
                                                    checked={
                                                        this.state.checkList
                                                            .investmentsInCertificates
                                                    }
                                                    onChange={(
                                                        e: BaseSyntheticEvent
                                                    ) => {
                                                        this.handleClickNoAccordion(
                                                            e,
                                                            "investmentsInCertificates",
                                                            "noDealWithWarrants"
                                                        );
                                                    }}
                                                >
                                                    Zertifikate
                                                </InputTextCheckbox>
                                            </Col>
                                        </Row>
                                    </Grid>
                                </InfoText>
                            </Accordion>

                            <Accordion
                                headline={
                                    "Ratingvorgaben für Investitionen in kreditrisikobehaftete Wertpapiere"
                                }
                                dataValue={"distributionOfOrdinaryIncome"}
                                onMouseUp={(e: BaseSyntheticEvent) => {
                                    this.handleClick(e, "ratingRequirements");
                                }}
                                id={"ratingRequirements"}
                                checked={
                                    this.state.checkList.ratingRequirements
                                }
                            >
                                <Grid fluid>
                                    <Row>
                                        <Col xs={12}>
                                            <InputTextRadio
                                                name={"ratingRequirements"}
                                                checked={
                                                    this.state.checkList
                                                        .ratingRequirementsValue ===
                                                    "hasInvestmentGrade"
                                                }
                                                onChange={(
                                                    e: BaseSyntheticEvent
                                                ) => {
                                                    this.handleClickNoAccordion(
                                                        e,
                                                        "hasInvestmentGrade",
                                                        "ratingRequirements"
                                                    );
                                                }}
                                            >
                                                Investition ausschließlich in
                                                kreditrisikobehaftete
                                                Wertpapiere im Rating-Bereich{" "}
                                                <br /> „Investment Grade“{" "}
                                                <span
                                                    onClick={e => {
                                                        e.preventDefault();
                                                        this.callInGradeModal();
                                                    }}
                                                >
                                                    <SVGInfoRounded
                                                        className={
                                                            "text-radio__info-icon"
                                                        }
                                                        viewBox={"0 0 50 50"}
                                                    />
                                                </span>
                                            </InputTextRadio>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12}>
                                            <InputTextRadio
                                                name={"ratingRequirements"}
                                                checked={
                                                    this.state.checkList
                                                        .ratingRequirementsValue ===
                                                    "hasNonInvestmentGrade"
                                                }
                                                onChange={(
                                                    e: BaseSyntheticEvent
                                                ) => {
                                                    this.handleClickNoAccordion(
                                                        e,
                                                        "hasNonInvestmentGrade",
                                                        "ratingRequirements"
                                                    );
                                                }}
                                            >
                                                Maximale Investition in
                                                kreditrisikobehaftete
                                                Wertpapiere im Rating-Bereich
                                                „Non Investment Grade“ in
                                                Prozent des verwalteten
                                                Vermögens{" "}
                                                <span
                                                    onClick={e => {
                                                        e.preventDefault();
                                                        this.callNonInGradeModal();
                                                    }}
                                                >
                                                    <SVGInfoRounded
                                                        className={
                                                            "text-radio__info-icon"
                                                        }
                                                        viewBox={"0 0 50 50"}
                                                    />
                                                </span>
                                            </InputTextRadio>
                                        </Col>
                                    </Row>
                                    <Row className={"row--bottom-0"}>
                                        <Col xs={4}>
                                            <div
                                                className={
                                                    "input-wrapper " +
                                                    this.getPercentageClass()
                                                }
                                            >
                                                <InputText
                                                    className={
                                                        "form-item-text__max-investment"
                                                    }
                                                    maxLength={6}
                                                    value={
                                                        this.state.checkList
                                                            .maxInvestiment
                                                    }
                                                    id={"maxInvestiment"}
                                                    name={"maxInvestiment"}
                                                    showPlaceholderAsLabel={
                                                        true
                                                    }
                                                    placeHolder={
                                                        "Bis zu 30% möglich"
                                                    }
                                                    isNumber={true}
                                                    isPercentage={true}
                                                    isError={
                                                        this.state.hasError
                                                    }
                                                    errorMessage={
                                                        "Es sind maximal 30% möglich."
                                                    }
                                                    onChange={e => {
                                                        this.handleInputChange(
                                                            e
                                                        );
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </Grid>
                            </Accordion>
                        </InfoText>
                    </Col>
                </div>

                <Modal
                    isVisible={this.state.isInGradeModalVisible}
                    hasCloseX={true}
                    headlineText={"Investment Grade"}
                    onExit={() => {
                        this.setState({ isInGradeModalVisible: false });
                    }}
                >
                    Rating-Bereich „Investment Grade“ entspricht Ratingklasse
                    AAA (S&amp;P)/ AAA (Fitch)/ Aaa (Moody`s) bis BBB-
                    (S&amp;P)/ BBB- (Fitch)/ Baa3 (Moody`s)
                </Modal>

                <Modal
                    isVisible={this.state.isNonInGradeModalVisible}
                    hasCloseX={true}
                    headlineText={"Non Investment Grade"}
                    onExit={() => {
                        this.setState({ isNonInGradeModalVisible: false });
                    }}
                >
                    Rating-Bereich „Non-Investment Grade“ entspricht
                    Ratingklasse niedriger als BBB- (S&amp;P)/ BBB- (Fitch)/
                    Baa3 (Moodys) oder ohne Rating
                </Modal>
            </>
        );
    }
}

const mapStaTeToProps = (props: ComponentProps) => {
    return {
        currentPage: props.currentPage
    };
};

export default connect(mapStaTeToProps, {
    updateCurrentPage,
    updateForm,
    importForm
})(SubPage09);
