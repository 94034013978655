import React, { ReactElement } from "react";
import "./Modal.scss";
import ReactDOM from "react-dom";
import SVGExit from "../svg/Exit";
import BarsLoader from "../BarsLoader/BarsLoader";

interface ModalProps {
    isVisible: boolean;
    hasCloseX: boolean;
    buttonWrapper?: ReactElement;

    hasLoader?: boolean;
    onExit: Function;
    headlineText?: string;
    hasNoCopyText?: boolean;
}

interface ModalStates {
    isVisible: boolean;
}

export default class Modal extends React.Component<ModalProps, ModalStates> {
    modalRoot = document.getElementById("modal-root");
    modalRef = React.createRef<HTMLDivElement>();

    state: ModalStates = {
        isVisible: this.props.isVisible
    };

    componentDidUpdate(): void {
        if (this.state.isVisible !== this.props.isVisible) {
            this.setState({ isVisible: this.props.isVisible });
        }
    }

    handleExit = (): void => {
        this.setState({ isVisible: false });
        this.props.onExit(); // update the parent status
    };

    handleExitOutside = (e: any): void => {
        const modalWrapper = this.modalRef.current;
        if (this.props.hasCloseX && e.target === modalWrapper) {
            this.setState({ isVisible: false });
            this.props.onExit();
        }
    };

    createEl = () => {
        if (!this.state.isVisible) {
            return null;
        }

        return (
            <div
                className={"modal"}
                ref={this.modalRef}
                onClick={this.handleExitOutside}
            >
                <div className={"modal__wrapper"}>
                    {this.props.hasCloseX && (
                        <div onClick={this.handleExit}>
                            <SVGExit
                                className={"modal__close"}
                                viewBox={"0 0 14 14"}
                            />
                        </div>
                    )}
                    <div className={"modal__content"}>
                        {this.props.hasLoader && (
                            <div className={"modal__content-loader"}>
                                <BarsLoader />
                            </div>
                        )}
                        <div
                            className={
                                "modal__content-inner" +
                                (this.props.hasNoCopyText
                                    ? " modal__content-inner--no-copytext"
                                    : "")
                            }
                        >
                            <div className={"modal__content-inner-headline"}>
                                {this.props.headlineText}
                            </div>
                            <div className={"modal__content-inner-text"}>
                                {this.props.children}
                            </div>
                        </div>
                    </div>
                    {this.props.buttonWrapper}
                </div>
            </div>
        );
    };

    render() {
        return ReactDOM.createPortal(this.createEl(), this.modalRoot!);
    }
}
