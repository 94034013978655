import * as React from "react";

const SVGAlert: React.FC<SvgProps> = props => (
    <svg width={18} height={14} {...props}>
        <path
            d="M9 0l9 14H0L9 0zm0 1.85L1.832 13h14.336L9 1.85zm.724 8.746V12H8.32v-1.404h1.404zM9.794 5l-.21 4.944H8.44L8.25 5h1.544z"
            fill="red"
            fillRule="evenodd"
        />
    </svg>
);

export default SVGAlert;
