import * as React from "react";

const SVGTime: React.FC<SvgProps> = props => (
    <svg width={14} height={14} {...props}>
        <path
            d="M5.816 11.499h2.368v-.442H5.816v.442zM12 1v.442h-.818c-.039 1.467-.46 2.842-1.197 3.89C9.358 6.226 8.561 6.801 7.692 7c.869.2 1.666.774 2.293 1.668.737 1.048 1.158 2.423 1.197 3.89H12V13H2v-.442h.818c.038-1.467.46-2.842 1.197-3.89C4.642 7.774 5.439 7.199 6.308 7c-.869-.2-1.666-.774-2.293-1.668-.737-1.048-1.159-2.423-1.197-3.89H2V1h10zM7 7.363c-2.009 0-3.652 2.31-3.732 5.195h7.464C10.652 9.674 9.01 7.363 7 7.363zm3.732-5.921H3.268C3.348 4.326 4.99 6.637 7 6.637c2.009 0 3.652-2.31 3.732-5.195zM5.227 2.63l.01.05c.065.301.446 1.861 1.467 2.238l-.157.414C5.179 4.828 4.8 2.793 4.785 2.707l.442-.077z"
            fill="red"
            fillRule="evenodd"
        />
    </svg>
);

export default SVGTime;
