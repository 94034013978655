const convertToCurrency: Function = (value: number): string => {
    if (typeof value !== "number") {
        value = 0;
    }

    return value.toLocaleString("de-DE", {
        style: "currency",
        currency: "EUR",
        minimumFractionDigits: 0
    });
};

export default convertToCurrency;
