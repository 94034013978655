import {
    currentPageReducer,
    currentBrokerReducer,
    formReducer,
    listOfPagesReducer,
    isHeaderLogoRightSideVisible
} from "./data/reducers";
import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";

const rootReducer = combineReducers({
    forms: formReducer,
    currentPage: currentPageReducer,
    listOfPages: listOfPagesReducer,
    currentBroker: currentBrokerReducer,
    isHeaderLogoRightSideVisible: isHeaderLogoRightSideVisible
});

// TODO remove this soon as possible
export const StoreManager = configureStore({
    reducer: rootReducer
});
