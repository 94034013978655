import React, { ChangeEventHandler } from "react";
import "./Textarea.scss";

interface TextareaProps {
    placeholderValue?: string;
    className?: string;
    cols?: number;
    rows?: number;
    onChange?: ChangeEventHandler;
    onBlur?: ChangeEventHandler;
    onFocus?: ChangeEventHandler;
    id?: string;
    additionalClass?: string;
    textAreaRef?: React.RefObject<HTMLTextAreaElement>;
    label?: string;
}

const Textarea: React.FC<TextareaProps> = ({
    placeholderValue,
    className,
    cols,
    rows,
    onChange,
    onBlur,
    onFocus,
    id,
    additionalClass,
    textAreaRef,
    label
}) => {
    return (
        <div
            className={"form-textarea__wrapper " + (className ? className : "")}
        >
            {label && <label className={"form-textarea__label"}>{label}</label>}
            <textarea
                ref={textAreaRef}
                cols={cols}
                rows={rows}
                onBlur={onBlur}
                onFocus={onFocus}
                onChange={onChange}
                id={id}
                className={additionalClass}
                value={placeholderValue}
            />
        </div>
    );
};

export default Textarea;
