import * as React from "react";

const SVGGlobalSustainable: React.FC<SvgProps> = props => (
    <svg width={14} height={14} {...props}>
        <path
            d="M8.09 4.805a.213.213 0 01.194-.003c.126.058 3.082 1.477 4.298 3.224.515.74.71 1.467.576 2.16-.128.665-.574 1.279-1.249 1.748l.52.962a.22.22 0 01-.194.324.222.222 0 01-.194-.115l-.522-.968c-.294.13-.6.22-.917.22-.244 0-.488-.04-.726-.12-.772-.261-1.39-.938-1.742-1.905-.728-2.001-.184-5.232-.16-5.37a.22.22 0 01.115-.157zM6.98 1c3.307 0 6 2.692 6 6 0 .085-.01.167-.014.25a7.282 7.282 0 00-.516-.56 5.446 5.446 0 00-.72-2.427H9.49c.023.102.035.219.056.324a16.96 16.96 0 00-.588-.315v-.01h-.016c-.155-.078-.27-.135-.319-.158a1 1 0 00-.889.01c-.068.037-.114.1-.17.149H5A11.268 11.268 0 004.69 6.74h2.316c-.009.167-.015.34-.02.518H4.69c.017.882.125 1.726.312 2.478h2.167a6.4 6.4 0 00.13.517h-2.15c.078.247.166.482.263.7.431.972 1.003 1.529 1.568 1.529.372 0 .744-.262 1.08-.706.11.144.218.287.342.41-.036.044-.069.095-.106.136.066-.016.126-.047.19-.065.156.144.314.283.49.393A5.946 5.946 0 016.978 13c-3.309 0-6-2.691-6-6 0-3.308 2.691-6 6-6zm-2.375 9.254H2.568a5.497 5.497 0 003.094 2.069c-.433-.48-.797-1.194-1.057-2.07zm3.758-4.921c-.116.843-.38 3.295.184 4.849.391 1.075 1.015 1.484 1.47 1.638.431.147.888.118 1.3-.06l-.745-1.38-1.695-.638a.22.22 0 01-.129-.283.223.223 0 01.284-.13l1.221.46-1.109-2.056a.218.218 0 01.09-.297.219.219 0 01.298.088l1.544 2.864.104-1.23c.011-.121.11-.216.238-.2a.22.22 0 01.201.237l-.16 1.9.241.45c.365-.26.89-.737 1.026-1.442.11-.572-.06-1.186-.505-1.825-.944-1.357-3.103-2.553-3.858-2.945zm-4.19 1.926h-2.67c.041.899.3 1.742.726 2.478h2.24a11.976 11.976 0 01-.297-2.478zm.295-2.996H2.23a5.442 5.442 0 00-.727 2.478h2.67c.017-.894.122-1.733.296-2.478zm1.194-2.586a5.497 5.497 0 00-3.094 2.069h2.037c.26-.875.624-1.59 1.057-2.07zm1.317-.16c-.565 0-1.137.557-1.568 1.528a7.004 7.004 0 00-.264.7h3.664a6.768 6.768 0 00-.265-.7c-.43-.971-1.002-1.528-1.567-1.528zm1.316.16c.433.48.797 1.194 1.057 2.069h2.037a5.493 5.493 0 00-3.094-2.07z"
            fill="red"
            fillRule="evenodd"
        />
    </svg>
);

export default SVGGlobalSustainable;
