import React, { FormEventHandler } from "react";
import "./Button.scss";

type PossibleStyles = "primary" | "secondary";

interface ButtonProps {
    styling: PossibleStyles;
    additionalClass?: string;
    onClick?: FormEventHandler;
    isDisabled?: boolean;
}

const Button: React.FC<ButtonProps> = ({
    styling,
    additionalClass,
    onClick,
    isDisabled,
    children
}) => {
    return (
        <span
            className={
                `button-${styling}` +
                (isDisabled ? " button--disabled" : "") +
                (additionalClass ? " " + additionalClass : "")
            }
            onClick={e => {
                if (!isDisabled && onClick !== undefined) {
                    onClick(e);
                }
            }}
        >
            {children}
        </span>
    );
};

export default Button;
